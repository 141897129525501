import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "components/Icon";
import AuthHelper from "services/AuthHelper";
import logo from "assets/images/logo.png";

class Layout extends Component {
    state = {
        sidebarToggle: false,
        username: "",
        user_email: "",
        updateAvailable: false
    };

    hasUpdate = false;

    componentDidMount() {
        let user = AuthHelper.user;

        if (user) {
            this.setState({
                username: user.username,
                user_email: user.user_email
            });
        }
    }

    doUpdate() {
        window.location.reload();
    }

    toggleSidebar() {
        if (this.state.sidebarToggle) {
            this.setState({ sidebarToggle: false });
        } else {
            this.setState({ sidebarToggle: true });
        }
    }

    hideSidebar() {
        this.setState({ sidebarToggle: false });
    }

    render() {
        let sidebar_classes = "main-sidebar";
        if (this.state.sidebarToggle) {
            sidebar_classes += " opened";
        }

        let sidebar = (
            <div className={sidebar_classes}>
                <div className="sidebar-header">
                    <div className="hideSidebarBtn" onClick={this.hideSidebar.bind(this)}>
                        <Icon icon="times" />
                    </div>
                    <div className="sidebar-content">
                        <div className="username">{this.state.username}</div>
                        <div className="email">{this.state.user_email}</div>
                    </div>
                </div>
                <ul className="menu">
                    <li>
                        <Link to="/dashboard" onClick={this.hideSidebar.bind(this)}>
                            <Icon icon="th-large" />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/customers" onClick={this.hideSidebar.bind(this)}>
                            <Icon icon="users" />
                            <span>Kunden</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/deals/import" onClick={this.hideSidebar.bind(this)}>
                            <Icon icon="cloud-upload-alt" />
                            <span>Angebot importieren</span>
                        </Link>
                    </li>
                    <li className="divider" />
                    <li>
                        <Link to="/settings" onClick={this.hideSidebar.bind(this)}>
                            <Icon icon="cogs" />
                            <span>Einstellungen</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/app-info" onClick={this.hideSidebar.bind(this)}>
                            <Icon icon="info-circle" />
                            <span>App-Info</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/logout" onClick={this.hideSidebar.bind(this)}>
                            <Icon icon="sign-out-alt" />
                            <span>Ausloggen</span>
                        </Link>
                    </li>
                </ul>
            </div>
        );

        let updateWrapper;
        if (this.props.appHasUpdateSuccess) {
            updateWrapper = (
                <div className="updateNotification" onClick={this.doUpdate.bind(this)}>
                    <Icon icon="success" />
                    UPDATE ERFOLGREICH INSTALLIERT
                </div>
            );
        }
        else if (this.props.appHasUpdate) {
            updateWrapper = (
                <div className="updateNotification" onClick={this.doUpdate.bind(this)}>
                    <Icon icon="download" />
                    Es ist ein Update der App verfügbar. Jetzt installieren
                </div>
            );
        }

        return (
            <div className="page-container">
                {sidebar}
                <div className="header">
                    <div className="sidebarToggle" onClick={this.toggleSidebar.bind(this)}>
                        <Icon icon="bars" style={{ fontSize: 26 }} />
                    </div>
                    <img src={logo} alt="Logo" className="logo" onClick={() => this.props.history.push("/dashboard")} />
                </div>
                <div className="pageWrapperContainer" onClick={this.hideSidebar.bind(this)}>
                    {updateWrapper}
                    <main>{this.props.children}</main>
                    <div className="footer">
                        Copyright &copy; by Achim Senft | Powered by{" "}
                        <a href="https://webtypen.de/" target="_blank" rel="noopener noreferrer">
                            Benedict Romp
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appHasUpdate: state.app.hasUpdate,
    appHasUpdateSuccess: state.app.hasUpdateSuccess,
});
export default connect(mapStateToProps)(withRouter(Layout));
