import React, { Component } from 'react';
import $ from 'jquery';

export default class BigTextareas extends Component {

	state = {
		isActive: false
	}

	componentDidMount() {
		this.bindEvents();
	}

	componentDidUpdate() {
		this.bindEvents();
	}

	bindEvents() {
		let self = this;
		let css_style = 'position: fixed;top: 0px;left: 0px;height: 100vh;width: 100%;z-index: 5000;background: #fff;outline: none;box-shadow: none;border: none;border-radius: 0px;border-top: 50px solid #2f4050;padding: 15px;';
		$("textarea.big-textarea").each(function() {
			$(this).unbind('click').bind('click', function() {
				$(this).attr('style', css_style);
				$("body").css("overflow-y", "hidden").css("height", "100vh");

				self.setState({
					isActive: true
				});
			});
		});
	}

	close() {
		$("textarea.big-textarea").each(function() {
			$(this).unbind('click').attr('style', '');
		});
		
		$("body").attr('style', '');

		this.setState({
			isActive: false
		});
	}

	render() {
		if(!this.state.isActive) {
			return (<div className="hidden"></div>)
		}

		return (
			<div>
				<div style={{ position: 'fixed', top: '14px', left: '15px', zIndex: 5005, color: '#fff', fontSize: '18px', fontWeight: 'bold' }}>
					Text bearbeiten
				</div>
				<div style={{ position: 'fixed', top: '4px', right: '15px', zIndex: 5005 }}>
					<button className="btn btn-default" onClick={this.close.bind(this)}>Fertig</button>
				</div>
			</div>
		);
	}

}