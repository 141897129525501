import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import "moment/locale/de";

import AuthHelper from "./services/AuthHelper";
import DBHelper from "./services/DBHelper";
import Layout from "./components/design/Layout";
import Login from "./screens/Login";
import Logout from "./screens/Logout";
import Dashboard from "./screens/Dashboard";
import CustomersList from "./screens/Database/CustomersList";
import Customer from "./screens/Database/Customer";
import AppInfo from "./screens/AppInfo";
import DealEdit from "./screens/Deals/Edit";
// import Import from "./screens/Deals/Import";
import Settings from "./screens/Settings";
import DealEditor from "./screens/DealEditor";
import DealImport from "./screens/DealImport";

// moment
moment.locale("de");

// FontAwesome
library.add(fas, fab, far);

// Offline-DB booten
DBHelper.boot();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            AuthHelper.checkLogin() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

const App = () => (
    <Router>
        <Switch>
            <Route exact path="/" component={Login} />
            <Layout>
                <Route exact path="/logout" component={Logout} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/customers" component={CustomersList} />
                <PrivateRoute exact path="/customers/:id/detail" component={Customer} />
                <PrivateRoute exact path="/app-info" component={AppInfo} />
                <PrivateRoute exact path="/settings" component={Settings} />

                <PrivateRoute exact path="/deals/import" component={DealImport} />
                <PrivateRoute exact path="/deals/:id/edit" component={DealEdit} />
                <PrivateRoute exact path="/deals/:id/editor" component={DealEditor} />
            </Layout>
            <Route
                render={() => (
                    <div class="content">
                        <h1>Seite nicht gefunden</h1>
                    </div>
                )}
            />
        </Switch>
    </Router>
);
export default App;
