import React, { Component } from "react";
import { Link } from "react-router-dom";
import DBHelper from "../services/DBHelper";

export default class DealImport extends Component {
    minFields = [
        "id",
        "date",
        "date_inspection",
        "deal_type",
        "user_id",
        "positions",
        "freepositions",
        "text_customer",
        "text_subcontractors",
        "text_groups",
        "offline_project",
        "export_moment"
    ];
    fileReader = null;

    state = {
        isLoading: false,
        hasError: false,
        fileChosen: false,
        dealId: null
    };

    hasError() {
        this.setState({
            isLoading: false,
            hasError: true
        });
    }

    handleFileRead(e) {
        const content = this.fileReader.result;
        if (!content) {
            return this.hasError();
        }

        const json = JSON.parse(content);
        console.log(content, json);
        if (!json) {
            return this.hasError();
        }

        for (let i in this.minFields) {
            if (json[this.minFields[i]] === undefined) {
                return this.hasError();
            }
        }

        DBHelper.write("deals", [
            {
                id: json.id,
                data: json
            }
        ]);

        this.setState({
            hasSuccess: true,
            isLoading: false,
            dealId: json.id
        });
    }

    handleFile(file) {
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.handleFileRead.bind(this);

        this.setState({
            fileChosen: file
        });
    }

    render() {
        if (this.state.hasSuccess) {
            return (
                <div className="content">
                    <h1>Angebot erfolgreich importiert</h1>

                    <div className="alert alert-success">Das Angebot wurde erfolgreich importiert.</div>
                    <Link to={"/deals/" + this.state.dealId + "/editor"} className="btn btn-primary">
                        Importiertes Angebot öffnen
                    </Link>
                </div>
            );
        }

        return (
            <div className="content">
                {this.state.hasError ? (
                    <div className="alert alert-danger">
                        <b>Fehler beim Import:</b>
                        <br />
                        Es ist ein Fehler beim Importieren der Datei aufgetreten.
                    </div>
                ) : null}

                <div className="card">
                    <div className="card-header">Angebot importieren</div>
                    <div className="card-body">
                        Einfach ein zuvor exportiertes Angebot auswählen und anschließend auf "Importieren" klicken.
                        <br />
                        <input
                            type="file"
                            accept=".lemo"
                            onChange={e => this.handleFile(e.target.files[0])}
                            disabled={this.state.isLoading}
                        />
                    </div>
                    <div className="card-footer text-right">
                        <button
                            className="btn btn-success btn-sm"
                            disabled={!this.state.fileChosen || this.state.isLoading}
                            onClick={() => {
                                this.setState({
                                    isLoading: true
                                });
                                this.fileReader.readAsText(this.state.fileChosen);
                            }}
                        >
                            Importieren
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
