import React, { Component } from "react";
import { connect } from "react-redux";
import DealHelper from "../DealHelper";
import PositionsHelper from "../PositionsHelper";
import Modal from "react-modal";

class DealPrices extends Component {
    state = {
        calcOwnPrice: null
    };

    showCalcOwnPrice = () => {
        this.setState({
            calcOwnPrice: "1,00"
        });
    };

    hideCalcOwnPrice = () => {
        this.setState({
            calcOwnPrice: null
        });
    };

    changeCalcOwnPrice = e => {
        this.setState({
            calcOwnPrice: e.target.value
        });
    };

    render() {
        let netto = 0;
        let groupPrices = {};
        let subcontractorTotal = 0;
        let modalContent;
        if (this.props.positions && this.props.positions.length > 0) {
            for (let i in this.props.positions) {
                const pos = this.props.positions[i];
                if (pos.contingency) {
                    continue;
                }

                let item = null;
                for (let c in this.props.data_catalog.categories) {
                    if (
                        !this.props.data_catalog.categories[c] ||
                        !this.props.data_catalog.categories[c].items ||
                        this.props.data_catalog.categories[c].items.length < 1
                    ) {
                        continue;
                    }

                    for (let i in this.props.data_catalog.categories[c].items) {
                        if (this.props.data_catalog.categories[c].items[i].id === pos.id) {
                            item = this.props.data_catalog.categories[c].items[i];
                            break;
                        }
                    }
                }

                if (!item) {
                    continue;
                }

                let price = PositionsHelper.getSinglePrice(this.props.price_type_id, item, pos, "value_customer");
                price = price * pos.quantity;
                netto = netto + price;

                if (this.props.show_pricedetails) {
                    if (item.groups && item.groups.length > 0) {
                        for (let g in item.groups) {
                            if (!groupPrices[item.groups[g].id]) {
                                groupPrices[item.groups[g].id] = {
                                    id: item.groups[g].id,
                                    name: item.groups[g].name,
                                    prefix: item.groups[g].prefix,
                                    brutto: 0
                                };
                            }

                            let weighting = PositionsHelper.getWeighting(item.groups[g].id, pos, item);
                            let price =
                                PositionsHelper.getSinglePrice(
                                    this.props.price_type_id,
                                    item,
                                    pos,
                                    "value",
                                    item.groups[g].id
                                ) * weighting;

                            groupPrices[item.groups[g].id].brutto = groupPrices[item.groups[g].id].brutto + price;
                            subcontractorTotal = subcontractorTotal + price;
                        }
                    }
                }
            }
        }

        if (this.props.free_positions && this.props.free_positions.length > 0) {
            for (let i in this.props.free_positions) {
                if (this.props.free_positions[i].contingency) {
                    continue;
                }

                let price = this.props.free_positions[i].price_customer * this.props.free_positions[i].quantity;
                if (
                    this.props.free_positions[i].price_multiplier !== null &&
                    this.props.free_positions[i].price_multiplier.toString().trim() !== ""
                ) {
                    price = price * DealHelper.input2Float(this.props.free_positions[i].price_multiplier);
                }
                netto = netto + price;

                if (this.props.free_positions[i].group_id && this.props.free_positions[i].group_id > 0) {
                    if (this.props.free_positions[i].price_subcontractor !== null) {
                        if (!groupPrices[this.props.free_positions[i].group_id]) {
                            for (let g in this.props.data_groups) {
                                if (this.props.data_groups[g].id === this.props.free_positions[i].group_id) {
                                    groupPrices[this.props.free_positions[i].group_id] = {
                                        id: this.props.free_positions[i].group_id,
                                        name: this.props.data_groups[g].name,
                                        prefix: this.props.data_groups[g].prefix,
                                        brutto: 0
                                    };
                                }
                            }
                        }

                        if (groupPrices[this.props.free_positions[i].group_id]) {
                            let weighting =
                                this.props.free_positions[i].weighting !== null
                                    ? this.props.free_positions[i].weighting
                                    : this.props.free_positions[i].quantity;
                            let price = this.props.free_positions[i].price_subcontractor * weighting;

                            groupPrices[this.props.free_positions[i].group_id].brutto =
                                groupPrices[this.props.free_positions[i].group_id].brutto + price;
                            subcontractorTotal = subcontractorTotal + price;
                        }
                    }
                }
            }
        }
        const brutto = netto * parseFloat("1." + this.props.tax_percent);

        let dbPercentage = subcontractorTotal !== 0 ? (1 - 1 / (netto / subcontractorTotal)) * 100 : 0;
        let dbCustomPercentage =
            this.state.calcOwnPrice &&
            this.state.calcOwnPrice.trim() !== "" &&
            DealHelper.input2Float(this.state.calcOwnPrice) > 0
                ? (1 - 1 / (DealHelper.input2Float(this.state.calcOwnPrice) / subcontractorTotal)) * 100
                : 0;

        if (this.props.show_pricedetails) {
            modalContent = (
                <div className="dealtool-pricedetails-container">
                    <div className="modal-title">
                        <button type="button" className="btn btn-secondary float-right" onClick={this.props.close}>
                            Schließen
                        </button>
                        <h1>Preisdetails</h1>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Kosten- / Preistyp</th>
                                <th className="text-right">Betrag</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Kundenpreis Netto</td>
                                <td className="text-right">{DealHelper.number_format(netto)} EUR</td>
                            </tr>
                            <tr>
                                <td>Kundenpreis Brutto</td>
                                <td className="text-right">{DealHelper.number_format(brutto)} EUR</td>
                            </tr>
                            {this.state.calcOwnPrice ? (
                                <tr>
                                    <td>Manueller Kundenpreis</td>
                                    <td className="text-right">
                                        {DealHelper.number_format(DealHelper.input2Float(this.state.calcOwnPrice))} EUR
                                    </td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>Summe Nachunternehmer</td>
                                <td className="text-right">{DealHelper.number_format(subcontractorTotal)} EUR</td>
                            </tr>
                            <tr style={{ background: "#f1f1f1", fontWeight: "bold" }}>
                                <td style={{ position: "relative" }}>
                                    <span
                                        className={
                                            "badge badge-" +
                                            (dbPercentage > 20 ? "success" : dbPercentage >= 17 ? "warning" : "danger")
                                        }
                                        style={{ position: "absolute", top: 9, right: 15, fontSize: 12, padding: 6 }}
                                    >
                                        {DealHelper.number_format(dbPercentage)} %
                                    </span>
                                    Deckungsbeitrag
                                </td>
                                <td className="text-right">
                                    {DealHelper.number_format(netto - subcontractorTotal)}
                                    EUR
                                </td>
                            </tr>
                            {this.state.calcOwnPrice ? (
                                <tr style={{ background: "#f1f1f1", fontWeight: "bold" }}>
                                    <td style={{ position: "relative" }}>
                                        <span
                                            className={
                                                "badge badge-" +
                                                (dbCustomPercentage > 20
                                                    ? "success"
                                                    : dbCustomPercentage >= 17
                                                    ? "warning"
                                                    : "danger")
                                            }
                                            style={{
                                                position: "absolute",
                                                top: 9,
                                                right: 15,
                                                fontSize: 12,
                                                padding: 6
                                            }}
                                        >
                                            {DealHelper.number_format(dbCustomPercentage)} %
                                        </span>
                                        Deckungsbeitrag Manuell
                                    </td>
                                    <td className="text-right">
                                        {DealHelper.number_format(
                                            DealHelper.input2Float(this.state.calcOwnPrice) - subcontractorTotal
                                        )}
                                        EUR
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>

                    {this.state.calcOwnPrice ? (
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-xs btn-secondary btn-bordered float-right"
                                        onClick={this.hideCalcOwnPrice}
                                    >
                                        Manuellen Kundenpreis ausblenden
                                    </button>
                                    <label>Eigener Kundenpreis</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.calcOwnPrice}
                                        onChange={this.changeCalcOwnPrice}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-xs btn-secondary btn-bordered"
                                onClick={this.showCalcOwnPrice}
                            >
                                Manuellen Kundenpreis eingeben
                            </button>
                        </div>
                    )}

                    <div className="mt-1 font-weight-bold">Gewerke:</div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Kosten- / Preistyp</th>
                                <th className="text-right">Betrag</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupPrices).map(key => (
                                <tr key={"group_price_detail_" + key}>
                                    <td>{groupPrices[key].name}</td>
                                    <td className="text-right">
                                        {DealHelper.number_format(groupPrices[key].brutto)} EUR
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.show_pricedetails}
                    style={{
                        overlay: {
                            zIndex: 50000
                        },
                        content: {
                            maxWidth: 500,
                            margin: "auto"
                        }
                    }}
                    onAfterOpen={() => {}}
                    onRequestClose={this.props.close}
                    ariaHideApp={false}
                >
                    {modalContent}
                </Modal>

                <div style={{ paddingTop: 15, paddingBottom: 30, paddingRight: 15 }}>
                    <div
                        style={{
                            borderTop: "2px solid rgb(0, 0, 0)",
                            borderBottom: "3px solid rgb(0, 0, 0)",
                            position: "relative",
                            width: "100%",
                            maxWidth: "320px",
                            float: "right"
                        }}
                    >
                        <div style={{ padding: "3px 0px", position: "relative" }}>
                            <div style={{ position: "absolute", right: 0 }}>{DealHelper.number_format(netto)} EUR</div>
                            Netto
                        </div>
                        <div style={{ padding: "3px 0px", position: "relative" }}>
                            <div style={{ position: "absolute", right: 0 }}>
                                {DealHelper.number_format(brutto - netto)} EUR
                            </div>
                            MwSt. {this.props.tax_percent}%
                        </div>
                        <div style={{ padding: "3px 0px", position: "relative", fontWeight: "bold" }}>
                            <div style={{ position: "absolute", right: 0 }}>{DealHelper.number_format(brutto)} EUR</div>
                            Gesamtbetrag
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div className="text-right">
                        <button
                            type="button"
                            className="btn btn-bordered btn-secondary btn-xs mt-1"
                            onClick={this.props.open}
                        >
                            Preisdetails anzeigen
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    data_catalog: state.dealtool.data_catalog,
    data_groups: state.dealtool.data_groups,
    price_type_id: state.dealtool.data_price_type ? state.dealtool.data_price_type.id : null,
    positions: state.dealtool.positions,
    free_positions: state.dealtool.free_positions,
    show_pricedetails: state.dealtool.show_pricedetails,
    tax_percent: state.dealtool.tax_percent
});
const mapDispatch = dispatch => ({
    open: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_pricedetails", value: true } }),
    close: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_pricedetails", value: false } })
});
export default connect(mapStateToProps, mapDispatch)(DealPrices);
