import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import PageLoader from "components/PageLoader";
import DealsHelper from "services/DealsHelper";
import CustomersHelper from "services/CustomersHelper";
import CatalogsHelper from "services/CatalogsHelper";
import EditPositionsList from "./EditPositionsList";
import EditPositionSelector from "./EditPositionSelector";
import EditFPE from "./EditFPE";
import PredefinedTextSelector from "components/PredefinedTextSelector";
import $ from "jquery";
import ApiHelper from "services/ApiHelper";
import SettingsHelper from "services/SettingsHelper";
import { confirmAlert } from "react-confirm-alert";

export default class Edit extends Component {
    state = {
        dealNotFound: false,
        needsSync: true,
        id: null,
        positionSelector: false,
        editFpeComponent: false,
        isLoading: false,
        editInfoText: false,
        data_qm: "",
        data_street: "",
        data_housenumer: "",
        data_zip: "",
        data_city: "",
        data_location: "",
        data_projecttype: 0,
        data_customer: 0,
        data_customer_contact: 0,
        data_date: "",
        data_date_rental: "",
        customers_list: null,
        positions: [],
        fpes: [],
        fpe_edit: null,
        customer: null,
        catalog: null,
        group_info_text: {},
        customer_info_text: "",
        nu_info_text: "",
        hasChanges: false,
        no_catalog: false
    };

    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({
            id: id,
            needsSync: ApiHelper.needsSync()
        });

        if (id !== "new") {
            this.setState({ isLoading: true });
            this.loadDeal(id);
        }

        this.setState({
            customers_list: CustomersHelper.getCustomers(),
            projecttypes_list: CatalogsHelper.getProjectTypes()
        });

        $(document)
            .unbind("scroll")
            .bind("scroll", function() {
                if ($(window).width() > 767) {
                    if ($(document).scrollTop() >= $("#dealPositionsListContainer").offset().top - 70) {
                        $("#dealActionToolbar").addClass("fixed-top");
                    } else {
                        $("#dealActionToolbar").removeClass("fixed-top");
                    }
                }
            });
    }

    componentWillUnmount() {
        $(document).unbind("scroll");
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            hasChanges: true
        });
    }

    async handleCustomerChange(event, isNotNewDeal) {
        const target = event.target;
        const value = target.value;

        let customer = await CustomersHelper.getCustomer(value);
        if (!customer.catalog) {
            this.setState({
                data_customer: value,
                customer: customer,
                catalog: null,
                no_catalog: true
            });

            return null;
        }

        let catalog = await CatalogsHelper.getCatalog(customer.catalog.id);

        this.setState({
            data_customer: value,
            customer: customer,
            catalog: catalog,
            no_catalog: false
        });

        if (customer.info_text_customer !== undefined && customer.info_text_nu !== undefined) {
            if (isNotNewDeal === undefined || !isNotNewDeal) {
                if (
                    (customer.info_text_customer && customer.info_text_customer.trim() !== "") ||
                    (customer.info_text_nu && customer.info_text_nu.trim() !== "")
                ) {
                    confirmAlert({
                        title: "Kunden Info-Texte",
                        message:
                            "Sollen die im Kundendatensatz hinterlegten Info-Texte dem Angebot hinzugefügt werden?",
                        buttons: [
                            {
                                label: "Abbrechen",
                                onClick: () => {}
                            },
                            {
                                label: "Texte hinzufügen",
                                onClick: () => {
                                    this.setState({
                                        nu_info_text:
                                            customer.info_text_nu && customer.info_text_nu.trim() !== ""
                                                ? customer.info_text_nu.trim()
                                                : "",
                                        customer_info_text:
                                            customer.info_text_customer && customer.info_text_customer.trim() !== ""
                                                ? customer.info_text_customer.trim()
                                                : ""
                                    });
                                }
                            }
                        ]
                    });
                }
            }
        }
    }

    openPositionSelector() {
        this.setState({
            positionSelector: true,
            editFpeComponent: false
        });
    }

    hidePositionSelector() {
        this.setState({
            positionSelector: false
        });
    }

    openFpeEditor(event) {
        const target = event.target;
        let fpe = target.getAttribute("data-fpe");

        this.setState({
            fpe_edit: fpe,
            editFpeComponent: true,
            positionSelector: false
        });
    }

    hideFpeComponent() {
        this.setState({
            editFpeComponent: false
        });
    }

    loadDeal(id) {
        let deal = DealsHelper.getDeal(id);

        if (!deal) {
            this.setState({
                dealNotFound: true
            });
            return;
        }

        this.setState({
            isLoading: false,
            id: deal.id,
            data_customer: deal.customer,
            data_customer_contact: deal.customer_contact,
            data_qm: deal.project.qm,
            data_street: deal.project.street,
            data_housenumer: deal.project.housenumber,
            data_zip: deal.project.zip,
            data_city: deal.project.city,
            data_location: deal.project.location,
            data_projecttype: deal.project.projecttype,
            data_date: deal.date,
            data_date_rental: deal.date_rental,
            positions: deal.positions,
            fpes: deal.fpes,
            hasChanges: false
        });

        // Info-Texte setzen
        if (deal.group_info_text !== undefined) {
            this.setState({ group_info_text: deal.group_info_text });
        }
        if (deal.customer_info_text !== undefined) {
            this.setState({ customer_info_text: deal.customer_info_text });
        }
        if (deal.nu_info_text !== undefined) {
            this.setState({ nu_info_text: deal.nu_info_text });
        }

        this.handleCustomerChange({ target: { value: deal.customer } }, true);
    }

    changeInfoTextToggle(event) {
        const target = event.target;
        const type = target.getAttribute("data-type");

        if (this.state.editInfoText === type) {
            this.setState({ editInfoText: false, hasChanges: true });
        } else {
            this.setState({ editInfoText: type, hasChanges: true });
        }
    }

    infoTextUpdate(target, text, data) {
        let text_pre = this.state[data.text_type] + text;
        this.setState({
            [data.text_type]: text_pre,
            hasChanges: true
        });
    }

    changeInfoText(event) {
        const target = event.target;
        const value = target.value;
        const type = target.getAttribute("data-type");

        this.setState({
            [type]: value,
            hasChanges: true
        });
    }

    infoGroupTextChange(event) {
        let group_texts = this.state.group_info_text;
        group_texts["group_" + event.target.getAttribute("data-group")] = event.target.value;

        this.setState({
            group_info_text: group_texts,
            hasChanges: true
        });
    }

    infoGroupTextUpdate(target, text, data) {
        let group_texts = this.state.group_info_text;

        let pre_text = "";
        if (group_texts["group_" + data.catalog_group] !== undefined) {
            pre_text = group_texts["group_" + data.catalog_group];
        }
        group_texts["group_" + data.catalog_group] = pre_text + text;

        this.setState({
            group_info_text: group_texts,
            hasChanges: true
        });
    }

    save() {
        if (!this.state.data_customer) {
            alert("Es kann kein Angebot ohne Kunde gespeichert werden.");
            return;
        }

        this.setState({ isLoading: true });

        let id = this.state.id;
        if (id === "new") {
            id = "deal_" + Math.floor(Date.now() / 1000).toString();
            this.setState({ id: id });
        }

        let data = {
            id: id,
            customer: this.state.data_customer,
            customer_contact: this.state.data_customer_contact,
            date: this.state.data_date,
            date_rental: this.state.data_date_rental,
            project: {
                qm: this.state.data_qm,
                street: this.state.data_street,
                housenumber: this.state.data_housenumer,
                zip: this.state.data_zip,
                city: this.state.data_city,
                location: this.state.data_location,
                projecttype: this.state.data_projecttype
            },
            positions: this.state.positions,
            fpes: this.state.fpes,
            group_info_text: this.state.group_info_text,
            customer_info_text: this.state.customer_info_text,
            nu_info_text: this.state.nu_info_text
        };
        DealsHelper.saveDeal(data);

        setTimeout(() => {
            this.setState({ isLoading: false, hasChanges: false });
        }, 1000);
    }

    savePositions(data) {
        let positions = this.state.positions;
        if (!positions) {
            positions = [];
        }

        let status = true;
        if (positions && positions.length > 0) {
            for (let p in positions) {
                if (positions[p].item_id === data.item_id) {
                    positions[p] = data;
                    status = false;
                }
            }
        }

        if (status) {
            positions.push(data);
        }
        this.setState({ positions: positions, hasChanges: true });
    }

    removePosition(item_id) {
        let status = false;
        let positions = this.state.positions;

        if (positions && positions.length > 0) {
            for (let p in positions) {
                if (positions[p].item_id === item_id) {
                    positions.splice(p, 1);
                    status = true;
                    break;
                }
            }
        }

        if (status) {
            this.setState({ positions: positions, hasChanges: true });
        }
    }

    saveFpe(key, data) {
        if (key === undefined || data.data_timekey === undefined) {
            return;
        }

        let fpes = this.state.fpes;
        if (fpes === undefined || !fpes) {
            fpes = [];
        }

        if (key === "new") {
            fpes.push(data);
        } else {
            for (let f in fpes) {
                if (f === key) {
                    fpes[f] = data;
                }
            }
        }

        this.setState({
            fpes: fpes,
            hasChanges: true
        });
    }

    removeFpe(key) {
        let fpes = this.state.fpes;

        fpes.splice(key, 1);

        this.setState({
            fpes: fpes,
            hasChanges: true
        });
    }

    render() {
        if (this.state.needsSync && SettingsHelper.getSetting("deals_sync_check_disabled") !== true) {
            return (
                <div style={{ padding: "20px", paddingBottom: "0px" }}>
                    <div className="alert alert-danger">
                        <b>Synchronisation neuer Daten erforderlich!</b>
                        <br />
                        Es wurden Änderungen an der Datenstruktur vorgenommen. Um Angebote verwenden zu können, muss die
                        App einmal Synchronisiert werden, damit die neueste Struktur geladen wird.
                    </div>
                </div>
            );
        }

        if (this.state.dealNotFound) {
            return (
                <div style={{ padding: "20px", paddingBottom: "0px" }}>
                    <div className="alert alert-danger">
                        <b>Angebot nicht gefunden</b>
                        <br />
                        Das Angebot mit der ID <span className="badge badge-danger">
                            #{this.props.match.params.id}
                        </span>{" "}
                        wurde nicht gefunden
                    </div>
                </div>
            );
        }

        let pageLoader;
        if (this.state.isLoading) {
            pageLoader = <PageLoader />;
        }

        let customer_selects;
        if (this.state.customers_list && this.state.customers_list.length > 0) {
            customer_selects = this.state.customers_list.map(element => (
                <option key={"customersel_" + element.id} value={element.id}>
                    {element.name}
                </option>
            ));
        }

        let customer_contacts_selects;
        if (
            this.state.customer &&
            this.state.customer.contact_persons !== undefined &&
            this.state.customer.contact_persons.length > 0
        ) {
            customer_contacts_selects = this.state.customer.contact_persons.map(element => (
                <option key={"customercontact_" + element.id} value={element.id}>
                    {element.first_name} {element.name}
                </option>
            ));
        }

        let deal_info_text_editor;
        if (this.state.editInfoText) {
            let infoTextType = "catalog-group-text";
            if (this.state.editInfoText === "customer_info_text") {
                infoTextType = "customer-text";
            } else if (this.state.editInfoText === "nu_info_text") {
                infoTextType = "subcontractor-text";
            }

            if (infoTextType === "catalog-group-text") {
                let groups = CatalogsHelper.getGroups();
                let groups_info_texts = groups.map(element => (
                    <div key={"group_text_" + element.id} className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className="form-group">
                                    <div style={{ float: "right" }}>
                                        <PredefinedTextSelector
                                            type={infoTextType}
                                            catalogGroup={element.id}
                                            target={"#group_text_" + element.id}
                                            updateEvent={this.infoGroupTextUpdate.bind(this)}
                                            updateData={{
                                                text_type: this.state.editInfoText,
                                                catalog_group: element.id
                                            }}
                                        />
                                    </div>
                                    <label>{element.name}</label>
                                    <textarea
                                        className="form-control"
                                        data-group={element.id}
                                        id={"group_text_" + element.id}
                                        value={
                                            this.state.group_info_text["group_" + element.id] !== undefined
                                                ? this.state.group_info_text["group_" + element.id]
                                                : ""
                                        }
                                        onChange={this.infoGroupTextChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ));

                deal_info_text_editor = (
                    <div className="row" style={{ marginTop: "15px" }}>
                        {groups_info_texts}
                    </div>
                );
            } else {
                deal_info_text_editor = (
                    <div className="panel panel-default" style={{ marginTop: "15px" }}>
                        <div className="panel-body">
                            <div className="form-group">
                                <div style={{ textAlign: "right" }}>
                                    <PredefinedTextSelector
                                        type={infoTextType}
                                        target="#editDealInfoText"
                                        updateEvent={this.infoTextUpdate.bind(this)}
                                        updateData={{
                                            text_type: this.state.editInfoText
                                        }}
                                    />
                                </div>
                                <textarea
                                    id="editDealInfoText"
                                    className="form-control"
                                    name={this.state.editInfoText}
                                    data-type={this.state.editInfoText}
                                    onChange={this.changeInfoText.bind(this)}
                                    value={this.state[this.state.editInfoText]}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="content">
                <div className="alert alert-warning">
                    <h4 className="mb-0 pb-0">ALTES ANGEBOTSTOOL</h4>
                </div>
                <Prompt
                    when={this.state.hasChanges}
                    message={location => "Das Angebot wirklich ohne zu Speichern verlassen?"}
                />

                {pageLoader}
                <EditPositionSelector
                    visible={this.state.positionSelector}
                    hide={this.hidePositionSelector.bind(this)}
                    positions={this.state.positions}
                    customer={this.state.data_customer}
                    catalog={this.state.catalog}
                    save={this.savePositions.bind(this)}
                    remove={this.removePosition.bind(this)}
                    openFpeEditor={this.openFpeEditor.bind(this)}
                />

                <EditFPE
                    visible={this.state.editFpeComponent}
                    hide={this.hideFpeComponent.bind(this)}
                    fpes={this.state.fpes}
                    save={this.saveFpe.bind(this)}
                    remove={this.removeFpe.bind(this)}
                    fpe_edit={this.state.fpe_edit}
                />

                <div className="headerAction">
                    <button className="btn btn-success" onClick={this.save.bind(this)}>
                        Speichern
                    </button>
                </div>

                <h1>Angebot bearbeiten</h1>

                {SettingsHelper.getSetting("deals_sync_check_disabled") === true ? (
                    <div className="alert alert-danger">
                        <b>Synchronisations-Überprüfung deaktiviert!</b>
                        <br />
                        In den App-Einstellungen wurde die Synchronisations-Überprüfung deaktiviert. Es kann also sein,
                        dass mit einer veralteten Datenversion gearbeitet wird, wodurch beim hochladen des Angebots ggf.
                        Fehler auftreten können.
                        <br />
                        Dieser Modus sollte nur in "Notfällen" verwendet und schnellstmöglich wieder deaktiviert werden.
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-heading">Bauprojekt</div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="inputCustomer">Kunde</label>
                                            <select
                                                value={this.state.data_customer}
                                                id="inputCustomer"
                                                className="form-control"
                                                name="data_customer"
                                                onChange={this.handleCustomerChange.bind(this)}
                                            >
                                                <option value="0">Kunden auswählen</option>
                                                {customer_selects}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="inputCustomerContact">Ansprechpartner</label>
                                            <select
                                                value={this.state.data_customer_contact}
                                                id="inputCustomerContact"
                                                className="form-control"
                                                name="data_customer_contact"
                                                onChange={this.handleInputChange.bind(this)}
                                            >
                                                <option value="0">Kein Ansprechpartner</option>
                                                {customer_contacts_selects}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="inputType">Projekt-Typ</label>
                                            <select
                                                id="inputType"
                                                className="form-control"
                                                name="data_projecttype"
                                                value={this.state.data_projecttype}
                                                onChange={this.handleInputChange.bind(this)}
                                            >
                                                {this.state.projecttypes_list
                                                    ? this.state.projecttypes_list.map(type => (
                                                          <option key={"projecttype_" + type.id} value={type.id}>
                                                              {type.name}
                                                          </option>
                                                      ))
                                                    : null}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="inputQm">Größe (Quadratmeter)</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="inputQm"
                                                name="data_qm"
                                                value={this.state.data_qm}
                                                onChange={this.handleInputChange.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="inputStreet">Straße</label>
                                            <input
                                                type="text"
                                                id="inputStreet"
                                                name="data_street"
                                                value={this.state.data_street}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="inputHousenumber">Hausnummer</label>
                                            <input
                                                type="text"
                                                id="inputHousenumber"
                                                name="data_housenumer"
                                                value={this.state.data_housenumer}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="inputZip">PLZ</label>
                                            <input
                                                type="number"
                                                id="inputZip"
                                                name="data_zip"
                                                value={this.state.data_zip}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="inputCity">Stadt</label>
                                            <input
                                                type="text"
                                                id="inputCity"
                                                name="data_city"
                                                value={this.state.data_city}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="inputLocation">Etage/Lage</label>
                                            <input
                                                type="text"
                                                id="inputLocation"
                                                name="data_location"
                                                value={this.state.data_location}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Begehungsdatum</label>
                                            <input
                                                type="date"
                                                id="inputDate"
                                                name="data_date"
                                                value={this.state.data_date}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Vermietungstermin</label>
                                            <input
                                                type="date"
                                                id="inputDateRental"
                                                name="data_date_rental"
                                                value={this.state.data_date_rental}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.no_catalog ? (
                        <div className="col-md-12">
                            <div className="alert alert-danger">
                                Dem ausgewählten Kunden wurde kein Katalog zugeordnet (eventuell Synchronisieren).
                            </div>
                        </div>
                    ) : null}

                    <div
                        className="col-md-12 dealActionToolbar"
                        id="dealActionToolbar"
                        style={{ position: "relative", zIndex: 10 }}
                    >
                        <div className="pull-right">
                            <button className="btn btn-info" data-fpe="new" onClick={this.openFpeEditor.bind(this)}>
                                FPE hinzufügen
                            </button>
                            <button className="btn btn-primary" onClick={this.openPositionSelector.bind(this)}>
                                Positionen aktualisieren
                            </button>
                        </div>
                    </div>

                    <div className="col-md-12" id="dealPositionsListContainer">
                        <EditPositionsList
                            positions={this.state.positions}
                            catalog={this.state.catalog}
                            fpes={this.state.fpes}
                            openFpeEditor={this.openFpeEditor.bind(this)}
                            qm={this.state.data_qm}
                            editItem={this.state.editPositionItem}
                            save={this.savePositions.bind(this)}
                            remove={this.removePosition.bind(this)}
                        />
                    </div>

                    <div className="col-md-12">
                        <div className="btn-group">
                            <button
                                className={
                                    this.state.editInfoText === "group_info_text" ? "btn btn-default" : "btn btn-white"
                                }
                                data-type="group_info_text"
                                onClick={this.changeInfoTextToggle.bind(this)}
                            >
                                Gewerks-Info-Texte anzeigen
                            </button>
                            <button
                                className={
                                    this.state.editInfoText === "customer_info_text"
                                        ? "btn btn-default"
                                        : "btn btn-white"
                                }
                                data-type="customer_info_text"
                                onClick={this.changeInfoTextToggle.bind(this)}
                            >
                                Kunden-Info-Texte anzeigen
                            </button>
                            <button
                                className={
                                    this.state.editInfoText === "nu_info_text" ? "btn btn-default" : "btn btn-white"
                                }
                                data-type="nu_info_text"
                                onClick={this.changeInfoTextToggle.bind(this)}
                            >
                                Nachunternehmer-Info-Texte anzeigen
                            </button>
                        </div>

                        {deal_info_text_editor}
                    </div>
                </div>
            </div>
        );
    }
}
