export const APP_HAS_UPDATE = "APP_HAS_UPDATE";
export const APP_HAS_UPDATE_SUCCESS = "APP_HAS_UPDATE_SUCCESS";

export function appHasUpdate(hasUpdate) {
    return { type: APP_HAS_UPDATE, payload: { hasUpdate: hasUpdate } };
}

export function appHasUpdateSuccess(hasUpdateSuccess) {
    return { type: APP_HAS_UPDATE, payload: { hasUpdateSuccess: hasUpdateSuccess } };
}
