import React, { Component } from "react";
import TextsHelper from "services/TextsHelper";
import Icon from "components/Icon";
import $ from "jquery";

export default class PredefinedTextSelector extends Component {
    state = {
        visible: false,
        texts: []
    };

    toggleVisibility() {
        if (this.state.visible) {
            this.setState({ visible: false });
        } else {
            this.setState({ visible: true, texts: [] });
        }
    }

    addTexts() {
        if (this.props.target !== undefined && this.props.target.trim() !== "") {
            let target = $(this.props.target);
            let texts = "";
            let update_data = null;
            let data = TextsHelper.getTexts();

            for (let i in this.state.texts) {
                for (let d in data) {
                    if (data[d].id !== undefined && parseInt(data[d].id, 10) === parseInt(this.state.texts[i], 10)) {
                        if (texts.trim() !== "" || (target.length > 0 && target.val().trim() !== "")) {
                            texts = texts + "\n" + data[d].text;
                        } else {
                            texts = data[d].text;
                        }
                    }
                }
            }

            if (target && target.length > 0) {
                if (this.props.updateData !== undefined) {
                    update_data = this.props.updateData;
                }

                if (this.props.updateEvent !== undefined) {
                    this.props.updateEvent(target, texts, update_data);
                } else {
                    target.val(target.val() + texts);
                }
            }
            this.setState({ visible: false });
        }
    }

    selectText(event) {
        let texts = this.state.texts;
        let status = true;
        let remove = null;
        if (texts && texts.length > 0) {
            for (let t in texts) {
                if (parseInt(texts[t], 10) === parseInt(event.target.getAttribute("data-id"), 10)) {
                    status = false;
                    remove = t;
                }
            }
        }

        if (status) {
            texts.push(parseInt(event.target.getAttribute("data-id"), 10));
        }

        if (remove !== null && remove >= 0) {
            texts.splice(remove);
        }

        this.setState({
            texts: texts
        });
    }

    textIsSelected(element_id, texts) {
        if (texts && texts.length > 0) {
            for (let t in texts) {
                if (parseInt(texts[t], 10) === parseInt(element_id, 10)) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        let selector;

        if (this.state.visible) {
            let texts = <div className="alert alert-info">Keine Texte gefunden.</div>;

            let array = [];
            let data = TextsHelper.getTexts();

            for (let i in data) {
                if (data[i].id !== undefined && data[i].type === this.props.type) {
                    if (this.props.type === "position-info-text") {
                        if (!data[i].connections || data[i].connections.length < 0) {
                            continue;
                        }

                        for (let c in data[i].connections) {
                            if (
                                data[i].connections[c].text_type !== "position-info-text" ||
                                parseInt(data[i].connections[c].target_id, 10) !== parseInt(this.props.catalogItem, 10)
                            ) {
                                continue;
                            }
                            array.push(data[i]);
                            break;
                        }
                    } else if (this.props.type === "catalog-group-text") {
                        if (!data[i].connections || data[i].connections.length < 0) {
                            continue;
                        }

                        for (let c in data[i].connections) {
                            if (
                                data[i].connections[c].text_type !== "catalog-group-text" ||
                                parseInt(data[i].connections[c].target_id, 10) !== parseInt(this.props.catalogGroup, 10)
                            ) {
                                continue;
                            }
                            array.push(data[i]);
                            break;
                        }
                    } else {
                        array.push(data[i]);
                    }
                }
            }

            if (array && array.length > 0) {
                let texts_state = this.state.texts;
                let texts_selects = array.map(element => (
                    <div
                        key={"text_" + element.id}
                        className={"textElement" + (this.textIsSelected(element.id, texts_state) ? " selected" : "")}
                        data-id={element.id}
                        onClick={this.selectText.bind(this)}
                    >
                        <div className="title" data-id={element.id}>
                            {element.name}
                        </div>
                        <div className="text" data-id={element.id}>
                            {element.text}
                        </div>
                    </div>
                ));

                texts = (
                    <div>
                        {texts_selects}
                        <div className="text-right">
                            <button className="btn btn-success" onClick={this.addTexts.bind(this)}>
                                Text einfügen
                            </button>
                        </div>
                    </div>
                );
            }

            selector = (
                <div className="textSelector">
                    <div className="textSelectorBg" onClick={this.toggleVisibility.bind(this)} />
                    <div className="textSelectorWrapper">
                        <div className="textSelectorTitle">
                            <div
                                className="pull-right"
                                onClick={this.toggleVisibility.bind(this)}
                                style={{ padding: "5px", paddingTop: "1px", cursor: "pointer" }}
                            >
                                <Icon icon="times" />
                            </div>
                            Vordefinierten Text auswählen
                        </div>

                        <div className="textSelectorContent">{texts}</div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {selector}
                <button className="btn btn-default btn-xs" onClick={this.toggleVisibility.bind(this)}>
                    Text einfügen
                </button>
            </div>
        );
    }
}
