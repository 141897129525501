import React, { Component } from 'react';

export default class PageLoader extends Component {

    render() {
        return (
            <div className="page-loader">
                <div className="page-loader-container">
                	<div className="page-loader-msg">
                    	Daten werden geladen ...
                    </div>
                </div>
            </div>
        );
    }

}
