import React from "react";
import { connect } from "react-redux";
import ScrollBar from "./ScrollBar";

const Toolbar = props => {
    let disabled = false;
    if (props.loading_maindata || props.loading_deal_data || props.loading_customer_catalog || props.is_saving) {
        disabled = true;
    }

    return (
        <ScrollBar>
            <div className="scroll-bar-content">
                <div
                    className="visible-scrolled"
                    style={{
                        float: "left",
                        fontSize: "18px",
                        paddingTop: "3px",
                        paddingRight: "10px"
                    }}
                >
                    {props.deal_key && props.deal_key.trim() !== ""
                        ? props.deal_key + " bearbeiten"
                        : "Neues Angebot erstellen"}
                </div>

                <div className="btn-group float-right">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={props.newFpe}
                        disabled={disabled || !props.data_catalog}
                    >
                        Neue FPE
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={props.openSelector}
                        disabled={disabled || !props.data_catalog}
                    >
                        Positionen aktualisieren
                    </button>
                    <button type="button" className="btn btn-success" onClick={props.save} disabled={disabled}>
                        Speichern
                    </button>
                </div>

                {props.children}
                <div style={{ clear: "both" }}></div>
            </div>
        </ScrollBar>
    );
};

const mapStateToProps = state => ({
    deal_key: state.dealtool.deal_key,
    is_saving: state.dealtool.is_saving,
    data_catalog: state.dealtool.data_catalog,
    loading_maindata: state.dealtool.loading_maindata,
    loading_deal_data: state.dealtool.loading_deal_data,
    loading_customer_catalog: state.dealtool.loading_customer_catalog
});
const mapDispatch = dispatch => ({
    newFpe: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "edit_fpe", value: "new" } })
});
export default connect(mapStateToProps, mapDispatch)(Toolbar);
