import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import DealHelper from "../DealHelper";
import EditPosition from "./EditPosition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Selector = props => {
    let content;
    if (props.show_selector) {
        let positions = [];
        if (props.data_catalog.categories && props.data_catalog.categories.length > 0) {
            for (let c in props.data_catalog.categories) {
                if (props.data_catalog.categories[c].id === props.selector_category || props.show_only_favorites) {
                    if (props.data_catalog.categories[c].items && props.data_catalog.categories[c].items.length > 0) {
                        for (let i in props.data_catalog.categories[c].items) {
                            let selected = false;
                            let showPosition = props.show_only_favorites ? false : true;
                            let isFavorite = false;
                            for (let p in props.positions) {
                                if (props.positions[p].id === props.data_catalog.categories[c].items[i].id) {
                                    selected = true;
                                }
                            }

                            for (let f in props.favorites) {
                                if (props.data_catalog.categories[c].items[i].id === props.favorites[f].item_id) {
                                    showPosition = true;
                                    isFavorite = true;
                                    break;
                                }
                            }

                            if (showPosition) {
                                positions.push(
                                    <li
                                        key={"dealtool-pos-" + props.data_catalog.categories[c].items[i].id}
                                        data-id={props.data_catalog.categories[c].items[i].id}
                                        className={
                                            (props.selector_item === props.data_catalog.categories[c].items[i].id
                                                ? "active"
                                                : "") + (selected ? " selected" : "")
                                        }
                                        onClick={props.changeItem}
                                    >
                                        <span className="badge badge-secondary mr-1">
                                            {DealHelper.itemNumber(props.data_catalog.categories[c].items[i])}
                                        </span>
                                        {props.data_catalog.categories[c].items[i].name}
                                        {isFavorite ? (
                                            <FontAwesomeIcon icon={["far", "star"]} className="ml-1" />
                                        ) : null}
                                    </li>
                                );
                            }
                        }
                    }
                }
            }
        }

        content = (
            <div className="dealtool-positions-selector">
                <div className="modal-title">
                    <button type="button" className="btn btn-secondary float-right" onClick={props.close}>
                        Schließen
                    </button>
                    {props.show_only_favorites ? (
                        <button
                            type="button"
                            className="btn btn-warning mr-1 float-right"
                            onClick={props.showAllPositions}
                        >
                            <FontAwesomeIcon icon={["fas", "star"]} className="mr-1" />
                            Alle Positionen anzeigen
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-secondary btn-bordered mr-1 float-right"
                            onClick={props.showOnlyFavorites}
                        >
                            <FontAwesomeIcon icon={["far", "star"]} className="mr-1" />
                            Nur Favoriten anzeigen
                        </button>
                    )}
                    <h1>Positionen aktualisieren</h1>
                    {props.data_catalog ? props.data_catalog.name : ""}
                </div>
                <ul className="dealtool-positions-categories">
                    {props.show_only_favorites ? (
                        <li className="plain-category-element">Favoriten:</li>
                    ) : props.data_catalog.categories && props.data_catalog.categories.length > 0 ? (
                        props.data_catalog.categories.map(cat => (
                            <li
                                key={"selector_category_btn" + cat.id}
                                data-id={cat.id}
                                onClick={props.changeCategory}
                                className={props.selector_category === cat.id ? "active" : ""}
                            >
                                {cat.name}
                            </li>
                        ))
                    ) : null}
                </ul>
                <div className="dealtool-positions-wrapper">
                    <ul className="dealtool-positions-list">{positions}</ul>
                    <div className="dealtool-positions-container">
                        <EditPosition
                            offline={props.offline ? true : false}
                            apiHelper={props.apiHelper}
                            loadFavorites={props.loadFavorites}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Modal
            isOpen={props.show_selector}
            onAfterOpen={() => {}}
            onRequestClose={props.close}
            ariaHideApp={false}
            style={{
                overlay: {
                    zIndex: 50000
                },
                content: {
                    padding: 0
                }
            }}
        >
            {content}
        </Modal>
    );
};

const mapStateToProps = state => ({
    show_selector: state.dealtool.show_selector,
    selector_category: state.dealtool.selector_category,
    selector_item: state.dealtool.selector_item,
    data_catalog: state.dealtool.data_catalog,
    positions: state.dealtool.positions,
    favorites: state.dealtool.data_catalog_favorites,
    show_only_favorites: state.dealtool.show_only_favorites
});
const mapDispatch = dispatch => ({
    close: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_selector", value: false } }),
    changeCategory: e =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "selector_category", value: parseInt(e.currentTarget.getAttribute("data-id")) }
        }),
    changeItem: e =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "selector_item", value: parseInt(e.currentTarget.getAttribute("data-id")) }
        }),
    showAllPositions: e => {
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "show_only_favorites", value: false }
        });
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "selector_item", value: null }
        });
    },
    showOnlyFavorites: e => {
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "show_only_favorites", value: true }
        });
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "selector_item", value: null }
        });
    }
});
export default connect(mapStateToProps, mapDispatch)(Selector);
