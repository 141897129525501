import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import moment from "moment";
import Icon from "components/Icon";
import DBHelper from "../services/DBHelper";
import { confirmAlert } from "react-confirm-alert";
import DealsHelper from "../services/DealsHelper";
import ApiHelper from "../services/ApiHelper";

export default class DealsList extends Component {
    state = {
        isLoading: true,
        isUploading: false,
        deals: null,
        dealContextMenu: null,
        copyDealOpen: null,
        copy_street: "",
        copy_housenumber: "",
        copy_zip: "",
        copy_city: "",
        copy_location: "",
        copy_qm: "",
    };

    componentDidMount() {
        this.loadDeals();
    }

    async loadDeals() {
        const deals = await DBHelper.getAll("deals");

        this.setState({
            isLoading: false,
            deals: deals && deals.length > 0 ? deals : null,
        });
    }

    closeContextMenu = () => {
        this.setState({
            dealContextMenu: null,
        });
    };

    dealOptions = () => {
        if (!this.state.dealContextMenu) {
            return null;
        }

        let deal;
        for (let i in this.state.deals) {
            if (this.state.deals[i].data && this.state.deals[i].data.id === this.state.dealContextMenu) {
                deal = this.state.deals[i].data;
            }
        }

        if (!deal) {
            return "Angebot nicht gefunden ...";
        }

        return (
            <div className="deal-options">
                <div className="deal-options-title">
                    {deal.offline_project.street && deal.offline_project.street.trim() !== ""
                        ? deal.offline_project.street +
                          " - " +
                          deal.offline_project.zip +
                          " " +
                          deal.offline_project.city
                        : "Unbekanntes Bauprojekt"}

                    {deal.offline_project.location && deal.offline_project.location.trim() !== ""
                        ? ", " + deal.offline_project.location
                        : null}
                </div>
                <ul className="deal-options-menu">
                    <li
                        onClick={(event) => {
                            event.preventDefault();
                            this.copyDealStart(deal);
                            this.setState({ dealContextMenu: null });
                        }}
                    >
                        <Icon icon="copy" />
                        Angebot kopieren
                    </li>
                    <li
                        onClick={(event) => {
                            event.preventDefault();
                            this.uploadDealStart(deal);
                            this.setState({ dealContextMenu: null });
                        }}
                    >
                        <Icon icon="upload" />
                        {deal.uploaded !== undefined && deal.uploaded ? "Erneut hochladen" : "Angebot hochladen"}
                    </li>
                    <li
                        onClick={(event) => {
                            event.preventDefault();
                            this.exportDeal(deal);
                            this.setState({ dealContextMenu: null });
                        }}
                    >
                        <Icon icon="cloud-download-alt" />
                        Angebot exportieren
                    </li>
                    <li
                        onClick={(event) => {
                            event.preventDefault();
                            this.removeDeal(deal.id);
                            this.setState({ dealContextMenu: null });
                        }}
                    >
                        <Icon icon="times" />
                        Angebot entfernen
                    </li>
                </ul>
            </div>
        );
    };

    closeCopyDeal = (e) => {
        this.setState({
            copyDealOpen: null,
        });
    };

    copyDealStart = (deal) => {
        this.setState({
            copyDealOpen: deal,
            copy_street: deal.offline_project.street,
            copy_housenumber: deal.offline_project.housenumber,
            copy_zip: deal.offline_project.zip,
            copy_city: deal.offline_project.city,
            copy_location: deal.offline_project.location,
            copy_qm: deal.offline_project.qm,
        });
    };

    copyDealAddressChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    copyDeal = () => {
        const newId = "deal_offline_" + Math.floor(Date.now() / 1000).toString();
        const newDeal = {
            ...this.state.copyDealOpen,
            id: newId,
            isCopy: this.state.copyDealOpen.id,
            offline_project: {
                ...this.state.copyDealOpen.offline_project,
                street: this.state.copy_street,
                housenumber: this.state.copy_housenumber,
                zip: this.state.copy_zip,
                city: this.state.copy_city,
                location: this.state.copy_location,
                qm: this.state.copy_qm,
            },
        };

        DBHelper.write("deals", [
            {
                id: newId,
                data: newDeal,
            },
        ]);

        this.closeCopyDeal();
        this.loadDeals();
    };

    copyDealRender = () => {
        return (
            <React.Fragment>
                <div style={{ background: "#eee", margin: -20, marginBottom: 15, padding: 20 }}>
                    <button type="button" className="btn btn-secondary btn-sm float-right" onClick={this.closeCopyDeal}>
                        Schließen
                    </button>
                    <h3 style={{ margin: 0, padding: 0 }}>
                        Angebot kopieren <span style={{ fontSize: 16 }}>> Bauprojekt-Adresse bearbeiten</span>
                    </h3>
                    <div className="clearfix" />
                </div>

                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-group">
                            <label>Straße</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.copy_street}
                                name="copy_street"
                                onChange={this.copyDealAddressChange}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>Hausnummer</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.copy_housenumber}
                                name="copy_housenumber"
                                onChange={this.copyDealAddressChange}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label>PLZ</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.copy_zip}
                                name="copy_zip"
                                onChange={this.copyDealAddressChange}
                            />
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className="form-group">
                            <label>Stadt</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.copy_city}
                                name="copy_city"
                                onChange={this.copyDealAddressChange}
                            />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>Lage / Etage</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.copy_location}
                                name="copy_location"
                                onChange={this.copyDealAddressChange}
                            />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>Größe (qm)</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.copy_qm}
                                name="copy_qm"
                                onChange={this.copyDealAddressChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="text-right">
                    <button type="button" className="btn btn-success btn-sm" onClick={this.copyDeal}>
                        Angebot kopieren
                    </button>
                </div>
            </React.Fragment>
        );
    };

    uploadDealStart = (deal) => {
        confirmAlert({
            title: "Angebot hochladen",
            message: "Soll das Angebot hochgeladen werden?",
            buttons: [
                {
                    label: "Abbrechen",
                    onClick: () => {},
                },
                {
                    label: "Hochladen",
                    onClick: () => {
                        this.setState({
                            isUploading: true,
                        });

                        ApiHelper.request("/upload", deal, "v2")
                            .then((response) => {
                                if (
                                    response.status === "success" &&
                                    response.data &&
                                    response.data.deal_id &&
                                    response.data.deal_id > 0
                                ) {
                                    deal.upload_data = response.data;
                                    DBHelper.write("deals", [
                                        {
                                            id: deal.id,
                                            data: deal,
                                        },
                                    ]);

                                    confirmAlert({
                                        title: "Angebot erfolgreich hochgeladen",
                                        message: null,
                                        childrenElement: () => <div>Erfolg!</div>,
                                        buttons: [
                                            {
                                                label: "Okay",
                                                onClick: () => {},
                                            },
                                        ],
                                    });
                                } else {
                                    let errorMessages;
                                    if (response.errors && response.errors.length > 0) {
                                        let errorsCount = 0;
                                        errorMessages = response.errors.map((error) => (
                                            <div key={"alert_error_" + errorsCount++} style={{ color: "red" }}>
                                                {error}
                                            </div>
                                        ));
                                    }

                                    confirmAlert({
                                        title: "Fehler beim hochladen",
                                        message: null,
                                        childrenElement: () => <div>{errorMessages}</div>,
                                        buttons: [
                                            {
                                                label: "Okay",
                                                onClick: () => {},
                                            },
                                        ],
                                    });
                                }

                                this.setState({
                                    isUploading: false,
                                });
                            })
                            .catch((e) => {
                                this.setState({
                                    isUploading: false,
                                });

                                confirmAlert({
                                    title: "Kritischer Fehler beim hochladen",
                                    message: null,
                                    childrenElement: () => (
                                        <div>
                                            Es ist ein unerwarteter Fehler beim Hochladen aufgetreten. Ursprung des
                                            Fehlers ist wahrscheinlich die App und nicht der Server!
                                        </div>
                                    ),
                                    buttons: [
                                        {
                                            label: "Okay",
                                            onClick: () => {},
                                        },
                                    ],
                                });
                            });
                    },
                },
            ],
        });
    };

    removeDeal = (dealId) => {
        confirmAlert({
            title: "Angebot entfernen",
            message: "Soll das Angebot wirklich entfernt werden?",
            buttons: [
                {
                    label: "Abbrechen",
                    onClick: () => {},
                },
                {
                    label: "Entfernen",
                    onClick: () => {
                        DealsHelper.removeDeal(dealId);
                        this.loadDeals();
                    },
                },
            ],
        });
    };

    exportDeal = (deal) => {
        deal.export_moment = moment().toISOString();

        let element = document.createElement("a");
        let file = new Blob([JSON.stringify(deal)], { type: "text/json" });
        element.href = URL.createObjectURL(file);
        element.download = deal.id + ".lemo";
        element.click();
    };

    render() {
        return (
            <React.Fragment>
                {this.state.isUploading ? (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            background: "rgba(255,255,255,0.6)",
                            zIndex: 999999,
                        }}
                    >
                        <div
                            style={{
                                width: 280,
                                background: "#fff",
                                color: "#333",
                                textAlign: "center",
                                top: "45%",
                                left: "50%",
                                position: "fixed",
                                marginLeft: -140,
                                padding: 15,
                                boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 11px",
                                borderRadius: 8,
                            }}
                        >
                            <div style={{ fontSize: 24 }}>
                                <Icon icon="spinner" spin />
                            </div>
                            Angebot wird hochgeladen ...
                        </div>
                    </div>
                ) : null}
                <ReactModal
                    isOpen={this.state.dealContextMenu && this.state.dealContextMenu.trim() !== "" ? true : false}
                    ariaHideApp={false}
                    onRequestClose={this.closeContextMenu}
                    style={{
                        content: {
                            width: "280px",
                            left: "50%",
                            marginLeft: "-140px",
                            height: "200px",
                            padding: "0px",
                        },
                    }}
                >
                    {this.dealOptions()}
                </ReactModal>

                <ReactModal
                    isOpen={this.state.copyDealOpen ? true : false}
                    ariaHideApp={false}
                    onRequestClose={this.closeCopyDeal}
                >
                    {this.copyDealRender()}
                </ReactModal>

                <div className="content pb-0">
                    <h4>Angebote</h4>
                </div>

                {this.state.deals && this.state.deals.length > 0 ? (
                    <div className="list-view">
                        {this.state.deals.map((element) => {
                            if (!element || !element.data) {
                                return null;
                            }

                            const deal = element.data;
                            let uploadInfo = null;
                            if (deal.upload_data && deal.upload_data.deal_id && deal.upload_data.uploaded_at) {
                                uploadInfo = (
                                    <span>
                                        <span style={{ fontWeight: "bold", color: "green" }}>
                                            Angebot hochgeladen:{" "}
                                        </span>
                                        {deal.upload_data.deal_key}
                                        {" - "}
                                        {moment(deal.upload_data.uploaded_at).fromNow()}
                                    </span>
                                );
                            }

                            return (
                                <Link
                                    to={"/deals/" + deal.id + "/editor"}
                                    key={deal.id}
                                    className="list-item"
                                    id={deal.id}
                                >
                                    <div className="title">
                                        <div className="float-right">
                                            <button
                                                className="btn btn-white btn-sm"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ dealContextMenu: deal.id });
                                                }}
                                                style={{
                                                    padding: "5px",
                                                    paddingTop: "2px",
                                                    lineHeight: "18px",
                                                    border: "none",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <Icon icon="cogs" data-id={deal.id} style={{ fontSize: "18px" }} />
                                            </button>
                                        </div>
                                        {deal.offline_project.street && deal.offline_project.street.trim() !== ""
                                            ? deal.offline_project.street +
                                              " " +
                                              deal.offline_project.housenumber +
                                              " - " +
                                              deal.offline_project.zip +
                                              " " +
                                              deal.offline_project.city
                                            : "Unbekanntes Bauprojekt"}

                                        {deal.offline_project.location && deal.offline_project.location.trim() !== ""
                                            ? ", " + deal.offline_project.location
                                            : null}
                                    </div>
                                    <div className="clearfix" style={{ fontSize: "10px" }}>
                                        {uploadInfo ? (
                                            uploadInfo
                                        ) : (
                                            <span style={{ color: "#333", textStyle: "italic" }}>
                                                Angebot noch nicht hochgeladen
                                            </span>
                                        )}

                                        {deal.offline_project &&
                                        deal.offline_project.project_number &&
                                        deal.offline_project.project_number.trim() !== "" ? (
                                            <span className="ml-1 badge badge-light">
                                                Projektnummer:{" "}
                                                <span style={{ fontWeight: "normal" }}>
                                                    {deal.offline_project.project_number}
                                                </span>
                                            </span>
                                        ) : null}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                ) : (
                    <div>Es wurden noch keine Angebote erstellt</div>
                )}
            </React.Fragment>
        );
    }
}
