import React, { Component } from "react";
import SettingsHelper from "../services/SettingsHelper";

export default class Einstellungen extends Component {
    state = {
        settings: {
            deals_sync_check_disabled: false
        }
    };

    constructor() {
        super();

        this.booleanValueChange = this.booleanValueChange.bind(this);

        let settings = SettingsHelper.getSettings();
        if (settings && Object.keys(settings).length > 0) {
            let keys = Object.keys(settings);
            for (let key in keys) {
                let settingName = keys[key];
                let settingValue = settings[settingName];

                if (settings[settingName] !== undefined) {
                    settings[settingName] = settingValue;
                }
            }

            this.state = {
                settings: settings
            };
        }
    }

    booleanValueChange(name) {
        let settings = this.state.settings;
        settings[name] = !settings[name];

        this.setState({
            settings: settings
        });
        SettingsHelper.setSettings(settings);
    }

    render() {
        return (
            <div className="content">
                <h1>Einstellungen</h1>

                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">Einstellungen Angebote</div>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>Synchronisations-Überprüfung deaktivieren</td>
                                        <td>
                                            <BooleanToggle
                                                checked={this.state.settings.deals_sync_check_disabled}
                                                name="deals_sync_check_disabled"
                                                onChange={this.booleanValueChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const BooleanToggle = props => {
    return (
        <label className={"switch" + (props.checked ? " checked" : "")} onClick={() => props.onChange(props.name)}>
            <span className="slider" />
        </label>
    );
};
