class AuthHelper {
    user = null;

    setLogin(data) {
        let user_data = {
            user_id: data.user_id,
            user_email: data.email,
            user_password: data.password,
            username: data.name,
            env: data.env,
            token: data.token
        };
        localStorage.setItem("user", JSON.stringify(user_data));
    }

    load() {
        let data = localStorage.getItem("user");

        if (data && data.trim() !== "") {
            let json = JSON.parse(data);

            if (json && json.user_id !== undefined && json.user_id > 0) {
                this.user = json;
                return true;
            }
        }
    }

    checkLogin() {
        if (this.user === undefined || !this.user) {
            this.load();
        }

        if (this.user && this.user.user_id !== undefined && this.user.user_id > 0) {
            return true;
        }
        return false;
    }

    logout() {
        this.user = null;

        localStorage.setItem("user", "");
    }
}

export default new AuthHelper();
