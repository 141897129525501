import React, { Component } from "react";
import $ from "jquery";
import EditPosition from "./EditPosition";

export default class EditPositionSelector extends Component {
    state = {
        activeTab: 0,
        activeItem: 0
    };

    componentDidMount() {
        let self = this;
        $(window)
            .unbind("resize")
            .bind("resize", function() {
                if (self.props.visible) {
                    let new_height =
                        parseInt($(window).height(), 10) -
                        parseInt($("#dealPositionSelectorHead").outerHeight(true), 10) +
                        "px";
                    $("#selector-list").css("height", new_height);
                }
            });
    }

    componentDidUpdate() {
        $(window).resize();
    }

    componentWillUnmount() {
        $(window).unbind("resize");
    }

    changeTab(event) {
        this.setState({
            activeTab: parseInt(event.target.getAttribute("data-pos"), 10),
            activeItem: 0
        });
    }

    changePosition(event) {
        this.setState({
            activeItem: parseInt(event.target.getAttribute("data-id"), 10)
        });
    }

    hide() {
        this.props.hide();
    }

    positionIsInDeal(item_id) {
        if (this.props.positions !== undefined && this.props.positions.length > 0 && item_id > 0) {
            for (let i in this.props.positions) {
                if (this.props.positions[i].item_id !== undefined && this.props.positions[i].item_id === item_id) {
                    return true;
                }
            }
        }
        return false;
    }

    closePosition() {
        this.setState({
            activeItem: 0
        });
    }

    render() {
        if (!this.props.visible) {
            return <span />;
        }

        if (!this.props.customer || this.props.customer < 1) {
            return (
                <div className="dealPositionSelector">
                    <div className="selectorContent">
                        <button className="btn btn-default pull-right" onClick={this.hide.bind(this)}>
                            Schließen
                        </button>
                        <h3>Positionen aktualisieren</h3>
                        Es muss zuerst ein Kunde ausgewählt werden.
                    </div>
                </div>
            );
        }

        let selector_nav;
        let selector_items;
        if (this.props.catalog && this.props.catalog.items !== undefined && this.props.catalog.items.length > 0) {
            // Kategorien
            let categories = this.props.catalog.categories.map(element => (
                <div
                    key={"category_" + element.id}
                    className={
                        "selector-nav-title " +
                        (element.id === this.state.activeTab ? "active" : "") +
                        (element.hidden !== undefined && element.hidden ? "hidden" : "")
                    }
                    onClick={this.changeTab.bind(this)}
                    data-pos={element.id}
                >
                    {element.name}
                </div>
            ));

            // Positionen
            let items = [];
            for (let k in this.props.catalog.items) {
                if (
                    this.props.catalog.items[k].category_id !== undefined &&
                    parseInt(this.props.catalog.items[k].category_id, 10) === parseInt(this.state.activeTab, 10)
                ) {
                    items.push(this.props.catalog.items[k]);
                }
            }
            if (items.length > 0) {
                selector_items = items.map(element => (
                    <div
                        key={"item_" + element.id}
                        className={
                            "selector-item" +
                            (this.state.activeItem === element.id ? " active" : "") +
                            (this.positionIsInDeal(element.id) ? " in-deal" : "")
                        }
                        onClick={this.changePosition.bind(this)}
                        data-id={element.id}
                    >
                        <span className="label label-default" style={{ marginRight: "5px" }}>
                            {element.gaeb_numbers && element.gaeb_numbers[0]
                                ? element.gaeb_numbers[0].number
                                : element.key}
                        </span>
                        {element.name}
                    </div>
                ));
            } else {
                selector_items = (
                    <div style={{ padding: "20px" }}>
                        <div className="alert alert-info">Keine Positionen in dieser Kategorie.</div>
                    </div>
                );
            }

            selector_nav = (
                <div className="selector-nav">
                    <div
                        key="category_0"
                        className={"selector-nav-title " + (0 === this.state.activeTab ? "active" : "")}
                        onClick={this.changeTab.bind(this)}
                        data-pos="0"
                    >
                        Hauptpositionen
                    </div>
                    {categories}
                </div>
            );
        }

        let activeItem = (
            <div style={{ padding: "20px" }}>
                <div className="alert alert-info">Keine Position ausgewählt.</div>
            </div>
        );
        if (this.state.activeItem && this.state.activeItem > 0) {
            activeItem = (
                <EditPosition
                    save={this.props.save}
                    remove={this.props.remove}
                    item={this.state.activeItem}
                    catalog={this.props.catalog}
                    customer={this.props.customer}
                    positions={this.props.positions}
                    closeEvent={this.closePosition.bind(this)}
                />
            );
        }

        return (
            <div className="dealPositionSelector">
                <div id="dealPositionSelectorHead">
                    <div className="selectorContent">
                        <button className="btn btn-default pull-right" onClick={this.hide.bind(this)}>
                            Schließen
                        </button>
                        <button
                            className="btn btn-primary pull-right"
                            onClick={this.props.openFpeEditor}
                            data-fpe="new"
                        >
                            FPE hinzufügen
                        </button>
                        <h3>Positionen aktualisieren</h3>
                    </div>
                    {selector_nav}
                </div>
                <div id="selector-list" className="selector-list">
                    <div className="selector-list-container" id="selector-list-container">
                        {selector_items}
                    </div>
                    <div
                        className={
                            "selector-current-item" +
                            (this.state.activeItem && this.state.activeItem > 0 ? " has-item" : "")
                        }
                        id="selector-current-item"
                    >
                        {activeItem}
                    </div>
                </div>
            </div>
        );
    }
}
