import DealHelper from "./DealHelper";

class PositionsHelper {
    getSinglePrice(pricetype_id, item, position, value_type, group_id, ignore_factor_or_multiplier) {
        if (!value_type) {
            value_type = "value_customer"; // Standardmäßig mit Kundenpreis rechnen
        }

        // Entweder/Oder Positionen
        if (item.price_property === "either_or") {
            if (item.prices && item.prices.length > 0 && position.price_property_id) {
                for (let p in item.prices) {
                    if (
                        item.prices[p].pricetype_id === pricetype_id &&
                        item.prices[p].group_id === position.price_property_id
                    ) {
                        // Bei Kundenpreisen den Multiplikator berücksichtigen
                        if (value_type === "value_customer") {
                            let multiplier = 1;
                            if (position.price_multiplier !== null && position.price_multiplier !== 1) {
                                multiplier = position.price_multiplier;
                            }
                            return item.prices[p][value_type] * multiplier;
                        }

                        // Bei NU-Preisen die NU-Faktoren berücksichtigen
                        if (value_type === "value" && group_id && group_id > 0 && !ignore_factor_or_multiplier) {
                            let factor = 1;
                            if (
                                position.groups &&
                                position.groups[group_id] &&
                                position.groups[group_id].nufactor !== null &&
                                position.groups[group_id].nufactor !== false &&
                                position.groups[group_id].nufactor !== undefined
                            ) {
                                factor = position.groups[group_id].nufactor;
                            }
                            return item.prices[p][value_type] * factor;
                        }
                        return item.prices[p][value_type];
                    }
                }
            }
            return 0;
        }

        // Normale Positionen (Summe bilden)
        let value = 0;
        if (item.prices && item.prices.length > 0) {
            for (let p in item.prices) {
                if (group_id) {
                    if (item.prices[p].pricetype_id === pricetype_id && item.prices[p].group_id === group_id) {
                        value = value + item.prices[p][value_type];
                    }
                } else {
                    if (item.prices[p].pricetype_id === pricetype_id) {
                        value = value + item.prices[p][value_type];
                    }
                }
            }
        }

        // Bei Kundenpreisen den Multiplikator berücksichtigen
        if (value_type === "value_customer" && !ignore_factor_or_multiplier) {
            let multiplier = 1;
            if (position.price_multiplier !== null && position.price_multiplier !== 1) {
                multiplier = position.price_multiplier;
            }
            return value * multiplier;
        }

        // Bei NU-Preisen die NU-Faktoren berücksichtigen
        if (value_type === "value" && group_id && group_id > 0 && !ignore_factor_or_multiplier) {
            let factor = 1;
            if (
                position.groups &&
                position.groups[group_id] &&
                position.groups[group_id].nufactor !== null &&
                position.groups[group_id].nufactor !== false &&
                position.groups[group_id].nufactor !== undefined
            ) {
                factor = position.groups[group_id].nufactor;
            }
            return value * factor;
        }
        return value;
    }

    getWeighting(group_id, position, item) {
        const group = this.getGroupData(group_id, position);
        if (item.price_property === "either_or") {
            if (position.price_property_id !== parseInt(group_id)) {
                return 0;
            }
        }
        return group && group.weighting !== null
            ? DealHelper.input2Float(group.weighting)
            : DealHelper.input2Float(position.quantity);
    }

    getNUFaktor(group_id, position) {
        const group = this.getGroupData(group_id, position);
        return group && group.nufactor !== null ? DealHelper.input2Float(group.nufactor) : 1;
    }

    getGroupData(group_id, position) {
        if (position.groups && Object.keys(position.groups).length > 0) {
            for (let i in position.groups) {
                if (position.groups[i].id === parseInt(group_id)) {
                    return position.groups[i];
                }
            }
        }
        return null;
    }
}

export default new PositionsHelper();
