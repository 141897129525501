import React, { Component } from "react";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { connect } from "react-redux";
import arrayMove from "array-move";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DragHandle = sortableHandle(() => <FontAwesomeIcon icon="sort" />);

const SortableItem = sortableElement(({ fpe, count, group }) => (
    <li className="fpe-sort-element" data-identiefier={fpe.identifier}>
        <div className="fpe-info-wrapper">
            <div className="fpe-number">{count + 1}.</div>
            <DragHandle />
        </div>
        <div className="fpe-content-wrapper">
            {group ? <b>{group}: </b> : ""}
            {fpe.name}
        </div>
    </li>
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ul className="fpe-sort-list">{children}</ul>;
});

class SortFpes extends Component {
    onSortEnd = ({ oldIndex, newIndex }) => {
        const newOrder = arrayMove(this.props.free_positions, oldIndex, newIndex);
        this.props.setFpes(newOrder);
    };

    render() {
        return (
            <React.Fragment>
                <div className="modal-title">
                    {this.props.hide !== undefined ? (
                        <button type="button" className="btn btn-secondary float-right" onClick={this.props.hide}>
                            Schließen
                        </button>
                    ) : (
                        ""
                    )}
                    <h1>FPEs sortieren</h1>
                </div>

                <div className="content">
                    <div className="card">
                        {this.props.free_positions && this.props.free_positions.length > 0 ? (
                            <SortableContainer onSortEnd={this.onSortEnd} lockAxis="y" useDragHandle>
                                {this.props.free_positions.map((fpe, index) => {
                                    let group = null;
                                    if (fpe.group_id && fpe.group_id > 0) {
                                        for (let i in this.props.groups) {
                                            if (parseInt(this.props.groups[i].id) === parseInt(fpe.group_id)) {
                                                group = this.props.groups[i].name;
                                            }
                                        }
                                    }

                                    return (
                                        <SortableItem
                                            index={index}
                                            fpe={fpe}
                                            count={index}
                                            group={group}
                                            key={"fpe_sort_" + index}
                                        />
                                    );
                                })}
                            </SortableContainer>
                        ) : (
                            <div className="card-body">
                                <div className="alert alert-warning mb-0" classAddition="mb-0">
                                    Dem Angebot wurden noch keine FPEs hinzufügent.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    free_positions: state.dealtool.free_positions,
    groups: state.dealtool.data_groups
});
const mapDispatch = dispatch => ({
    hide: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_fpes_sort", value: false } }),
    setFpes: fpes => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "free_positions", value: fpes } })
});
export default connect(mapStateToProps, mapDispatch)(SortFpes);
