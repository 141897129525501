import { APP_HAS_UPDATE, APP_HAS_UPDATE_SUCCESS } from "./actions";

const initialDashboardState = {
    hasUpdate: false,
    hasUpdateSuccess: false
};

const app = (state = initialDashboardState, action) => {
    switch (action.type) {
        case APP_HAS_UPDATE:
            return {
                ...state,
                hasUpdate: action.payload.hasUpdate
            };
        case APP_HAS_UPDATE_SUCCESS:
            return {
                ...state,
                hasUpdateSuccess: action.payload.hasUpdateSuccess
            };
        default:
            return state;
    }
};

export default app;
