import React, { Component } from 'react';
import CatalogsHelper from '../../services/CatalogsHelper';

export default class EditFPE extends Component {

	state = {
		current_fpe: null,
		data_timekey: null,
		data_name: '',
		data_quantity: 0,
		data_price: 0,
		data_nu_price: 0,
		data_group: 0,
		data_unit: 'st',
		data_contingency: false
	}

	handleValueCalc(event) {
		const element = event.target;
		const type    = element.getAttribute('data-type');
		const target  = element.getAttribute('data-target');

		let val = this.state[target];
		if(type === "plus") {
			val = parseInt(val, 10) + 1;
		}
		else if(type === "minus") {
			val = parseInt(val, 10) - 1;
		}

		this.setState({
			[target]: val
		});
	}

	handleInputChange(event) {
        const target = event.target;
        const name   = target.name;
        let value    = target.type === 'checkbox' ? target.checked : target.value;

        if(target.getAttribute('type') === 'number') {
        	value = parseFloat(value);
        }

        this.setState({
            [name]: value
        });
    }

    changeContingency(event) {
    	const target = event.target;
    	
    	if(!target.value || target.value === "false") {
    		this.setState({
    			data_contingency: false
    		});
    	} else {
    		this.setState({
    			data_contingency: true
    		});
    	}
    }

	componentDidUpdate() {
		if(this.state.current_fpe !== this.props.fpe_edit) {
			if(this.props.fpe_edit === "new") {
				this.setState({
					current_fpe: this.props.fpe_edit,
					data_quantity: 0,
					data_price: 0,
					data_nu_price: 0,
					data_timekey: Math.floor(Date.now()),
					data_name: '',
					data_group: 0,
					data_unit: 'st',
					data_contingency: false
				});
			}

			else {
				for(let f in this.props.fpes) {
					if(f === this.props.fpe_edit) {
						this.setState({
							current_fpe: this.props.fpe_edit,
							data_quantity: this.props.fpes[f].data_quantity,
							data_price: this.props.fpes[f].data_price,
							data_nu_price: this.props.fpes[f].data_nu_price,
							data_timekey: this.props.fpes[f].data_timekey,
							data_name: this.props.fpes[f].data_name,
							data_group: this.props.fpes[f].data_group,
							data_unit: this.props.fpes[f].data_unit,
							data_contingency: (this.props.fpes[f].data_contingency !== undefined) ? this.props.fpes[f].data_contingency : false
						});
					}
				}
			}
		}
	}

	remove() {
		this.props.remove(this.state.current_fpe);
		this.props.hide();
		this.setState({
			current_fpe: null
		});
	}

	save() {
		this.props.save(this.state.current_fpe, {
			data_quantity: this.state.data_quantity,
			data_price: this.state.data_price,
			data_nu_price: this.state.data_nu_price,
			data_name: this.state.data_name,
			data_timekey: this.state.data_timekey,
			data_group: this.state.data_group,
			data_unit: this.state.data_unit,
			data_contingency: this.state.data_contingency
		});
		this.props.hide();

		this.setState({
			current_fpe: null
		});
	}

	render() {
		if(!this.props.visible) {
            return (<span></span>);
        }

        let units = Object.keys(CatalogsHelper.units).map((key) =>
    		<option key={'unit_' + key} value={key}>{CatalogsHelper.units[key]}</option>
    	);

    	let groups = CatalogsHelper.getGroups().map((element) => 
			<option key={'cat_group_' + element.id} value={element.id}>{element.name}</option>
		);

		let remove_button;
		if(this.state.current_fpe && this.state.current_fpe !== "new") {
			remove_button = (<button className="btn btn-danger" onClick={this.remove.bind(this)}>FPE entfernen</button>);
		}

        return (
    		<div className="dealEditFPE" style={{ overflowY: 'scroll' }}>
    			<div className="dealEditFPEContent">
					<button className="btn btn-default pull-right" onClick={this.props.hide}>Schließen</button>
					<h3>FPE bearbeiten / hinzufügen</h3>

					<div className="panel panel-default">
						<div className="panel-heading">FPE Informationen</div>
						<div className="panel-body">
							<div className="row">
								<div className="col-md-8">
									<div className="form-group">
										<label htmlFor="inputFpeName">Bezeichnung</label>
										<textarea id="inputFpeName" className="form-control" rows="5" name="data_name" value={this.state.data_name} onChange={this.handleInputChange.bind(this)}></textarea>
									</div>

									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="inputQuantity">Menge</label>
												<div className="row">
													<div className="col-xs-6">
														<input type="number" id="inputQuantity" className="form-control" name="data_quantity" value={this.state.data_quantity} onChange={this.handleInputChange.bind(this)} />
													</div>
													<div className="col-xs-3">
														<button className="btn btn-default btn-block" data-type="plus" data-target="data_quantity" onClick={this.handleValueCalc.bind(this)}>+</button>
													</div>
													<div className="col-xs-3">
														<button className="btn btn-default btn-block" data-type="minus" data-target="data_quantity" onClick={this.handleValueCalc.bind(this)}>-</button>
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="inputFpePrice">Einzelpreis (netto)</label>
												<div className="row">
													<div className="col-xs-6">
														<input type="number" id="inputFpePrice" className="form-control" name="data_price" value={this.state.data_price} onChange={this.handleInputChange.bind(this)} />
													</div>
													<div className="col-xs-3">
														<button className="btn btn-default btn-block" data-type="plus" data-target="data_price" onClick={this.handleValueCalc.bind(this)}>+</button>
													</div>
													<div className="col-xs-3">
														<button className="btn btn-default btn-block" data-type="minus" data-target="data_price" onClick={this.handleValueCalc.bind(this)}>-</button>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="inputNuPrice">NU Preis</label>
												<div className="row">
													<div className="col-xs-6">
														<input type="number" id="inputNuPrice" className="form-control" name="data_nu_price" value={this.state.data_nu_price} onChange={this.handleInputChange.bind(this)} />
													</div>
													<div className="col-xs-3">
														<button className="btn btn-default btn-block" data-type="plus" data-target="data_nu_price" onClick={this.handleValueCalc.bind(this)}>+</button>
													</div>
													<div className="col-xs-3">
														<button className="btn btn-default btn-block" data-type="minus" data-target="data_nu_price" onClick={this.handleValueCalc.bind(this)}>-</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label htmlFor="inputFpeGroup">Gewerk</label>
										<select id="inputFpeGroup" className="form-control" name="data_group" value={this.state.data_group} onChange={this.handleInputChange.bind(this)}>
											<option value="0">---</option>
											{groups}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="inputFpeUnit">Einzelpreis (netto)</label>
										<select id="inputFpeUnit" className="form-control" name="data_unit" value={this.state.data_unit} onChange={this.handleInputChange.bind(this)}>
											{units}
										</select>
									</div>
									<div className="form-group" style={{ marginBottom: '0px' }}>
										<label htmlFor="inputFpeContingency">Eventualposition</label>
										<select id="inputFpeContingency" style={{ marginBottom: '0px' }} name="data_contingency" className="form-control" value={this.state.data_contingency} onChange={this.changeContingency.bind(this)}>
											<option value={true}>Ja</option>
											<option value={false}>Nein</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="panel-footer text-right">
							{remove_button}
							<button className="btn btn-success" onClick={this.save.bind(this)}>Speichern</button>
						</div>
					</div>
				</div>
    		</div>
    	);
	}

}