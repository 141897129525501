import CatalogsHelper from "./CatalogsHelper";

class CustomersHelper {
    customers_data = null;

    loadData() {
        let data = localStorage.getItem("customers");

        if (data !== undefined && data !== null) {
            this.customers_data = JSON.parse(data);
        } else {
            this.customers_data = null;
        }
    }

    getCustomers() {
        if (this.customers_data === null) {
            this.loadData();
        }

        return this.customers_data;
    }

    async getCustomer(id) {
        if (this.customers_data === null) {
            this.loadData();
        }

        if (this.customers_data === undefined || !this.customers_data || this.customers_data.length < 1) {
            return null;
        }

        for (let k in this.customers_data) {
            if (
                this.customers_data[k] &&
                this.customers_data[k].id !== undefined &&
                parseInt(this.customers_data[k].id, 10) === parseInt(id, 10)
            ) {
                if (!this.customers_data[k].catalog && this.customers_data[k].catalog_id) {
                    let tempCatalog = await CatalogsHelper.getCatalog(this.customers_data[k].catalog_id);

                    if (tempCatalog) {
                        this.customers_data[k].catalog = tempCatalog;
                    } else {
                        this.customers_data[k].catalog = null;
                    }
                }

                return this.customers_data[k];
            }
        }
        return null;
    }
}

export default new CustomersHelper();
