import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import DealHelper from "../DealHelper";

class EditFreeposition extends Component {
    state = {
        fpe: null,
        loading: true,
        isNew: false
    };

    componentDidMount() {
        this.loadFpe();
    }

    loadFpe() {
        if (this.props.edit_fpe !== "new" && this.props.edit_fpe > 0 && this.current_fpe !== this.props.edit_fpe) {
            this.setState({
                fpe: DealHelper.fpePrepareForEdit(this.props.freepositions[this.props.edit_fpe - 1]),
                loading: false,
                isNew: false
            });
        } else {
            this.setState({
                fpe: DealHelper.createFpe("fpe_" + ((Date.now() / 1000) | 0)),
                loading: false,
                isNew: true
            });
        }
    }

    onChange = e => {
        this.setState({
            ...this.state,
            fpe: {
                ...this.state.fpe,
                [e.target.name]: e.target.value
            }
        });
    };

    applyTemplate = e => {
        const id = parseInt(e.target.getAttribute("data-id"));
        for (let i in this.props.templates) {
            if (this.props.templates[i].id === id) {
                let temp = { ...this.state.fpe };
                temp.contingency = this.props.templates[i].contingency ? true : false;
                temp.group_id = this.props.templates[i].catalog_group_id
                    ? this.props.templates[i].catalog_group_id
                    : null;
                temp.name = this.props.templates[i].name ? this.props.templates[i].name : "";
                temp.description = this.props.templates[i].description ? this.props.templates[i].description : "";
                temp.unit = this.props.templates[i].unit ? this.props.templates[i].unit : "psch";
                temp.quantity = this.props.templates[i].quantity ? this.props.templates[i].quantity : 1;
                temp.weighting = this.props.templates[i].weighting ? this.props.templates[i].weighting : null;
                temp.price_subcontractor = this.props.templates[i].price ? this.props.templates[i].price : null;
                temp.price_customer = this.props.templates[i].price_customer
                    ? this.props.templates[i].price_customer
                    : null;

                this.setState({
                    fpe: DealHelper.fpePrepareForEdit(temp)
                });

                $(".ReactModal__Content").animate({ scrollTop: 0 }, "fast");
                break;
            }
        }
    };

    remove = () => {
        let fpes = [...this.props.freepositions];
        if (window.confirm("Soll die geöffnete FPE wirklich entfernt werden?")) {
            for (let i in fpes) {
                if (fpes[i].identifier === this.state.fpe.identifier) {
                    fpes.splice(i, 1);
                }
            }

            this.props.setValue("free_positions", fpes);
            this.props.setValue("edit_fpe", null);
        }
    };

    save = e => {
        e.preventDefault();

        let fpes = [...this.props.freepositions];
        if (this.props.edit_fpe === "new") {
            fpes.push(DealHelper.fpePrepareForSave(this.state.fpe));
        } else {
            fpes[this.props.edit_fpe - 1] = DealHelper.fpePrepareForSave(this.state.fpe);
        }

        this.props.setValue("free_positions", fpes);
        this.props.setValue("edit_fpe", null);
    };

    render() {
        const { fpe, loading } = this.state;
        if (!loading && !fpe) {
            return <div className="alert alert-danger">Es ist ein Fehler beim Laden der FPE aufgetreten.</div>;
        }

        if (loading) {
            return <div className="text-center p-3">FPE wird geladen ...</div>;
        }

        return (
            <div className="dealtool-edit-fpe">
                <div className="modal-title">
                    <button type="button" className="btn btn-secondary float-right" onClick={this.props.close}>
                        Schließen
                    </button>
                    <h1>FPE bearbeiten</h1>
                </div>

                <form onSubmit={this.save} className={this.props.offline ? "content" : "content p-0"}>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label>Bezeichnung</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    onChange={this.onChange}
                                    value={fpe.name}
                                />
                            </div>
                            <div className="form-group">
                                <label>Beschreibung</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    rows={5}
                                    name="description"
                                    onChange={this.onChange}
                                    value={fpe.description ? fpe.description : ""}
                                ></textarea>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Gewerk</label>
                                <select
                                    className="form-control"
                                    name="group_id"
                                    onChange={this.onChange}
                                    value={fpe.group_id ? fpe.group_id : 0}
                                >
                                    <option value={0}>Kein Gewerk</option>
                                    {this.props.data_groups && this.props.data_groups.length > 0
                                        ? this.props.data_groups.map(group => (
                                              <option key={"fpe_grp_" + group.id} value={group.id}>
                                                  {group.name}
                                              </option>
                                          ))
                                        : null}
                                </select>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Menge</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="quantity"
                                            onChange={this.onChange}
                                            value={fpe.quantity}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Einheit</label>
                                        <select
                                            className="form-control"
                                            name="unit"
                                            onChange={this.onChange}
                                            value={fpe.unit}
                                        >
                                            <option value="st">Stück</option>
                                            <option value="psch">Pauschal</option>
                                            <option value="m">m</option>
                                            <option value="qm">m²</option>
                                            <option value="h">h</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Eventualposition</label>
                                <select
                                    className="form-control"
                                    name="contingency"
                                    onChange={this.onChange}
                                    value={fpe.contingency}
                                >
                                    <option value={true}>Ja</option>
                                    <option value={false}>Nein</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Einzelpreis Kunde (netto)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="price_customer"
                                    onChange={this.onChange}
                                    value={fpe.price_customer !== null ? fpe.price_customer : ""}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Einzelpreis Nachunternehmer (netto)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="price_subcontractor"
                                    onChange={this.onChange}
                                    value={fpe.price_subcontractor !== null ? fpe.price_subcontractor : ""}
                                />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-group">
                                <label>Nachunternehmer Gewichtung</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="weighting"
                                    onChange={this.onChange}
                                    value={fpe.weighting !== null ? fpe.weighting : ""}
                                />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-group">
                                <label>AG Faktor (Kunde)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="price_multiplier"
                                    onChange={this.onChange}
                                    value={fpe.price_multiplier !== null ? fpe.price_multiplier : ""}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 text-right mb-3">
                            <div className="form-group">
                                {this.state.isNew ? null : (
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-bordered mr-1"
                                        onClick={this.remove}
                                    >
                                        Löschen
                                    </button>
                                )}
                                <input type="submit" value="FPE Speichern" className="btn btn-success" />
                            </div>
                        </div>
                    </div>
                </form>

                {this.props.templates && this.props.templates.length > 0 ? (
                    <div className="card">
                        <div className="card-header">FPE-Vorlagen</div>
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right">Optionen</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.templates.map(template => (
                                    <tr key={"fpe_template_" + template.id}>
                                        <td>{template.name}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-xs"
                                                data-id={template.id}
                                                onClick={this.applyTemplate}
                                            >
                                                Übernehmen
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    edit_fpe: state.dealtool.edit_fpe,
    freepositions: state.dealtool.free_positions,
    data_groups: state.dealtool.data_groups,
    templates: state.dealtool.data_fpe_templates
});

const mapDispatch = dispatch => ({
    close: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "edit_fpe", value: null } }),
    setValue: (e, value) =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: {
                name: value !== undefined ? e : e.target.name,
                value: value !== undefined ? value : e.target.value
            }
        })
});
export default connect(mapStateToProps, mapDispatch)(EditFreeposition);
