import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealHelper from "../DealHelper";

const DealFpeElement = props => {
    const { fpe, count } = props;
    if (!fpe || !count) {
        return null;
    }

    let group = null;
    if (fpe.group_id && parseInt(fpe.group_id) > 0 && props.data_groups && props.data_groups.length > 0) {
        for (let i in props.data_groups) {
            if (props.data_groups[i].id === parseInt(fpe.group_id)) {
                group = props.data_groups[i];
            }
        }
    }

    const agFactor =
        fpe.price_multiplier !== undefined &&
        fpe.price_multiplier !== null &&
        fpe.price_multiplier.toString().trim() !== ""
            ? DealHelper.input2Float(fpe.price_multiplier)
            : 1;
    return (
        <tr className="position fpe" onClick={props.openEditor} data-count={count}>
            <td>FPE-{count}</td>
            <td>
                {group ? <b>{group.name}: </b> : ""}
                {fpe.name}

                {fpe.description && fpe.description.trim() !== "" ? (
                    <div style={{ fontSize: 10, whiteSpace: "pre-wrap" }}>{fpe.description}</div>
                ) : null}
            </td>
            <td className="text-right">{agFactor !== 1 ? <b>{DealHelper.number_format(agFactor)}</b> : "-"}</td>
            <td />
            <td>
                {group
                    ? group.prefix +
                      ": " +
                      (fpe.weighting !== null &&
                      DealHelper.input2Float(fpe.weighting) !== DealHelper.input2Float(fpe.quantity) ? (
                          <b>{DealHelper.number_format(DealHelper.input2Float(fpe.weighting))}</b>
                      ) : (
                          DealHelper.number_format(DealHelper.input2Float(fpe.quantity))
                      ))
                    : "-"}
            </td>
            <td className="text-right">
                {DealHelper.number_format(DealHelper.input2Float(fpe.quantity))}
                <div className="small">{fpe.unit}</div>
            </td>
            <td className="text-right">
                {DealHelper.number_format(DealHelper.input2Float(fpe.price_customer))}
                {props.show_nuprices && group ? (
                    <div style={{ fontSize: 10 }}>
                        {group.prefix +
                            ": " +
                            DealHelper.number_format(DealHelper.input2Float(fpe.price_subcontractor))}
                    </div>
                ) : null}
            </td>
            <td className="text-right">
                {fpe.contingency ? (
                    <i>Eventualposition</i>
                ) : agFactor !== 1 ? (
                    <React.Fragment>
                        <div style={{ textDecoration: "line-through", opacity: 0.8 }}>
                            <FontAwesomeIcon icon="exchange-alt" className="mr-1" />
                            {DealHelper.number_format(
                                DealHelper.input2Float(fpe.price_customer) * DealHelper.input2Float(fpe.quantity)
                            )}
                        </div>
                        {DealHelper.number_format(
                            DealHelper.input2Float(fpe.price_customer) * agFactor * DealHelper.input2Float(fpe.quantity)
                        )}
                    </React.Fragment>
                ) : (
                    DealHelper.number_format(
                        DealHelper.input2Float(fpe.price_customer) * DealHelper.input2Float(fpe.quantity)
                    )
                )}
                {props.show_nuprices && group ? (
                    <div style={{ fontSize: 10 }}>
                        {group.prefix}
                        {": "}
                        {fpe.weighting !== null &&
                        DealHelper.input2Float(fpe.weighting) !== DealHelper.input2Float(fpe.quantity) ? (
                            <b>
                                {DealHelper.number_format(
                                    DealHelper.input2Float(fpe.price_subcontractor * fpe.weighting)
                                )}
                            </b>
                        ) : (
                            DealHelper.number_format(DealHelper.input2Float(fpe.price_subcontractor * fpe.quantity))
                        )}
                    </div>
                ) : null}
            </td>
        </tr>
    );
};

const mapStateToProps = state => ({
    data_groups: state.dealtool.data_groups,
    show_nuprices: state.dealtool.show_nuprices
});
const mapDispatch = dispatch => ({
    openEditor: e => {
        return dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "edit_fpe", value: parseInt(e.currentTarget.getAttribute("data-count")) }
        });
    }
});
export default connect(mapStateToProps, mapDispatch)(DealFpeElement);
