import React, { Component } from "react";
import ApiHelper from "../services/ApiHelper";
import AuthHelper from "../services/AuthHelper";
import { Redirect } from "react-router-dom";

export default class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            isLoading: false,
            hasError: false,
            loginSuccess: false,
            errorMessage: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: true, hasError: false });

        ApiHelper.request("/login", {
            email: this.state.email,
            password: this.state.password
        })
            .then(json => {
                if (json.status !== undefined && json.status === "success") {
                    AuthHelper.setLogin(json.data);
                    this.setState({ loginSuccess: true });
                } else {
                    this.setState({ hasError: true, isLoading: false, password: "" });
                }
            })
            .catch(e => {
                console.error(e);
                this.setState({ hasError: true, isLoading: false, password: "", errorMessage: e });
            });
    }

    render() {
        if (AuthHelper.checkLogin() || this.state.loginSuccess) {
            const { from } = { from: { pathname: "/dashboard" } };
            return <Redirect to={from} />;
        }

        let error_msg;
        if (this.state.hasError) {
            error_msg = <div className="alert alert-danger">Fehler beim Login</div>;
        }

        let login_form;
        if (this.state.isLoading) {
            login_form = (
                <div className="login text-center" style={{ padding: "50px" }}>
                    Daten werden geladen ...
                </div>
            );
        } else {
            login_form = (
                <form onSubmit={this.handleSubmit.bind(this)} className="panel-body">
                    <div className="form-group">
                        <label>E-Mail:</label>
                        <input
                            type="text"
                            value={this.state.email}
                            name="email"
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            value={this.state.password}
                            name="password"
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </div>
                    <input type="submit" value="Einloggen" className="btn btn-primary btn-block" />
                </form>
            );
        }

        return (
            <div style={{ marginBottom: "15px" }}>
                <div
                    style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        backgroundColor: "#f3f3f4",
                        paddingBottom: "50px"
                    }}
                >
                    <div className="login" style={{ margin: "auto", maxWidth: "500px" }}>
                        <div className="text-center">
                            <img
                                src="/favicon.ico"
                                alt=""
                                style={{ margin: "auto", marginTop: "50px", height: "50px" }}
                            />
                            <h1 style={{ margin: "0px", paddingTop: "15px", paddingBottom: "30px" }}>Login</h1>
                        </div>

                        {error_msg}
                        <div className="panel panel-default">{login_form}</div>

                        {this.state.errorMessage ? this.state.errorMessage : null}
                    </div>
                </div>

                <div style={{ textAlign: "center", padding: "15px" }}>
                    Copyright &copy; by{" "}
                    <a href="https://webtypen.de/" target="_blank" rel="noopener noreferrer">
                        Benedict Romp
                    </a>
                </div>
            </div>
        );
    }
}
