import $ from "jquery";
import AuthHelper from "./AuthHelper";
import Config from "../Config";
import moment from "moment";

class ApiHelper {
    request(path, data, version) {
        if (!data) {
            data = {};
        }

        if (!version) {
            version = "v1";
        }

        data.app = "deals_app";
        data.version = Config.version;
        data.api_key = Config.api.key;

        if (AuthHelper.checkLogin()) {
            data.user_id = AuthHelper.user.user_id;
            data.env = AuthHelper.user.env;
            data.token = AuthHelper.user.token;
        }

        data.deviceInfo = {
            os: navigator.platform,
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            language: navigator.language,
            type: navigator.product,
            os_version: navigator.appVersion,
            manufacturer: navigator.vendor
        };

        // path = 'http://localhost/senft/public/api/android-v1' + path;
        path = Config.api.endpoint + version + path;
        return $.post(path, data);
    }

    needsSync() {
        let min_sync = moment(Config.api.min_sync);
        let last_sync = localStorage.getItem("last_sync");

        if (last_sync === undefined || !last_sync || moment(last_sync) <= min_sync) {
            return true;
        }
        return false;
    }
}

export default new ApiHelper();
