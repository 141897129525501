import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compile } from "path-to-regexp";
import DealHeader from "./DealHeader";
import DealPositions from "./DealPositions";
import DealHelper from "../DealHelper";
import Selector from "./Selector";
import Toolbar from "./Toolbar";
import EditTexts from "./EditTexts";

class Dealtool extends Component {
    state = {
        alert: null,
        alertMessage: ""
    };

    lastProjectId = null;

    componentDidMount() {
        this.load();
    }

    componentDidUpdate() {
        if (this.props.project_id && this.lastProjectId !== this.props.project_id) {
            this.afterProjectLoad(this.props.project_id);
        }
    }

    load = () => {
        this.lastProjectId = null;
        this.loadMainData(() => this.loadDealData(this.props.match.params.id));
    };

    loadMainData = callback => {
        this.props.setValue("loading_maindata", true);

        if (this.props.offline) {
            console.log("DEALTOOL IN OFFLINE-MODE!");
            this.props.setValue("data_groups", JSON.parse(localStorage.getItem("catalog_groups")));
            this.props.setValue("data_users", null); // Keine Nutzerauswahl in offline-app
            this.props.setValue("data_fpe_templates", null); // Keine FPE-Templates in offline-app

            const texts = JSON.parse(localStorage.getItem("predefined_texts"));
            let customerTexts = [];
            let subcontractorsTexts = [];
            for (let i in texts) {
                if (texts[i] && texts[i].type === "customer-text") {
                    customerTexts.push(texts[i]);
                }

                if (texts[i] && texts[i].type === "subcontractor-text") {
                    subcontractorsTexts.push(texts[i]);
                }
            }
            this.props.setValue("data_infotexts_customer", customerTexts);
            this.props.setValue("data_infotexts_subcontractor", subcontractorsTexts);
            this.props.setValue("loading_maindata", false);

            if (callback) {
                callback();
            }
        } else {
            this.props.apiHelper.request("deals/editor/main-data").then(response => {
                this.props.setValue("data_groups", response.groups);
                this.props.setValue("data_users", response.users);
                this.props.setValue(
                    "data_fpe_templates",
                    response.fpeTemplates && response.fpeTemplates.length > 0 ? response.fpeTemplates : null
                );
                this.props.setValue(
                    "data_infotexts_customer",
                    response.textpatterns && response.textpatterns.customerTexts
                        ? response.textpatterns.customerTexts
                        : null
                );
                this.props.setValue(
                    "data_infotexts_subcontractor",
                    response.textpatterns && response.textpatterns.subcontractorTexts
                        ? response.textpatterns.subcontractorTexts
                        : null
                );
                this.props.setValue("loading_maindata", false);

                if (callback) {
                    callback();
                }
            });
        }
    };

    loadDealData = deal_id => {
        if (deal_id === "new") {
            if (!this.props.offline) {
                window.setTitle("Neues Angebot erstellen");
            }
            this.props.reset();
            return;
        }

        if (!this.props.offline) {
            window.setTitle("Angebot lädt");
        }

        this.props.setValue("loading_deal_data", true);
        if (this.props.offline) {
            setTimeout(() => {
                this.props.setValue("id", deal_id);
                this.props.load(deal_id, () => {
                    this.props.setValue("loading_deal_data", false);

                    if (this.props.loadFavorites) {
                        this.props.loadFavorites();
                    }
                });
            }, 500);
        } else {
            this.props.apiHelper
                .request("deals/editor/deal-data", {
                    id: deal_id
                })
                .then(data => {
                    if (data.is_finished) {
                        this.props.history.push("/deals/finished/" + data.finished_deal.id + "/details");
                    } else {
                        window.setTitle(data.deal_key + " - Angebote - ");
                        this.props.setValue("id", data.id);
                        this.props.setValue("project_id", data.project_id ? data.project_id : 0);
                        if (data.project_id) {
                            this.lastProjectId = data.project_id;
                        }

                        this.props.setValue(
                            "customer_contact_id",
                            data.customer_contact_id ? data.customer_contact_id : 0
                        );
                        this.props.setValue("tax_percent", data.tax_percent ? data.tax_percent : "");
                        this.props.setValue("deal_type", data.deal_type ? data.deal_type : "");
                        this.props.setValue("deal_key", data.deal_key ? data.deal_key : "");
                        this.props.setValue("user_id", data.user_id ? data.user_id : 0);
                        this.props.setValue("date", data.date ? data.date : "");
                        this.props.setValue("date_inspection", data.date_inspection ? data.date_inspection : "");
                        this.props.setValue(
                            "customers_info_text",
                            data.customers_info_text && data.customers_info_text.trim() ? data.customers_info_text : ""
                        );
                        this.props.setValue(
                            "subcontractors_info_text",
                            data.subcontractors_info_text && data.subcontractors_info_text.trim()
                                ? data.subcontractors_info_text
                                : ""
                        );

                        let groupTexts = {};
                        if (data.group_texts && data.group_texts.length > 0) {
                            for (let i in data.group_texts) {
                                if (data.group_texts[i].text && data.group_texts[i].text.trim() !== "") {
                                    groupTexts[parseInt(data.group_texts[i].group_id)] = DealHelper.createGroupText(
                                        data.group_texts[i].group_id,
                                        data.group_texts[i].text
                                    );
                                }
                            }
                        }
                        this.props.setValue("group_texts", groupTexts);

                        let positions = [];
                        if (data.positions && data.positions.length > 0) {
                            for (let i in data.positions) {
                                const pos = DealHelper.createPosition(
                                    data.positions[i].catalog_item_id,
                                    data.positions[i]
                                );
                                if (pos) {
                                    positions.push(pos);
                                }
                            }
                        }
                        this.props.setValue("positions", positions);

                        let free_positions = [];
                        if (data.free_positions && data.free_positions.length > 0) {
                            for (let i in data.free_positions) {
                                const fpe = DealHelper.createFpe(
                                    data.free_positions[i].identifier,
                                    data.free_positions[i].name,
                                    data.free_positions[i]
                                );
                                if (fpe) {
                                    free_positions.push(fpe);
                                }
                            }
                        }
                        this.props.setValue("free_positions", free_positions);
                        this.props.setValue("loading_deal_data", false);
                    }
                });
        }
    };

    afterProjectLoad = projectId => {
        this.lastProjectId = projectId;
        this.props.setValue("loading_customer_catalog", true);

        this.props.apiHelper
            .request("/deals/editor/customer-catalog-by-project", {
                project: projectId
            })
            .then(response => {
                this.props.setValue(
                    "data_customer",
                    response.data && response.data.customer ? response.data.customer : null
                );
                this.props.setValue(
                    "data_customer_contacts",
                    response.data && response.data.customer_contacts ? response.data.customer_contacts : null
                );
                this.props.setValue(
                    "data_catalog",
                    response.data && response.data.catalog ? response.data.catalog : null
                );
                this.props.setValue(
                    "data_price_type",
                    DealHelper.getPriceType(
                        response.data ? response.data.catalog : null,
                        response.data.project ? response.data.project : null
                    )
                );
                this.props.setValue("loading_customer_catalog", false);

                if (this.props.loadFavorites) {
                    this.props.loadFavorites();
                }
            });
    };

    openSelector = () => {
        this.props.setValue("show_selector", true);
    };

    save = () => {
        if (this.props.offline) {
            if (!this.props.offlineProject || !this.props.offlineProject.qm) {
                alert("Dem Bauprojekt muss eine Quadratmeter-Größe vor dem Speichern hinzugefügt werden.");
                return;
            }
        } else {
            if (!this.props.project_id || this.props.project_id < 1) {
                alert("Es muss ein Bauprojekt ausgewählt werden, bevor das Angebot gespeichert werden kann.");
                return;
            }
        }

        if (!this.props.data_customer || !this.props.data_customer.id || this.props.data_customer.id < 1) {
            alert(
                "Das ausgewählte Bauprojekt wurde mit keinem Kunden verbunden. Es können nur Angebote mit" +
                    "Kundenzuordnung über das Baurpojekt gespeichert werden!"
            );
            return;
        }

        this.props.setValue("is_saving", true);
        this.setState({
            alert: null,
            alertMessage: null
        });

        const { dealtool } = this.props.reduxStore.getState();
        let postData = {
            id: dealtool.id,
            customer_id: dealtool.data_customer.id,
            customer_contact_id: dealtool.customer_contact_id,
            date: dealtool.date,
            date_inspection: dealtool.date_inspection,
            deal_type: dealtool.deal_type,
            project_id: dealtool.project_id,
            user_id: dealtool.user_id,
            positions: dealtool.positions,
            freepositions: dealtool.free_positions,
            text_customer: dealtool.customers_info_text,
            text_subcontractors: dealtool.subcontractors_info_text,
            text_groups: dealtool.group_texts,
            tax_percent: dealtool.tax_percent
        };

        if (this.props.save) {
            this.props.save(postData, dealtool, id => {
                this.props.history.push(compile(this.props.match.path)({ id: id }));
                this.setState(
                    {
                        alert: "success",
                        alertMessage: "Das Angebot wurde erfolgreich gespeichert"
                    },
                    () => {
                        this.props.setValue("id", id);
                        this.props.setValue("is_saving", false);
                        this.load();
                    }
                );
            });
        } else {
            this.props.apiHelper
                .request("deals/editor/save/new", postData)
                .then(data => {
                    // this.props.history.push(pathToRegexp.compile(this.props.match.path)({ id: data.id }));
                    window.history.replaceState(null, null, window.urls.base + "/deals/" + data.id + "/editor");

                    setTimeout(() => {
                        this.setState(
                            {
                                alert: "success",
                                alertMessage: "Das Angebot wurde erfolgreich gespeichert"
                            },
                            () => {
                                this.props.setValue("id", data.id);
                                this.props.setValue("is_saving", false);
                                this.load();
                            }
                        );
                    }, 200);
                })
                .catch(e => {
                    console.error(e);

                    this.setState(
                        {
                            alert: "danger",
                            alertMessage: "Es ist ein Fehler beim Speichern des Angebots aufgetreten."
                        },
                        () => {
                            this.props.setValue("is_saving", false);
                        }
                    );
                });
        }
    };

    render() {
        return (
            <div className="dealtool">
                <Toolbar openSelector={this.openSelector} save={this.save}>
                    {this.props.toolbar}
                </Toolbar>

                <div className="deal-content-container">
                    {this.state.alert && this.state.alertMessage ? (
                        <div className={"alert alert-" + this.state.alert}>{this.state.alertMessage}</div>
                    ) : null}

                    {this.props.is_saving ? (
                        <div className="deal-content p-5 text-center">
                            <div style={{ fontSize: 26 }}>
                                <FontAwesomeIcon icon="spinner" className="fa-spin" />
                            </div>
                            Das Angebot wird auf dem Server gespeichert. Einen Moment bitte ...
                        </div>
                    ) : (
                        <React.Fragment>
                            <DealHeader
                                offline={this.props.offline ? true : false}
                                projectSelector={this.props.projectSelector}
                                dealHeaderAddition={this.props.dealHeaderAddition}
                            />
                            <DealPositions offline={this.props.offline ? true : false} />
                            <EditTexts offline={this.props.offline ? true : false} />
                            <Selector
                                offline={this.props.offline ? true : false}
                                apiHelper={this.props.apiHelper}
                                loadFavorites={this.props.loadFavorites}
                            />
                        </React.Fragment>
                    )}

                    <div className="text-right">
                        <button
                            onClick={this.save}
                            className="btn btn-success"
                            disabled={
                                this.props.loading_maindata ||
                                this.props.loading_deal_data ||
                                this.props.loading_customer_catalog ||
                                this.props.is_saving
                            }
                        >
                            Speichern
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    is_saving: state.dealtool.is_saving,
    project_id: state.dealtool.project_id,
    data_customer: state.dealtool.data_customer,
    loading_maindata: state.dealtool.loading_maindata,
    loading_deal_data: state.dealtool.loading_deal_data,
    loading_customer_catalog: state.dealtool.loading_customer_catalog,
    offlineProject: state.dealtool.offline_project
});
const mapDispatch = dispatch => ({
    reset: () => dispatch({ type: "RESET_DEAL" }),
    setValue: (e, value) =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: {
                name: value !== undefined ? e : e.target.name,
                value: value !== undefined ? value : e.target.value
            }
        })
});
export default withRouter(connect(mapStateToProps, mapDispatch)(Dealtool));
