import DealHelper from "./DealHelper";

// import {
//     SET_NOTIFICATIONS_COUNT,
//     SET_NOTIFICATIONS_LOADING,
//     SET_NOTIFICATIONS,
//     SIMPLETABLE_RESET,
//     SIMPLETABLE_FILTER
// } from "../actions";

const initialStore = {
    // Loading-Indicators
    is_saving: false,
    loading_maindata: false,
    loading_deal_data: false,
    loading_customer_catalog: false,
    loading_favorites: false,

    // Temporary-Settings
    show_nuprices: true,
    show_infotexts: true,
    show_materials: true,
    show_pricedetails: false,
    show_fpes_sort: false,
    show_changed_prices: true,
    show_texts: null,
    show_group_text: null,
    show_item_description: null,
    show_only_favorites: false,

    // Data-Stores
    data_groups: null,
    data_users: null,
    data_customer: null,
    data_customer_contacts: null,
    data_catalog: null,
    data_catalog_favorites: null,
    data_price_type: null,
    data_infotexts_customer: null,
    data_infotexts_subcontractor: null,
    data_fpe_templates: null,

    // Position-Selector
    show_selector: false,
    selector_category: 0,
    selector_item: null,
    edit_fpe: null,

    // Deal-Infos
    id: "new",
    tax_percent: 19,
    project_id: 0,
    offline_project: {
        customer_id: 0,
        project_type: 1,
        qm: 0,
        location: "",
        deadline: "",
        street: "",
        housenumber: "",
        zip: "",
        city: ""
    },
    customer_contact_id: 0,
    deal_type: "",
    deal_key: "",
    user_id: 0,
    date: "",
    date_inspection: "",
    customers_info_text: "",
    subcontractors_info_text: "",
    group_texts: {},
    positions: [],
    free_positions: []
};

const dealtoolReducer = (state = initialStore, action) => {
    switch (action.type) {
        case "RESET_DEAL":
            return initialStore;
        case "SET_DEAL_VALUE":
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case "SET_DEAL_INFOTEXTS":
            return {
                ...state,
                show_texts: state.show_texts === action.payload.value ? null : action.payload.value
            };
        case "TOGGLE_TEMP_SETTING":
            return {
                ...state,
                [action.payload.name]: !state[action.payload.name]
            };
        case "SET_DEAL_GROUPTEXT":
            return {
                ...state,
                group_texts: {
                    ...state.group_texts,
                    [parseInt(action.payload.group_id)]: DealHelper.createGroupText(
                        action.payload.group_id,
                        action.payload.text
                    )
                }
            };
        case "REMOVE_POSITION":
            let removePositions = [...state.positions];
            for (let i in removePositions) {
                if (removePositions[i].id === action.payload) {
                    removePositions.splice(i, 1);
                }
            }

            return {
                ...state,
                positions: removePositions
            };
        case "OPEN_POSITION_EDITOR":
            let category = 0;
            if (state.data_catalog && state.data_catalog.categories && state.data_catalog.categories.length > 0) {
                for (let i in state.data_catalog.categories) {
                    if (state.data_catalog.categories[i].items && state.data_catalog.categories[i].items.length > 0) {
                        for (let y in state.data_catalog.categories[i].items) {
                            if (state.data_catalog.categories[i].items[y].id === action.payload.id) {
                                category = state.data_catalog.categories[i].id;
                                break;
                            }
                        }
                    }
                }
            }

            return {
                ...state,
                show_selector: true,
                selector_item: action.payload.id,
                selector_category: category
            };
        case "SET_QUICK_VALUE":
            let positions = [...state.positions];
            for (let i in positions) {
                if (positions[i].id === action.payload.position) {
                    let tempPosition = { ...positions[i] };
                    tempPosition[action.payload.field] = DealHelper.input2Float(action.payload.value);
                    positions[i] = DealHelper.positionPrepareForSave(tempPosition);
                    break;
                }
            }

            return {
                ...state,
                positions: positions
            };
        case "SET_QUICK_GROUP_VALUE":
            let newPositions = [...state.positions];
            for (let i in newPositions) {
                if (newPositions[i].id === action.payload.position) {
                    let tempPosition = { ...newPositions[i] };
                    if (!tempPosition.groups) {
                        tempPosition.groups = {};
                    }

                    if (!tempPosition.groups[action.payload.group_id]) {
                        tempPosition.groups[action.payload.group_id] = DealHelper.createGroupData(
                            action.payload.group_id
                        );
                    }

                    tempPosition.groups[action.payload.group_id][action.payload.field] =
                        !action.payload.value || action.payload.value.trim() === ""
                            ? null
                            : DealHelper.input2Float(action.payload.value);

                    // Andere Gewerke nullen bei Entweder-Oder-Positionen
                    if (action.payload.field === "weighting" && action.payload.price_property === "either_or") {
                        tempPosition.price_property_id = parseInt(action.payload.group_id);
                        for (let g in tempPosition.groups) {
                            if (parseInt(tempPosition.groups[g].id) !== parseInt(action.payload.group_id)) {
                                tempPosition.groups[g].weighting = null;
                            }
                        }
                    }
                    newPositions[i] = DealHelper.positionPrepareForSave(tempPosition);
                }
            }

            return {
                ...state,
                positions: newPositions
            };
        case "SET_OFFLINE_PROJECT_VALUE":
            return {
                ...state,
                offline_project: { ...state.offline_project, [action.payload.name]: action.payload.value }
            };
        default:
            return state;
    }
};
export default dealtoolReducer;
