import React, { Component } from "react";
import DealHelper from "../DealHelper";

export default class QuickValueEditor extends Component {
    state = {
        isEditing: false,
        value: ""
    };

    onClick = e => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.group) {
            let value = null;
            if (this.props.position.groups && this.props.position.groups[this.props.group]) {
                value = this.props.position.groups[this.props.group][this.props.field];
            }

            if (value === null) {
                value = this.props.defaultValue ? this.props.defaultValue : 1;
            }

            this.setState(
                {
                    isEditing: true,
                    value: DealHelper.number_format(value)
                },
                () => {
                    const input = document.getElementById("quick-value-editor-input");
                    input.focus();
                    input.select();
                }
            );
        } else {
            this.setState(
                {
                    isEditing: true,
                    value: DealHelper.number_format(this.props.position[this.props.field])
                },
                () => {
                    const input = document.getElementById("quick-value-editor-input");
                    input.focus();
                    input.select();
                }
            );
        }
    };

    hide = e => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isEditing: false,
            value: ""
        });
    };

    onChange = e => {
        this.setState({
            value: e.target.value
        });
    };

    stop = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    save = e => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.group) {
            this.props.onChange(
                this.state.value,
                this.props.field,
                this.props.position,
                this.props.priceProperty,
                this.props.group
            );
        } else {
            this.props.onChange(this.state.value, this.props.field, this.props.position, this.props.priceProperty);
        }

        this.setState({
            isEditing: false,
            value: ""
        });
    };

    render() {
        let edit;
        if (this.state.isEditing) {
            edit = (
                <React.Fragment>
                    <div className="dealtool-quick-value-bg" onClick={this.hide}></div>
                    <div className="dealtool-quick-value-editor" onClick={this.stop}>
                        <form onSubmit={this.save} onClick={this.stop}>
                            <div className="form-group mb-0 mt-0" onClick={this.stop}>
                                <div className="close-btn" onClick={this.hide}>
                                    Schließen
                                </div>
                                <label onClick={this.stop}>{this.props.label ? this.props.label : "Bearbeiten"}</label>
                                <div className="input-group mb-0" onClick={this.stop}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.value}
                                        onChange={this.onChange}
                                        id="quick-value-editor-input"
                                    />
                                    <div className="input-group-append" onClick={this.stop}>
                                        <input
                                            type="submit"
                                            className="btn btn-secondary btn-bordered"
                                            value="Speichern"
                                            onClick={this.save}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.block) {
            return (
                <div
                    className={"quick-value-editor" + (this.props.classAddition ? " " + this.props.classAddition : "")}
                >
                    <div className="quick-value-container" onClick={this.onClick}>
                        {this.props.children}
                    </div>
                    {edit}
                </div>
            );
        }

        return (
            <span className={"quick-value-editor" + (this.props.classAddition ? " " + this.props.classAddition : "")}>
                <span className="quick-value-container" onClick={this.onClick}>
                    {this.props.children}
                </span>
                {edit}
            </span>
        );
    }
}
