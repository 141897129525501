import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import AuthHelper from '../services/AuthHelper';

export default class LogoutComponent extends Component {

	componentDidMount() {
		AuthHelper.logout();
	}

	render() {
		const { from } = { from: { pathname: '/' } };
		return (
            <div><Redirect to={from}/></div>
        );
	}

}