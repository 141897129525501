import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealPositionsTable from "./DealPositionsTable";
import DealPrices from "./DealPrices";
import EditFreeposition from "./EditFreeposition";
import SortFpes from "./SortFpes";

const DealPositions = props => {
    if (
        (!props.project_id && !props.offline) ||
        (props.offline && !props.offlineProject) ||
        (props.offline && !props.offlineProject.qm)
    ) {
        return null;
    }

    if (props.loading_maindata || props.loading_deal_data || props.loading_customer_catalog) {
        return (
            <div className="deal-content deal-positions mb-3">
                <div className="p-1 text-center">
                    <div className="mb-1" style={{ fontSize: 24 }}>
                        <FontAwesomeIcon icon="spinner" className="fa-spin" />
                    </div>
                    Daten werden geladen ...
                </div>
            </div>
        );
    }

    if (!props.data_customer) {
        return (
            <div className="deal-content deal-positions mb-3">
                <div className="alert alert-warning">
                    Es konnte kein Kunde anhand des ausgewählten Bauprojekts geladen werden.
                </div>
            </div>
        );
    }

    return (
        <div className="deal-positions">
            <div className="deal-content deal-positions-toolbar">
                <div className="float-right">
                    <div
                        className="btn-group mr-2"
                        role="group"
                        aria-label="Toolbar-Positionen"
                        style={{ border: "2px solid rgba(0, 0, 0, 0.2)", borderRadius: "0.2rem" }}
                    >
                        <button
                            type="button"
                            className="btn btn-xs btn-light"
                            data-type="show_nuprices"
                            onClick={props.toggleTempSetting}
                        >
                            {props.show_nuprices ? (
                                <FontAwesomeIcon icon="check" className="mr-1" />
                            ) : (
                                <FontAwesomeIcon icon="times" className="mr-1" />
                            )}
                            NU-Preise
                        </button>
                        <button
                            type="button"
                            className="btn btn-xs btn-light"
                            data-type="show_infotexts"
                            onClick={props.toggleTempSetting}
                        >
                            {props.show_infotexts ? (
                                <FontAwesomeIcon icon="check" className="mr-1" />
                            ) : (
                                <FontAwesomeIcon icon="times" className="mr-1" />
                            )}
                            Info-Texte
                        </button>
                        <button
                            type="button"
                            className="btn btn-xs btn-light"
                            data-type="show_materials"
                            onClick={props.toggleTempSetting}
                        >
                            {props.show_materials ? (
                                <FontAwesomeIcon icon="check" className="mr-1" />
                            ) : (
                                <FontAwesomeIcon icon="times" className="mr-1" />
                            )}
                            Materialien
                        </button>
                        <button
                            type="button"
                            className="btn btn-xs btn-light"
                            data-type="show_changed_prices"
                            onClick={props.toggleTempSetting}
                        >
                            {props.show_changed_prices ? (
                                <FontAwesomeIcon icon="check" className="mr-1" />
                            ) : (
                                <FontAwesomeIcon icon="times" className="mr-1" />
                            )}
                            Preisänderungen
                        </button>
                    </div>
                </div>
                <span className="font-weight-bold">Positionen:</span>
            </div>

            <div className="deal-content no-padding">
                <DealPositionsTable />
                <DealPrices />
            </div>
            <div className="deal-content">
                <div className="btn-group float-right mb-1">
                    <button type="button" className="btn btn-primary" onClick={props.newFpe}>
                        FPE hinzufügen
                    </button>
                    <button type="button" className="btn btn-primary" onClick={props.openSelector}>
                        Positionen aktualisieren
                    </button>
                </div>

                <div className="btn-group infotext-types-btns">
                    <button
                        type="button"
                        className={props.show_texts === "customer" ? "btn btn-secondary" : "btn btn-light"}
                        data-type="customer"
                        onClick={props.changeInfotexts}
                    >
                        {props.show_texts === "customer" ? <FontAwesomeIcon icon="times" className="mr-1" /> : null}
                        Info-Text: Kunde
                    </button>
                    <button
                        type="button"
                        className={props.show_texts === "subcontractors" ? "btn btn-secondary" : "btn btn-light"}
                        data-type="subcontractors"
                        onClick={props.changeInfotexts}
                    >
                        {props.show_texts === "subcontractors" ? (
                            <FontAwesomeIcon icon="times" className="mr-1" />
                        ) : null}
                        Info-Text: Nachunternehmer
                    </button>
                    <button
                        type="button"
                        className={props.show_texts === "groups" ? "btn btn-secondary" : "btn btn-light"}
                        data-type="groups"
                        onClick={props.changeInfotexts}
                    >
                        {props.show_texts === "groups" ? <FontAwesomeIcon icon="times" className="mr-1" /> : null}
                        Info-Text: Gewerke
                    </button>
                </div>
            </div>

            <Modal
                isOpen={props.edit_fpe ? true : false}
                onAfterOpen={() => {}}
                // onRequestClose={props.closeFpeEditor}
                onRequestClose={() => {
                    /* Nichts machen */
                }}
                ariaHideApp={false}
                style={{
                    overlay: {
                        zIndex: 50000
                    },
                    content: {
                        padding: 0
                    }
                }}
            >
                <EditFreeposition offline={props.offline} />
            </Modal>

            <Modal
                isOpen={props.show_fpes_sort}
                onAfterOpen={() => {}}
                onRequestClose={props.hideFpeSort}
                ariaHideApp={false}
                style={{
                    overlay: {
                        zIndex: 50000
                    },
                    content: {
                        padding: 0
                    }
                }}
            >
                <SortFpes />
            </Modal>
        </div>
    );
};

const mapStateToProps = state => ({
    loading_maindata: state.dealtool.loading_maindata,
    loading_deal_data: state.dealtool.loading_deal_data,
    loading_customer_catalog: state.dealtool.loading_customer_catalog,
    project_id: state.dealtool.project_id,
    data_customer: state.dealtool.data_customer,
    show_nuprices: state.dealtool.show_nuprices,
    show_infotexts: state.dealtool.show_infotexts,
    show_materials: state.dealtool.show_materials,
    edit_fpe: state.dealtool.edit_fpe,
    show_fpes_sort: state.dealtool.show_fpes_sort,
    show_texts: state.dealtool.show_texts,
    show_changed_prices: state.dealtool.show_changed_prices,
    offlineProject: state.dealtool.offline_project
});

const mapDispatch = (dispatch, store) => ({
    toggleTempSetting: e =>
        dispatch({ type: "TOGGLE_TEMP_SETTING", payload: { name: e.currentTarget.getAttribute("data-type") } }),
    closeFpeEditor: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "edit_fpe", value: null } }),
    openSelector: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_selector", value: true } }),
    newFpe: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "edit_fpe", value: "new" } }),
    hideFpeSort: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_fpes_sort", value: false } }),
    changeInfotexts: e => {
        return dispatch({
            type: "SET_DEAL_INFOTEXTS",
            payload: {
                value: e.currentTarget.getAttribute("data-type")
            }
        });
    }
});
export default connect(mapStateToProps, mapDispatch)(DealPositions);
