import React, { Component } from "react";
import CatalogsHelper from "../../services/CatalogsHelper";
import EditPositionLightbox from "./EditPositionLightbox";
import $ from "jquery";

export default class EditPositionsList extends Component {
    state = {
        editPositionLightbox: false,
        editPositionItem: null,
        nuPrices: false
    };

    price_type = null;

    hideEditPositionLightbox() {
        this.setState({
            editPositionLightbox: false
        });
    }

    openPositionEditor(event) {
        this.setState({
            editPositionLightbox: true,
            editPositionItem: event.target.getAttribute("data-id")
        });
    }

    toggleNuPrices(event) {
        this.setState({
            nuPrices: event.target.checked
        });
    }

    toggleDescription(event) {
        const target = event.target;

        let descr = $(target)
            .parent()
            .find(".item-description");
        if (descr.hasClass("hidden")) {
            descr.removeClass("hidden");
        } else {
            descr.addClass("hidden");
        }
    }

    buildPosData() {
        this.setPriceType();

        let pos_data = [];
        if (this.props.catalog && this.props.catalog.items !== undefined && this.props.catalog.items.length > 0) {
            for (let i in this.props.catalog.items) {
                if (this.props.catalog.items[i] && this.props.catalog.items[i].id !== undefined) {
                    for (let p in this.props.positions) {
                        if (
                            this.props.positions[p].item_id !== undefined &&
                            this.props.positions[p].item_id === this.props.catalog.items[i].id
                        ) {
                            // Handle Position und hinzufügen
                            pos_data.push(this.handlePosData(this.props.catalog.items[i], this.props.positions[p]));
                        }
                    }
                }
            }
        }

        // Nach Katalog-Kategorien sortieren
        let out = [];
        for (let y in pos_data) {
            let status = true;
            for (let o in out) {
                if (out[o].id !== undefined && out[o].id === pos_data[y].category_id) {
                    status = false;
                    out[o].items.push(pos_data[y]);
                }
            }

            if (status) {
                out.push({
                    id: pos_data[y].category_id,
                    sort: pos_data[y].category_pos,
                    items: [pos_data[y]]
                });
            }
        }

        if (out && out.length > 0) {
            out.sort((a, b) => {
                return a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0;
            });
        }

        return out;
    }

    setPriceType() {
        let price_type = null;
        if (
            this.props.catalog !== undefined &&
            this.props.catalog &&
            this.props.catalog.price_types !== undefined &&
            this.props.catalog.price_types.length > 0
        ) {
            for (let i in this.props.catalog.price_types) {
                let type = this.props.catalog.price_types[i];

                if (type && type.min_border !== undefined && type.max_border !== undefined) {
                    if (
                        parseFloat(this.props.qm, 10) >= parseFloat(type.min_border) &&
                        parseFloat(this.props.qm, 10) <= parseFloat(type.max_border, 10)
                    ) {
                        price_type = type;
                        break;
                    }
                }
            }
        }

        this.price_type = price_type;
    }

    handlePosData(item, position) {
        let weightings = this.getWeightingFromPosition(position);
        let nu_factors = this.getNuFactorsFormPosition(position);
        let prices = this.calcPrices(item, position);

        let description = item.description;
        while (description.indexOf("font-family") > 0) {
            description = description.replace("font-family", "ignore-fnt-fmly");
        }

        return {
            // Item-Data
            id: item.id,
            key: item.key,
            name: item.name,
            description: description,
            unit: item.unit,
            prices: item.prices,
            prices_groups: item.prices_groups,
            groups: item.groups !== undefined ? item.groups : null,
            nu_factors: nu_factors,
            weightings: weightings,
            category_id: item.category_id,
            category_pos: item.category_pos,
            sort: item.sort,

            // Position-Data
            data_contingency: position.contingency !== undefined ? position.contingency : false,
            data_multiplier: position.multiplier,
            data_quantity: position.quantity,
            data_weighting: weightings.map(element => (
                <div key={"weighting_" + item.id + "_" + element.group_id}>
                    {element.group.name}:
                    <span
                        style={{
                            marginLeft: "5px",
                            fontWeight: element.highlight ? "bold" : "normal"
                        }}
                    >
                        {parseFloat(element.weighting)
                            .toFixed(2)
                            .replace(".", ",")}
                    </span>
                </div>
            )),
            data_nu_factors: nu_factors.map(element => (
                <div key={"nu_factors" + item.id + "_" + element.group_id}>
                    {element.group.name}:
                    <span
                        style={{
                            marginLeft: "5px",
                            fontWeight: element.highlight ? "bold" : "normal"
                        }}
                    >
                        {parseFloat(element.nu_factors)
                            .toFixed(2)
                            .replace(".", ",")}
                    </span>
                </div>
            )),

            // Price
            price: prices.single,
            price_sum: prices.sum
        };
    }

    getWeightingFromPosition(position) {
        let weightings = [];
        if (position.groups !== undefined) {
            for (let g in position.groups) {
                let group = CatalogsHelper.getGroup(position.groups[g].group_id);
                if (group) {
                    if (
                        position.groups[g].weighting !== undefined &&
                        position.groups[g].weighting !== position.quantity
                    ) {
                        weightings.push({
                            highlight: true,
                            group_id: position.groups[g].group_id,
                            weighting:
                                position.groups[g].weighting === null
                                    ? position.quantity
                                    : position.groups[g].weighting,
                            group: group
                        });
                    } else {
                        weightings.push({
                            highlight: false,
                            group_id: position.groups[g].group_id,
                            weighting: position.quantity,
                            group: group
                        });
                    }
                }
            }
        }
        return weightings;
    }

    getNuFactorsFormPosition(position) {
        let nu_factors = [];
        if (position.groups !== undefined) {
            for (let g in position.groups) {
                let group = CatalogsHelper.getGroup(position.groups[g].group_id);
                if (group) {
                    if (
                        position.groups[g].nu_factors !== undefined &&
                        position.groups[g].nu_factors !== 1 &&
                        position.groups[g].nu_factors !== null
                    ) {
                        nu_factors.push({
                            highlight: true,
                            group_id: position.groups[g].group_id,
                            nu_factors: position.groups[g].nu_factors,
                            group: group
                        });
                    } else {
                        nu_factors.push({
                            highlight: false,
                            group_id: position.groups[g].group_id,
                            nu_factors: 1,
                            group: group
                        });
                    }
                }
            }
        }

        return nu_factors;
    }

    calcPrices(item, position) {
        let prices = { single: null, sum: null };

        if (this.price_type && this.price_type.id !== undefined && this.price_type.id > 0) {
            if (item.prices_groups !== undefined && item.prices_groups.length > 0) {
                let temp_value = 0;
                let temp_value_selling = 0;

                for (let pr in item.prices_groups) {
                    if (
                        item.prices_groups[pr].id !== undefined &&
                        item.prices_groups[pr].type_id === this.price_type.id
                    ) {
                        if (item.price_property === "either_or") {
                            if (
                                item.prices_groups[pr].group_id !== undefined &&
                                position.price_property !== undefined &&
                                item.prices_groups[pr].group_id === position.price_property
                            ) {
                                prices.single = {
                                    value: item.prices_groups[pr].value,
                                    value_selling: item.prices_groups[pr].value_customer
                                };
                            }
                        } else {
                            temp_value = temp_value + item.prices_groups[pr].value;
                            temp_value_selling = temp_value_selling + item.prices_groups[pr].value_customer;
                        }
                    }
                }

                if (item.price_property !== "either_or") {
                    prices.single = {
                        value: temp_value,
                        value_selling: temp_value_selling
                    };
                }
            }
        }

        if (prices.single !== null && prices.single.value_selling !== undefined) {
            if (position && position.item_id !== undefined && position.item_id > 0 && position.quantity !== undefined) {
                prices.sum = {
                    value: parseFloat(prices.single.value) * parseFloat(position.quantity),
                    value_selling: parseFloat(prices.single.value_selling) * parseFloat(position.quantity)
                };
            }
        }

        return prices;
    }

    savePosition(data) {
        this.props.save(data);

        this.setState({
            editPositionLightbox: false
        });
    }

    removePosition(item_id) {
        this.props.remove(item_id);

        this.setState({
            editPositionLightbox: false
        });
    }

    getNuPrice(type, position) {
        let data = [];
        if (position.groups !== undefined && position.groups.length > 0) {
            for (let g in position.groups) {
                let group = CatalogsHelper.getGroup(position.groups[g].id);
                let highlight = false;

                if (group) {
                    let nu_price = 0;
                    for (let i in position.prices_groups) {
                        if (
                            position.prices_groups[i].group_id !== undefined &&
                            parseInt(position.prices_groups[i].group_id, 10) === parseInt(group.id, 10)
                        ) {
                            if (parseInt(position.prices_groups[i].type_id, 10) === parseInt(this.price_type.id, 10)) {
                                nu_price = position.prices_groups[i].value;
                            }
                        }
                    }

                    if (position.nu_factors !== undefined && position.nu_factors.length > 0) {
                        for (let p in position.nu_factors) {
                            if (
                                position.nu_factors[p].group_id !== undefined &&
                                parseInt(position.nu_factors[p].group_id, 10) === parseInt(group.id, 10)
                            ) {
                                nu_price = parseFloat(nu_price, 10) * parseFloat(position.nu_factors[p].nu_factors, 10);

                                if (parseFloat(position.nu_factors[p].nu_factors) !== 1.0) {
                                    highlight = true;
                                }
                            }
                        }
                    }

                    data.push({
                        group_id: group.id,
                        group: group.prefix,
                        price: nu_price,
                        highlight: highlight
                    });
                }
            }
        }

        if (type === "sum") {
            if (data && data.length > 0) {
                for (let d in data) {
                    let qty = position.data_quantity;
                    if (position.weightings !== undefined && position.weightings.length > 0) {
                        for (let w in position.weightings) {
                            if (parseInt(data[d].group_id, 10) === parseInt(position.weightings[w].group_id, 10)) {
                                qty = position.weightings[w].weighting;

                                if (position.weightings[w].highlight) {
                                    data[d].highlight = true;
                                }
                            }
                        }
                    }

                    data[d].price = parseFloat(data[d].price) * parseFloat(qty);
                }
            }
        }

        if (data && data.length > 0) {
            let prices = data.map(element => (
                <div key={"nu_price_" + element.group}>
                    <span style={{ marginRight: "3px" }}>{element.group}:</span>
                    <span
                        style={{
                            fontWeight: element.highlight ? "bold" : "normal",
                            opacity: element.highlight ? 1 : 0.7
                        }}
                    >
                        {parseFloat(element.price)
                            .toFixed(2)
                            .replace(".", ",")}
                    </span>
                </div>
            ));

            return <div style={{ fontSize: "11px" }}>{prices}</div>;
        }

        return <div />;
    }

    render() {
        let pos_data = this.buildPosData();
        let status = false;
        let nuPrices = this.state.nuPrices;

        let positions;
        let fpes_title;
        let fpes_list;
        let full_price;
        if (pos_data && pos_data.length > 0) {
            status = true;
            positions = pos_data.map(category => {
                let items_count = 0;

                return category.items.map(element => {
                    items_count++;

                    let categoryName;
                    if (items_count === 1) {
                        if (category.id === 0) {
                            categoryName = <span>Hauptpositionen</span>;
                        } else if (
                            this.props.catalog &&
                            this.props.catalog.categories &&
                            this.props.catalog.categories.length > 0
                        ) {
                            for (let c in this.props.catalog.categories) {
                                if (this.props.catalog.categories[c].id === category.id) {
                                    categoryName = <span>{this.props.catalog.categories[c].name}</span>;
                                }
                            }
                        }
                    }

                    return [
                        items_count === 1 ? (
                            <tr key={"category_heading_" + categoryName}>
                                <td colSpan="7" style={{ fontWeight: "bold" }}>
                                    {categoryName}
                                </td>
                            </tr>
                        ) : null,
                        <tr key={"position_element" + element.id}>
                            <td className="hidden-xs">{element.key}</td>
                            <td>
                                <button
                                    className="btn btn-default btn-xs"
                                    onClick={this.openPositionEditor.bind(this)}
                                    data-id={element.id}
                                >
                                    Bearbeiten
                                </button>
                                <span className="visible-xs">{element.key}</span>
                                <span onClick={this.toggleDescription.bind(this)}>{element.name}</span>

                                <div
                                    className="item-description hidden"
                                    style={{ fontSize: "12px" }}
                                    dangerouslySetInnerHTML={{
                                        __html: element.description
                                    }}
                                />
                            </td>
                            <td className="hidden-xs">{element.data_nu_factors}</td>
                            <td className="hidden-xs">{element.data_weighting}</td>
                            <td className="text-right">
                                {parseFloat(element.data_quantity)
                                    .toFixed(2)
                                    .toString()
                                    .replace(".", ",")}{" "}
                                {element.unit}
                            </td>
                            <td className="text-right">
                                {element.price && element.price.value_selling !== undefined
                                    ? parseFloat(element.price.value_selling)
                                          .toFixed(2)
                                          .toString()
                                          .replace(".", ",")
                                    : "-"}
                                {nuPrices ? this.getNuPrice("single", element) : ""}
                            </td>
                            <td
                                className="text-right"
                                style={{
                                    fontStyle:
                                        element.data_contingency !== undefined && element.data_contingency
                                            ? "italic"
                                            : "normal"
                                }}
                            >
                                {element.data_contingency !== undefined && element.data_contingency
                                    ? "Eventual"
                                    : element.price_sum && element.price_sum.value_selling !== undefined
                                    ? parseFloat(element.price_sum.value_selling)
                                          .toFixed(2)
                                          .toString()
                                          .replace(".", ",")
                                    : "-"}
                                {nuPrices ? this.getNuPrice("sum", element) : ""}
                            </td>
                        </tr>
                    ];
                });
            });
        }

        if (this.props.fpes !== undefined && this.props.fpes && this.props.fpes.length > 0) {
            status = true;
            let count = 1;
            fpes_list = this.props.fpes.map(element => (
                <tr key={"fpe_" + count}>
                    <td className="hidden-xs">FPE-{count++}</td>
                    <td>
                        <button
                            className="btn btn-default btn-xs"
                            onClick={this.props.openFpeEditor.bind(this)}
                            data-fpe={count - 2}
                        >
                            Bearbeiten
                        </button>
                        <span className="visible-xs">FPE-{count}</span>
                        <span style={{ opacity: 0.7, marginRight: "5px" }}>
                            {element.data_group ? CatalogsHelper.getGroup(element.data_group).name : "Kein Gewerk"}:
                        </span>
                        {element.data_name}
                    </td>
                    <td className="hidden-xs" />
                    <td className="hidden-xs" />
                    <td className="text-right">
                        {parseFloat(element.data_quantity)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")}{" "}
                        {element.data_unit}
                    </td>
                    <td className="text-right">
                        {parseFloat(element.data_price)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")}
                    </td>
                    <td
                        className="text-right"
                        style={{
                            fontStyle:
                                element.data_contingency !== undefined && element.data_contingency ? "italic" : "normal"
                        }}
                    >
                        {element.data_contingency !== undefined && element.data_contingency
                            ? "Eventual"
                            : parseFloat(parseFloat(element.data_price) * parseFloat(element.data_quantity))
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",")}
                    </td>
                </tr>
            ));

            fpes_title = (
                <tr key={"fpes_list_title"}>
                    <td colSpan="7" style={{ fontWeight: "bold" }}>
                        Freie-Positionen
                    </td>
                </tr>
            );
        }

        if (!status) {
            positions = (
                <tr>
                    <td colSpan="7" style={{ textAlign: "center", padding: "30px" }}>
                        Es wurden noch keine Positionen zum Angebot hinzugefügt
                    </td>
                </tr>
            );
        } else {
            // Preis berechnen
            let price = 0;

            if (pos_data && pos_data.length > 0) {
                for (let c in pos_data) {
                    if (pos_data[c].items !== undefined && pos_data[c].items) {
                        for (let i in pos_data[c].items) {
                            let item = pos_data[c].items[i];

                            if (
                                item.price_sum !== undefined &&
                                item.price_sum &&
                                item.price_sum.value_selling !== undefined
                            ) {
                                if (item.data_contingency === undefined || !item.data_contingency) {
                                    price = price + item.price_sum.value_selling;
                                }
                            }
                        }
                    }
                }
            }

            if (this.props.fpes !== undefined && this.props.fpes && this.props.fpes.length > 0) {
                for (let y in this.props.fpes) {
                    if (
                        this.props.fpes[y] &&
                        this.props.fpes[y].data_price !== undefined &&
                        this.props.fpes[y].data_quantity !== undefined
                    ) {
                        if (this.props.fpes[y].data_contingency === undefined || !this.props.fpes[y].data_contingency) {
                            price = price + this.props.fpes[y].data_price * this.props.fpes[y].data_quantity;
                        }
                    }
                }
            }

            full_price = (
                <div style={{ paddingTop: "15px", paddingBottom: "30px" }}>
                    <div
                        style={{
                            borderTop: "2px solid #000",
                            borderBottom: "3px solid #000",
                            position: "relative",
                            width: "100%",
                            maxWidth: "320px",
                            float: "right"
                        }}
                    >
                        <div style={{ padding: "3px 0px", position: "relative" }}>
                            <div style={{ position: "absolute", right: "0px" }}>
                                {parseFloat(price)
                                    .toFixed(2)
                                    .replace(".", ",")}{" "}
                                EUR
                            </div>
                            Netto
                        </div>
                        <div style={{ padding: "3px 0px", position: "relative" }}>
                            <div style={{ position: "absolute", right: "0px" }}>
                                {parseFloat(price * 0.19)
                                    .toFixed(2)
                                    .replace(".", ",")}{" "}
                                EUR
                            </div>
                            MwSt. 19%
                        </div>
                        <div
                            style={{
                                padding: "3px 0px",
                                position: "relative",
                                fontWeight: "bold"
                            }}
                        >
                            <div style={{ position: "absolute", right: "0px" }}>
                                {parseFloat(price + price * 0.19)
                                    .toFixed(2)
                                    .replace(".", ",")}{" "}
                                EUR
                            </div>
                            Gesamtbetrag
                        </div>
                    </div>
                    <div style={{ clear: "both" }} />
                </div>
            );
        }

        return (
            <div>
                <div>
                    <EditPositionLightbox
                        visible={this.state.editPositionLightbox}
                        positions={this.props.positions}
                        customer={this.props.data_customer}
                        catalog={this.props.catalog}
                        hide={this.hideEditPositionLightbox.bind(this)}
                        save={this.savePosition.bind(this)}
                        remove={this.removePosition.bind(this)}
                        activeItem={this.state.editPositionItem}
                    />
                </div>

                <div className="panel panel-default">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="hidden-xs">POS.</th>
                                <th>Bezeichnung</th>
                                <th className="hidden-xs">NU-Faktor</th>
                                <th className="hidden-xs">Gewichtung</th>
                                <th className="text-right">Menge</th>
                                <th className="text-right">EP (EUR)</th>
                                <th className="text-right">GP (EUR)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {positions}
                            {fpes_title}
                            {fpes_list}
                        </tbody>
                    </table>
                </div>
                <div className="text-right">
                    <label style={{ fontWeight: "normal" }}>
                        <input
                            type="checkbox"
                            checked={this.state.nuPrices}
                            onChange={this.toggleNuPrices.bind(this)}
                        />
                        Nachunternehmer Preise anzeigen
                    </label>
                </div>

                {full_price}
            </div>
        );
    }
}
