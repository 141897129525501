class DealHelper {
    /**
     * JS Äquivalent zur PHP-Funktion number_format()
     *
     * @param {*} number
     * @param {*} decimals
     * @param {*} dec_point
     * @param {*} thousands_sep
     */
    number_format(number, decimals, dec_point, thousands_sep) {
        if (decimals === undefined) {
            decimals = 2;
        }

        number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = typeof thousands_sep === "undefined" ? "." : thousands_sep,
            dec = typeof dec_point === "undefined" ? "," : dec_point,
            s = "",
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return "" + Math.round(n * k) / k;
            };

        s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || "").length < prec) {
            s[1] = s[1] || "";
            s[1] += new Array(prec - s[1].length + 1).join("0");
        }
        return s.join(dec);
    }

    uniqueId() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    /**
     * Texteingabe mit Komma als Dezimaltrenner in Float umwandeln
     *
     * @param {*} val
     */
    input2Float(val) {
        if (val === undefined || val === null) {
            return null;
        }

        if (typeof val == "string") {
            val = val
                .toString()
                .replace(".", "")
                .replace(".", "")
                .replace(".", "")
                .replace(",", ".");
        }
        return parseFloat(val);
    }

    /**
     * Gibt das Standardformat für Positionen zurück
     */
    createPosition = (item_id, data) => {
        const groupData = {};
        if (data && data.group_data && Object.keys(data.group_data).length > 0) {
            for (let g in data.group_data) {
                groupData[parseInt(data.group_data[g].group_id)] = this.createGroupData(
                    data.group_data[g].group_id,
                    data.group_data[g]
                );
            }
        }

        return {
            id: item_id,
            quantity: !data || data.quantity === null || data.quantity === undefined ? 1 : data.quantity,
            contingency: data && data.contingency ? true : false,
            price_multiplier:
                !data || data.price_multiplier === undefined || data.price_multiplier === null
                    ? 1
                    : parseFloat(data.price_multiplier),
            price_property_id: data && data.price_property_id ? data.price_property_id : null,
            groups: groupData
        };
    };

    /**
     * Gibt das Standardformat für Gewerksinfos in Positionsdatensätzen zurück
     */
    createGroupData = (group_id, data) => {
        let materials = null;
        if (data && data.materials) {
            materials = data.materials;

            if (typeof materials === "string") {
                materials = JSON.parse(materials);
            }
        }

        return {
            id: group_id,
            materials: materials,
            infotext: data && data.info_text && data.info_text.trim() !== "" ? data.info_text.trim() : null,
            weighting:
                data && data.weighting !== undefined && data.weighting !== null ? parseFloat(data.weighting) : null,
            nufactor:
                data && data.sub_factor !== undefined && data.sub_factor !== null ? parseFloat(data.sub_factor) : null
        };
    };

    /**
     * Gibt das Standardformat für FPE-Datensätze zurück
     */
    createFpe = (identifier, name, data) => {
        if (!identifier) {
            return null;
        }

        let price_subcontractor = 0;
        if (data) {
            if (data.price !== undefined && data.price !== null && data.price !== false) {
                price_subcontractor = data.price;
            } else if (
                data.price_subcontractor !== undefined &&
                data.price_subcontractor !== null &&
                data.price_subcontractor !== false
            ) {
                price_subcontractor = data.price_subcontractor;
            }
        }

        return {
            identifier: identifier,
            name: name && name.trim() !== "" ? name : "",
            group_id: data && data.group_id ? data.group_id : null,
            price_customer: data && data.price_customer ? data.price_customer : 0,
            price_subcontractor: price_subcontractor,
            price_multiplier:
                data && data.price_multiplier !== undefined && data.price_multiplier !== null
                    ? data.price_multiplier
                    : null,
            quantity: data && data.quantity !== undefined && data.quantity !== null ? data.quantity : 1,
            unit: data && data.unit ? data.unit : "psch",
            contingency: data && data.contingency ? true : false,
            weighting: data && data.weighting !== undefined && data.weighting !== null ? data.weighting : null,
            description: data && data.description ? data.description : ""
        };
    };

    /**
     * Lädt die Standardinformationen einer Position (Standardmaterialien und Infotexte automatisch setzen)
     */
    positionDefaults = item => {
        let groupData = {};
        if (item.groups && item.groups.length > 0) {
            for (let i in item.groups) {
                if (!item.groups[i] || !item.groups[i].id) {
                    continue;
                }

                // Standard-Materialien laden
                let materials = null;
                if (item.materials && item.materials.length > 0) {
                    for (let c in item.materials) {
                        if (!item.materials[c].materials || item.materials[c].materials.length < 1) {
                            continue;
                        }

                        for (let y in item.materials[c].materials) {
                            if (!item.materials[c].materials[y] || !item.materials[c].materials[y].default) {
                                continue;
                            }

                            if (item.materials[c].materials[y].group_id !== item.groups[i].id) {
                                continue;
                            }

                            if (materials === null) {
                                materials = [];
                            }

                            let matData = {
                                id: item.materials[c].materials[y].material_id,
                                checked: true
                            };

                            if (item.materials[c].materials[y].default_version) {
                                matData["version"] = item.materials[c].materials[y].default_version;
                            }
                            materials.push(matData);
                        }
                    }
                }

                let infotext =
                    item.groups[i].default_info_text && item.groups[i].default_info_text.trim() !== ""
                        ? item.groups[i].default_info_text
                        : "";

                if (item.groups[i].info_text_prefix && item.groups[i].info_text_prefix.trim() !== "") {
                    infotext = item.groups[i].info_text_prefix + "\n" + infotext;
                }

                if (item.groups[i].info_text_suffix && item.groups[i].info_text_suffix.trim() !== "") {
                    infotext =
                        (infotext && infotext.trim() !== "" ? infotext + "\n" : "") + item.groups[i].info_text_suffix;
                }

                groupData[item.groups[i].id] = {
                    group_id: item.groups[i].id,
                    info_text: infotext,
                    materials: materials && materials.length > 0 ? materials : null,
                    sub_factor: null,
                    weighting: null
                };
            }
        }

        return this.createPosition(item.id, {
            quantity: "1,00",
            contingency: false,
            group_data: groupData
        });
    };

    positionPrepareForEdit(position) {
        let newPos = { ...position };
        if (typeof newPos.quantity !== "string") {
            newPos.quantity = this.number_format(newPos.quantity);
        }
        if (typeof newPos.price_multiplier !== "string") {
            newPos.price_multiplier = this.number_format(newPos.price_multiplier, 8);
        }
        if (typeof newPos.price_property_id === "string") {
            newPos.price_property_id = parseInt(newPos.price_property_id);
        }
        if (typeof newPos.contingency === "string") {
            newPos.contingency = newPos.contingency === "true" ? true : false;
        }

        if (newPos.groups && Object.keys(newPos.groups).length > 0) {
            let newGroups = { ...newPos.groups };
            for (let i in newGroups) {
                if (newGroups[i].weighting !== null && typeof newGroups[i].weighting !== "string") {
                    newGroups[i].weighting = this.number_format(newGroups[i].weighting);
                }
                if (newGroups[i].nufactor !== null && typeof newGroups[i].nufactor !== "string") {
                    newGroups[i].nufactor = this.number_format(newGroups[i].nufactor);
                }
            }
            newPos.groups = newGroups;
        }
        return newPos;
    }

    positionPrepareForSave(position) {
        let newPos = { ...position };
        if (typeof newPos.quantity === "string") {
            newPos.quantity = newPos.quantity.trim() !== "" ? this.input2Float(newPos.quantity) : 0;
        }
        if (typeof newPos.price_multiplier === "string") {
            newPos.price_multiplier =
                newPos.price_multiplier.trim() !== "" ? this.input2Float(newPos.price_multiplier) : null;
        }
        if (typeof newPos.price_property_id === "string") {
            newPos.price_property_id =
                newPos.price_property_id.trim() !== "" ? parseInt(newPos.price_property_id) : null;
        }
        if (typeof newPos.contingency === "string") {
            newPos.contingency = newPos.contingency === "true" ? true : false;
        }

        if (newPos.groups && Object.keys(newPos.groups).length > 0) {
            let newGroups = { ...newPos.groups };
            for (let i in newGroups) {
                if (newGroups[i].weighting !== null && typeof newGroups[i].weighting === "string") {
                    newGroups[i].weighting =
                        newGroups[i].weighting.trim() !== "" ? this.input2Float(newGroups[i].weighting) : null;
                }
                if (newGroups[i].nufactor !== null && typeof newGroups[i].nufactor === "string") {
                    newGroups[i].nufactor =
                        newGroups[i].nufactor.trim() !== "" ? this.input2Float(newGroups[i].nufactor) : null;
                }
            }
            newPos.groups = newGroups;
        }
        return newPos;
    }

    fpePrepareForEdit(fpe) {
        let newFpe = { ...fpe };
        if (typeof fpe.quantity !== "string") {
            newFpe.quantity = this.number_format(newFpe.quantity);
        }
        if (typeof fpe.price_customer !== "string") {
            newFpe.price_customer = this.number_format(newFpe.price_customer);
        }
        if (typeof fpe.price_subcontractor !== "string") {
            newFpe.price_subcontractor = this.number_format(newFpe.price_subcontractor);
        }
        if (typeof fpe.price_subcontractor !== "string") {
            newFpe.weighting = newFpe.weighting !== null ? this.number_format(newFpe.weighting) : null;
        }
        if (typeof fpe.price_multiplier !== "string") {
            newFpe.price_multiplier =
                newFpe.price_multiplier !== null ? this.number_format(newFpe.price_multiplier) : null;
        }
        return newFpe;
    }

    fpePrepareForSave(fpe) {
        let newFpe = { ...fpe };
        if (typeof fpe.quantity === "string") {
            newFpe.quantity = newFpe.quantity.trim() !== "" ? this.input2Float(newFpe.quantity) : null;
        }
        if (typeof fpe.price_customer === "string") {
            newFpe.price_customer =
                newFpe.price_customer.trim() !== "" ? this.input2Float(newFpe.price_customer) : null;
        }
        if (typeof fpe.price_subcontractor === "string") {
            newFpe.price_subcontractor =
                newFpe.price_subcontractor.trim() !== "" ? this.input2Float(newFpe.price_subcontractor) : null;
        }

        if (typeof fpe.price_multiplier === "string") {
            newFpe.price_multiplier =
                newFpe.price_multiplier.trim() !== "" ? this.input2Float(newFpe.price_multiplier) : null;
        }

        if (typeof fpe.contingency === "string") {
            newFpe.contingency = newFpe.contingency === "true" ? true : false;
        }

        if (newFpe.price_customer === null) {
            newFpe.price_customer = 0;
        }

        if (newFpe.price_subcontractor === null) {
            newFpe.price_subcontractor = 0;
        }
        return newFpe;
    }

    createGroupText(group_id, text) {
        return {
            id: parseInt(group_id),
            text: text && text.trim() !== "" ? text : ""
        };
    }

    itemNumber(item) {
        return item.gaeb_numbers ? item.gaeb_numbers[0].number : "-";
    }

    getPriceType = (catalog, projectOrQm) => {
        if (catalog && catalog.priceTypes && catalog.priceTypes.length > 0 && projectOrQm) {
            if (typeof projectOrQm === "object") {
                projectOrQm = projectOrQm.qm;
            }

            for (let i in catalog.priceTypes) {
                if (projectOrQm >= catalog.priceTypes[i].min_qm && projectOrQm <= catalog.priceTypes[i].max_qm) {
                    return catalog.priceTypes[i];
                }
            }
        }
        return null;
    };
}

export default new DealHelper();
