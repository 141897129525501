import React from "react";
import { connect } from "react-redux";
import DealPositionsElement from "./DealPositionsElement";
import DealFpeElement from "./DealFpeElement";

const DealPositionsTable = props => {
    return (
        <table className="table table-deal-positions mb-0" style={{ borderTop: "none" }}>
            <thead>
                <tr>
                    <th>POS.</th>
                    <th>Bezeichnung</th>
                    <th style={{ width: 100 }} className="text-right">
                        AG-Faktor
                    </th>
                    <th style={{ width: 100 }}>NU-Faktor</th>
                    <th style={{ width: 100 }}>Gewichtung</th>
                    <th className="text-right" style={{ width: 100 }}>
                        Menge
                    </th>
                    <th className="text-right" style={{ width: 100 }}>
                        EP (EUR)
                    </th>
                    <th className="text-right" style={{ width: 100 }}>
                        GP (EUR)
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data_catalog && props.data_catalog.categories
                    ? props.data_catalog.categories.map(category => {
                          let positions = [];
                          if (
                              category.items &&
                              category.items.length > 0 &&
                              props.positions &&
                              props.positions.length > 0
                          ) {
                              for (let i in category.items) {
                                  for (let p in props.positions) {
                                      if (props.positions[p].id === category.items[i].id) {
                                          positions.push({
                                              item: category.items[i],
                                              position: props.positions[p]
                                          });
                                      }
                                  }
                              }
                          }

                          if (!positions || positions.length < 1) {
                              return null;
                          }
                          return (
                              <React.Fragment key={"category_" + category.id}>
                                  <tr>
                                      <td colSpan={8} className="font-weight-bold">
                                          {category.name}
                                      </td>
                                  </tr>

                                  {positions.map(pos => {
                                      return (
                                          <DealPositionsElement
                                              key={"pos_item_" + pos.item.id}
                                              position={pos.position}
                                              item={pos.item}
                                          />
                                      );
                                  })}
                              </React.Fragment>
                          );
                      })
                    : null}

                {props.free_positions && props.free_positions.length > 0 ? (
                    <React.Fragment>
                        <tr>
                            <td colSpan={8} className="font-weight-bold">
                                Freie-Positionen
                                <button
                                    type="button"
                                    className="ml-1 btn btn-xs btn-secondary btn-bordered"
                                    onClick={props.showFpesSort}
                                >
                                    Sortieren
                                </button>
                            </td>
                        </tr>

                        {props.free_positions.map((fpe, i) => {
                            return <DealFpeElement key={"fpe_" + fpe.identifier} count={i + 1} fpe={fpe} />;
                        })}
                    </React.Fragment>
                ) : null}
            </tbody>
        </table>
    );
};

const mapStateToProps = state => ({
    data_catalog: state.dealtool.data_catalog,
    positions: state.dealtool.positions,
    free_positions: state.dealtool.free_positions
});
const mapDispatch = dispatch => ({
    showFpesSort: () => dispatch({ type: "SET_DEAL_VALUE", payload: { name: "show_fpes_sort", value: true } })
});
export default connect(mapStateToProps, mapDispatch)(DealPositionsTable);
