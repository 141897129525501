import React, { Component } from "react";
import $ from "jquery";

export default class ScrollBar extends Component {
    componentDidMount() {
        setTimeout(() => {
            let scrollbar = $("#page-scroll-bar");
            let wrapper = $(".scroll-bar-wrapper");
            let sidebar = $("#main-sidebar");

            $(document).bind("scroll", function() {
                if (wrapper && wrapper.offset() && $(this).scrollTop() + 50 > wrapper.offset().top) {
                    let width = $(this).width();
                    if (sidebar.is(":visible")) {
                        width = width - sidebar.width();
                    }

                    scrollbar.addClass("scrolled").css("width", width + "px");
                } else {
                    scrollbar.removeClass("scrolled");
                }
            });
            $(document).scroll();
        }, 250);
    }

    componentWillUnmount() {
        $(document).unbind("scroll");
    }

    render() {
        return (
            <div className="scroll-bar-wrapper">
                <div className="scroll-bar" id="page-scroll-bar">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
