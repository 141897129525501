import React from "react";
import ResizableTextarea from "./ResizableTextarea";
import InfotextSelector from "./InfotextSelector";

const EditPositionGroups = props => {
    const { item, position } = props;
    if (!item || !item.groups || item.groups.length < 1) {
        return null;
    }

    return (
        <div className="mt-3">
            <h5>Gewerkes</h5>

            {item.groups.map(group => {
                if (item.price_property === "either_or" && position.price_property_id !== group.id) {
                    return (
                        <div key={"pos_grp_" + group.id} className="card mb-3">
                            <div className="card-header">
                                <input
                                    type="checkbox"
                                    data-group={group.id}
                                    onChange={props.onPricePropertyChange}
                                    checked={false}
                                    className="mr-1"
                                />
                                {group.prefix ? <span className="font-weight-bold mr-2">{group.prefix}</span> : ""}
                                {group.name}
                            </div>
                        </div>
                    );
                }

                let groupData = null;
                if (position && position.groups) {
                    for (let i in position.groups) {
                        if (position.groups[i].id === group.id) {
                            groupData = position.groups[i];
                        }
                    }
                }

                let infotexts = [];
                if (item.infotexts && item.infotexts.length > 0) {
                    for (let i in item.infotexts) {
                        if (item.infotexts[i].group_id === group.id) {
                            infotexts.push(item.infotexts[i]);
                        }
                    }
                }

                let materials = [];
                if (item.materials && item.materials.length > 0) {
                    for (let c in item.materials) {
                        if (!item.materials[c].materials || item.materials[c].materials.length < 1) {
                            continue;
                        }

                        let componentTemp = [];
                        for (let i in item.materials[c].materials) {
                            const material = item.materials[c].materials[i];
                            if (material.group_id !== group.id) {
                                continue;
                            }

                            let checked = false;
                            let checkedVersion = null;
                            let defaultVersion = null;
                            if (groupData && groupData.materials && groupData.materials.length > 0) {
                                for (let m in groupData.materials) {
                                    if (parseInt(groupData.materials[m].id) === material.material.id) {
                                        checked = groupData.materials[m].checked ? true : false;

                                        if (groupData.materials[m].version) {
                                            checkedVersion = groupData.materials[m].version;
                                        }
                                        break;
                                    }
                                }
                            }

                            let versions;
                            if (material.versions && material.versions.length > 0) {
                                defaultVersion = material.versions[0].id;

                                versions = (
                                    <select
                                        className="form-control"
                                        value={checkedVersion ? checkedVersion : defaultVersion}
                                        data-group={group.id}
                                        data-material={material.material.id}
                                        onChange={props.onMaterialVersionChange}
                                    >
                                        {material.versions.map(v => (
                                            <option
                                                key={"mat_version_" + v.id}
                                                value={v.id}
                                                data-group={group.id}
                                                data-material={material.material.id}
                                            >
                                                {v.title}
                                            </option>
                                        ))}
                                    </select>
                                );
                            }

                            componentTemp.push(
                                <tr key={"material_" + group.id + "_" + material.material.id}>
                                    <td style={{ width: 45, textAlign: "center" }}>
                                        <input
                                            type="checkbox"
                                            data-group={group.id}
                                            data-material={material.material.id}
                                            data-defaultversion={defaultVersion}
                                            checked={checked}
                                            onChange={props.onMaterialToggle}
                                        />
                                    </td>
                                    <td>{material.manufacturer ? material.manufacturer.name : "-"}</td>
                                    <td>
                                        {material.material.series ? material.material.series : "-"}
                                        {material.material.info ? (
                                            <div style={{ fontSize: 10 }}>
                                                <b>Info:</b> {material.material.info}
                                            </div>
                                        ) : null}
                                    </td>
                                    <td>{material.material.article_number ? material.material.article_number : "-"}</td>
                                    <td>{versions}</td>
                                </tr>
                            );
                        }

                        if (componentTemp && componentTemp.length > 0) {
                            materials.push(
                                <React.Fragment
                                    key={"material_comp_" + group.id + "_" + item.materials[c].component_id}
                                >
                                    <tr>
                                        <td className="component" colSpan={5}>
                                            {item.materials[c].component_name}
                                        </td>
                                    </tr>
                                    {componentTemp}
                                </React.Fragment>
                            );
                        }
                    }
                }

                return (
                    <div key={"pos_grp_" + group.id} className="card mb-3">
                        <div className="card-header">
                            {group.prefix ? <span className="font-weight-bold mr-2">{group.prefix}</span> : ""}
                            {group.name}
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <div className="float-right">
                                    <InfotextSelector
                                        texts={infotexts}
                                        onSelect={props.onInfoTextAppend}
                                        data={{ group_id: group.id }}
                                        lineBreak={true}
                                    />
                                </div>
                                <label>Infotext</label>
                                <ResizableTextarea
                                    className="form-control"
                                    data-group={group.id}
                                    name="infotext"
                                    value={
                                        groupData && groupData.infotext && groupData.infotext.trim() !== ""
                                            ? groupData.infotext
                                            : ""
                                    }
                                    onChange={props.onChange}
                                    id={"infotext_" + item.id + "_group_" + group.id}
                                />
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Gewichtung</label>
                                        <input
                                            type="text"
                                            name="weighting"
                                            value={groupData && groupData.weighting !== null ? groupData.weighting : ""}
                                            className="form-control"
                                            data-group={group.id}
                                            onChange={props.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>NU-Faktor</label>
                                        <input
                                            type="text"
                                            name="nufactor"
                                            value={groupData && groupData.nufactor !== null ? groupData.nufactor : ""}
                                            className="form-control"
                                            data-group={group.id}
                                            onChange={props.onChange}
                                        />
                                    </div>
                                </div>

                                {materials && materials.length > 0 ? (
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Materialien</label>
                                            <table className="table dealtool-materials-table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Hersteller</th>
                                                        <th>Serie</th>
                                                        <th>Artikelnummer</th>
                                                        <th>Ausführung</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{materials}</tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default EditPositionGroups;
