import { createStore, combineReducers, compose } from "redux";

import appReducer from "./reducers";
import dealtoolReducer from "../plugins/senft_dealtool/src/reducer";

const rootReducer = combineReducers({
    app: appReducer,
    dealtool: dealtoolReducer
});

let rootEnhancer = compose();
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    rootEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: "Senft App"
    })(rootEnhancer);
}

const initialStore = {};
const store = createStore(rootReducer, initialStore, rootEnhancer);
export default store;
