import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class InfotextSelector extends Component {
    state = {
        showModal: false,
        selected: []
    };

    showModal = () => {
        this.setState({
            showModal: true,
            selected: []
        });
    };

    hideModal = () => {
        this.setState({
            showModal: false,
            selected: []
        });
    };

    selectText = e => {
        const index = parseInt(e.currentTarget.getAttribute("data-i"));
        let selected = [...this.state.selected];
        let add = true;
        for (let i in selected) {
            if (selected[i] === index) {
                add = false;
                selected.splice(i, 1);
            }
        }

        if (add) {
            selected.push(index);
        }

        this.setState({
            selected: selected
        });
    };

    returnText = () => {
        let text = "";
        for (let i in this.state.selected) {
            const temp = this.props.texts[this.state.selected[i]].text;

            if (temp && temp.trim() !== "") {
                if (!text || text.trim() === "") {
                    text += temp;
                } else if (this.props.lineBreak) {
                    text += "\n" + temp;
                } else {
                    text += this.props.break ? this.props.break + temp : "<br />" + temp;
                }
            }
        }

        this.props.onSelect(text, this.props.data);
        this.setState({
            showModal: false,
            selected: []
        });
    };

    render() {
        let modal;
        if (this.state.showModal) {
            modal = (
                <div className="dealtool-infotext-selector">
                    <div className="dealtool-infotext-selector-bg" onClick={this.hideModal}></div>
                    <div className="dealtool-infotext-container">
                        <div className="dealtool-infotext-title">
                            <span className="dealtool-close" onClick={this.hideModal}>
                                <FontAwesomeIcon icon="times" onClick={this.hideModal} />
                            </span>
                            Vordefinierten Text auswählen:
                        </div>
                        <div className="dealtool-infotext-list">
                            {this.props.texts && this.props.texts.length > 0 ? (
                                this.props.texts.map((text, i) => {
                                    let isActive = false;
                                    for (let index in this.state.selected) {
                                        if (this.state.selected[index] === i) {
                                            isActive = true;
                                        }
                                    }

                                    return (
                                        <div
                                            className={"dealtool-infotext-selector-text" + (isActive ? " active" : "")}
                                            data-i={i}
                                            onClick={this.selectText}
                                            key={"textselector_" + i}
                                        >
                                            {text.name ? <b>{text.name}</b> : null}
                                            <div>{text.text}</div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="alert alert-warning">Es stehen keine Infotexte zur Verfügung.</div>
                            )}

                            <div className="text-right">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    disabled={this.state.selected.length < 1}
                                    onClick={this.returnText}
                                >
                                    Einfügen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                {modal}
                <button type="button" className="btn btn-secondary btn-xs float-right" onClick={this.showModal}>
                    Text einfügen
                </button>
            </React.Fragment>
        );
    }
}

export default InfotextSelector;
