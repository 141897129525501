class SettingsHelper {
    settings_data = null;

    loadData() {
        let data = localStorage.getItem("settings");

        if (data !== undefined && data !== null) {
            this.settings_data = JSON.parse(data);
        } else {
            this.settings_data = {};
        }
    }

    getSettings() {
        if (this.settings_data === null) {
            this.loadData();
        }

        return this.settings_data;
    }

    setSettings(settings) {
        this.settings_data = settings;
        localStorage.setItem("settings", JSON.stringify(settings));
    }

    getSetting(setting) {
        if (this.settings_data === null) {
            this.loadData();
        }

        if(this.settings_data[setting] !== undefined) {
            return this.settings_data[setting];
        }
        return null;
    }
}

export default new SettingsHelper();
