class TextsHelper {

	predefined_texts_data = null;

	loadData() {
		let data = localStorage.getItem('predefined_texts');

		if(data !== undefined && data !== null) {
			this.predefined_texts_data = JSON.parse(data);
		} else {
			this.predefined_texts_data = null;
		}
	}

	getTexts() {
		if(this.predefined_texts_data === null) {
			this.loadData();
		}

		return this.predefined_texts_data;
	}

}

export default new TextsHelper();