import React, { Component } from "react";
import EditPosition from "./EditPosition";

export default class EditPositionLightbox extends Component {
    render() {
        if (!this.props.visible) {
            return <span />;
        }

        return (
            <div className="dealEditPosition" style={{ overflowY: "scroll" }}>
                <div className="dealEditPositionContent">
                    <button
                        className="btn btn-default pull-right"
                        style={{ position: "relative", zIndex: 3020 }}
                        onClick={this.props.hide}
                    >
                        Schließen
                    </button>

                    <EditPosition
                        save={this.props.save}
                        remove={this.props.remove}
                        item={this.props.activeItem}
                        catalog={this.props.catalog}
                        customer={this.props.customer}
                        positions={this.props.positions}
                        isLightbox={true}
                    />
                </div>
            </div>
        );
    }
}
