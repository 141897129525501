import DBHelper from "./DBHelper";

class CatalogsHelper {
    catalogs_data = null;
    projecttypes_data = null;
    groups_data = null;
    units = {
        st: "Stück",
        psch: "Pauschal",
        m: "m",
        qm: "m²",
        h: "h"
    };

    async loadData() {
        // let data = localStorage.getItem('catalogs');
        let data = await DBHelper.get("sync", "type", "catalogs");

        if (data !== undefined && data !== null) {
            // this.catalogs_data = JSON.parse(data);
            this.catalogs_data = data.data;
        } else {
            this.catalogs_data = null;
        }
    }

    loadProjectTypesData() {
        let data = localStorage.getItem("project_types");

        if (data !== undefined && data !== null) {
            this.projecttypes_data = JSON.parse(data);
        } else {
            this.projecttypes_data = null;
        }
    }

    getCatalogs() {
        if (this.catalogs_data === null) {
            this.loadData();
        }

        return this.catalogs_data;
    }

    getProjectTypes() {
        if (this.projecttypes_data === null) {
            this.loadProjectTypesData();
        }

        return this.projecttypes_data;
    }

    async getCatalog(id) {
        if (this.catalogs_data === null) {
            await this.loadData();
        }

        if (this.catalogs_data === undefined || !this.catalogs_data || this.catalogs_data.length < 1) {
            return null;
        }

        for (let k in this.catalogs_data) {
            if (
                this.catalogs_data[k] &&
                this.catalogs_data[k].id !== undefined &&
                parseInt(this.catalogs_data[k].id, 10) === parseInt(id, 10)
            ) {
                return this.catalogs_data[k];
            }
        }
        return null;
    }

    getItem(catalog_id, item_id) {
        let catalog = this.getCatalog(catalog_id);
        if (!catalog || catalog.items === undefined || catalog.items.length < 1) {
            return null;
        }

        for (let i in catalog.items) {
            if (catalog.items[i].id !== undefined && parseInt(catalog.items[i].id, 10) === parseInt(item_id, 10)) {
                return catalog.items[i];
            }
        }
        return null;
    }

    loadGroupsData() {
        let data = localStorage.getItem("catalog_groups");

        if (data !== undefined && data !== null) {
            this.groups_data = JSON.parse(data);
        } else {
            this.groups_data = null;
        }
    }

    getGroups() {
        if (this.groups_data === null) {
            this.loadGroupsData();
        }

        return this.groups_data;
    }

    getGroup(id) {
        if (this.groups_data === null) {
            this.loadGroupsData();
        }

        if (this.groups_data === undefined || !this.groups_data || this.groups_data.length < 1) {
            return null;
        }

        for (let k in this.groups_data) {
            if (
                this.groups_data[k] &&
                this.groups_data[k].id !== undefined &&
                parseInt(this.groups_data[k].id, 10) === parseInt(id, 10)
            ) {
                return this.groups_data[k];
            }
        }
        return null;
    }
}

export default new CatalogsHelper();
