import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealHelper from "../DealHelper";
import PositionsHelper from "../PositionsHelper";
import QuickValueEditor from "./QuickValueEditor";

const DealPositionsElement = props => {
    const { item, position } = props;
    if (!item || !position) {
        return null;
    }

    let groupInfos;
    let groupInfosCount = 0;
    if (position.groups && props.data_groups && props.data_groups.length > 0) {
        groupInfos = Object.keys(position.groups).map(groupId => {
            const groupData = position.groups[groupId];
            if (!groupData) {
                return null;
            }

            let group = null;
            for (let g in props.data_groups) {
                if (props.data_groups[g].id === groupData.id) {
                    group = props.data_groups[g];
                    break;
                }
            }

            if (!group) {
                return null;
            }

            if (item.price_property === "either_or") {
                if (position.price_property_id !== group.id) {
                    return null;
                }
            }

            let materialsCount = 0;
            let materials;
            if (props.show_materials && groupData.materials && groupData.materials.length > 0) {
                materials = groupData.materials.map((mat, i) => {
                    if (!mat.checked || mat.checked === "false") {
                        return null;
                    }

                    let material;
                    for (let c in item.materials) {
                        for (let m in item.materials[c].materials) {
                            if (item.materials[c].materials[m].material_id === parseInt(mat.id)) {
                                material = item.materials[c].materials[m];
                                break;
                            }
                        }
                    }
                    if (!material) {
                        return null;
                    }

                    let version;
                    if (mat.version && material.versions && material.versions.length > 0) {
                        for (let i in material.versions) {
                            if (material.versions[i].id === parseInt(mat.version)) {
                                version = material.versions[i].title;
                                break;
                            }
                        }
                    }

                    materialsCount++;
                    return (
                        <div key={"material_" + item.id + "_" + group.id + "_" + i}>
                            - {material.component ? material.component.name + ": " : ""}
                            {material.manufacturer ? material.manufacturer.name : <i>Unbekannter Hersteller</i>}
                            {material.material.series ? " - " + material.material.series : null}
                            {version ? " - " + version : null}
                        </div>
                    );
                });
            }

            let infotext;
            if (props.show_infotexts && groupData.infotext && groupData.infotext.trim() !== "") {
                infotext = groupData.infotext.trim();
            }

            if (materialsCount < 1 && !infotext) {
                return null;
            }

            groupInfosCount++;
            return (
                <div className="group-info-col" key={"infotext_" + props.item.id + "_" + groupId}>
                    <div className="card mt-1">
                        <div
                            className="card-header"
                            style={{ padding: 5, fontSize: 10, lineHeight: "10px", height: "auto", minHeight: 10 }}
                        >
                            {group.name}
                        </div>
                        <div className="card-body" style={{ padding: 5, fontSize: 10, whiteSpace: "pre-line" }}>
                            {infotext ? (
                                <div className={materialsCount > 0 ? "mb-3" : ""}>
                                    <b>Infotext:</b>
                                    <br />
                                    {infotext}
                                </div>
                            ) : null}
                            {materialsCount > 0 ? (
                                <div>
                                    <b>Materialien:</b>
                                    <br />
                                    {materials}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        });
    }

    let customerPriceChange;
    let customerPriceChangeTotal;
    if (props.show_changed_prices && !position.contingency) {
        // Wenn Multiplikator gesetzt, normalen Wert anzeigen
        if (
            position.price_multiplier !== null &&
            position.price_multiplier !== false &&
            position.price_multiplier !== undefined &&
            position.price_multiplier !== 1
        ) {
            const originalSinglePrice = PositionsHelper.getSinglePrice(
                props.price_type_id,
                item,
                position,
                "value_customer",
                null, // No Group-ID
                true // Ignore Factor
            );

            customerPriceChange = (
                <div className="pos-price-change">
                    <FontAwesomeIcon icon="exchange-alt" className="mr-1" />
                    <span className="price-change-value">{DealHelper.number_format(originalSinglePrice)}</span>
                </div>
            );
            customerPriceChangeTotal = (
                <div className="pos-price-change">
                    <FontAwesomeIcon icon="exchange-alt" className="mr-1" />
                    <span className="price-change-value">
                        {DealHelper.number_format(originalSinglePrice * DealHelper.input2Float(position.quantity))}
                    </span>
                </div>
            );
        }
    }

    return (
        <tr className="position" onClick={() => props.open(item.id)}>
            <td>
                {DealHelper.itemNumber(item)}
                <div className="position-remove-container mt-1">
                    <button
                        type="button"
                        className="btn btn-danger btn-bordered btn-xs"
                        onClick={props.remove}
                        data-id={item.id}
                    >
                        Löschen
                    </button>
                </div>
            </td>
            <td>
                {item.name}

                {groupInfosCount > 0 ? (
                    <div
                        className={
                            "position-group-infos" + (groupInfosCount < 3 ? " less-then-three" : " three-or-more")
                        }
                    >
                        {groupInfos}
                    </div>
                ) : null}

                {item.description ? (
                    <div>
                        {props.show_item_description && item.id === props.show_item_description ? (
                            <React.Fragment>
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-bordered btn-xs mt-1"
                                    onClick={props.hideDescription}
                                >
                                    Langtext ausblenden
                                </button>
                                <div
                                    className="card p-1 mt-1 item-description"
                                    style={{ fontSize: 10 }}
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                />
                            </React.Fragment>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-secondary btn-bordered btn-xs mt-1"
                                onClick={props.showDescription}
                                data-id={item.id}
                            >
                                Langtext anzeigen
                            </button>
                        )}
                    </div>
                ) : null}
            </td>
            <td>
                <div className="float-right">
                    <QuickValueEditor
                        position={position}
                        field="price_multiplier"
                        label="Multiplikator bearbeiten"
                        onChange={props.quickValueChange}
                        classAddition="position-right"
                    >
                        {position.price_multiplier !== null &&
                        position.price_multiplier !== false &&
                        position.price_multiplier !== undefined &&
                        position.price_multiplier !== 1 ? (
                            <b>{DealHelper.number_format(position.price_multiplier)}</b>
                        ) : (
                            "1,00"
                        )}
                    </QuickValueEditor>
                </div>
            </td>
            <td>
                {item.groups && item.groups.length > 0
                    ? item.groups.map(group => {
                          const value = PositionsHelper.getNUFaktor(group.id, position);
                          return (
                              <QuickValueEditor
                                  block
                                  key={"group_subfactor_" + group.id + "_" + item.id}
                                  position={position}
                                  group={group.id}
                                  field="nufactor"
                                  label={(group.prefix ? group.prefix : group.name) + " - NU-Faktor bearbeiten"}
                                  onChange={props.quickGroupValueChange}
                                  classAddition="negativ-margin"
                              >
                                  <div className="deal-group-value">
                                      <span className="group">{group.prefix ? group.prefix : group.name}: </span>
                                      <span className={value !== 1 ? "value font-weight-bold" : "value"}>
                                          {DealHelper.number_format(value)}
                                      </span>
                                  </div>
                              </QuickValueEditor>
                          );
                      })
                    : "-"}
            </td>
            <td>
                {item.groups && item.groups.length > 0
                    ? item.groups.map(group => {
                          const value = PositionsHelper.getWeighting(group.id, position, item);
                          return (
                              <QuickValueEditor
                                  block
                                  key={"group_weighting_" + group.id + "_" + item.id}
                                  position={position}
                                  group={group.id}
                                  field="weighting"
                                  label={(group.prefix ? group.prefix : group.name) + " - Gewichtung bearbeiten"}
                                  onChange={props.quickGroupValueChange}
                                  classAddition="negativ-margin"
                                  defaultValue={position.quantity}
                                  priceProperty={item.price_property}
                              >
                                  <div className="deal-group-value">
                                      <span className="group">{group.prefix ? group.prefix : group.name}: </span>
                                      <span
                                          className={
                                              value !== DealHelper.input2Float(position.quantity)
                                                  ? "value font-weight-bold"
                                                  : "value"
                                          }
                                      >
                                          {DealHelper.number_format(value)}
                                      </span>
                                  </div>
                              </QuickValueEditor>
                          );
                      })
                    : "-"}
            </td>
            <td className="text-right">
                <QuickValueEditor
                    position={position}
                    field="quantity"
                    label="Menge bearbeiten"
                    onChange={props.quickValueChange}
                    classAddition="position-right"
                >
                    {DealHelper.number_format(DealHelper.input2Float(position.quantity))}
                </QuickValueEditor>
                <div className="small">{item.unit}</div>
            </td>
            <td className="text-right">
                {customerPriceChange}
                {DealHelper.number_format(PositionsHelper.getSinglePrice(props.price_type_id, item, position))}
                {props.show_nuprices
                    ? item.groups && item.groups.length > 0
                        ? item.groups.map(group => {
                              let elementChange;
                              const nuFactor = PositionsHelper.getNUFaktor(group.id, position);
                              if (props.show_changed_prices && nuFactor !== 1) {
                                  elementChange = (
                                      <div
                                          key={"group_single_price_change_" + group.id + "_" + item.id}
                                          className="deal-group-value changed-group-value"
                                          style={{ fontSize: 10 }}
                                      >
                                          <span className="group">{group.prefix ? group.prefix : group.name}: </span>
                                          <span className="value">
                                              <FontAwesomeIcon icon="exchange-alt" className="mr-1" />
                                              {DealHelper.number_format(
                                                  PositionsHelper.getSinglePrice(
                                                      props.price_type_id,
                                                      item,
                                                      position,
                                                      "value",
                                                      group.id,
                                                      true
                                                  )
                                              )}
                                          </span>
                                      </div>
                                  );
                              }

                              const element = (
                                  <div
                                      key={"group_single_price_" + group.id + "_" + item.id}
                                      className="deal-group-value"
                                      style={{ fontSize: 10 }}
                                  >
                                      <span className="group">{group.prefix ? group.prefix : group.name}: </span>
                                      <span className="value">
                                          {DealHelper.number_format(
                                              PositionsHelper.getSinglePrice(
                                                  props.price_type_id,
                                                  item,
                                                  position,
                                                  "value",
                                                  group.id
                                              )
                                          )}
                                      </span>
                                  </div>
                              );

                              if (elementChange) {
                                  return (
                                      <React.Fragment key={"group_single_price_group_" + group.id + "_" + item.id}>
                                          {elementChange}
                                          {element}
                                      </React.Fragment>
                                  );
                              }
                              return element;
                          })
                        : "-"
                    : null}
            </td>
            <td className="text-right">
                {customerPriceChangeTotal}
                {position.contingency ? (
                    <i>Eventualposition</i>
                ) : (
                    DealHelper.number_format(
                        PositionsHelper.getSinglePrice(props.price_type_id, item, position) *
                            DealHelper.input2Float(position.quantity)
                    )
                )}
                {props.show_nuprices
                    ? item.groups && item.groups.length > 0
                        ? item.groups.map(group => {
                              const weighting = PositionsHelper.getWeighting(group.id, position, item);
                              const nuFactor = PositionsHelper.getNUFaktor(group.id, position);
                              let elementChange;
                              if (props.show_changed_prices && nuFactor !== 1) {
                                  elementChange = (
                                      <div
                                          key={"group_full_price_changed_" + group.id + "_" + item.id}
                                          className="deal-group-value changed-group-value"
                                          style={{ fontSize: 10 }}
                                      >
                                          <span className="group">{group.prefix ? group.prefix : group.name}: </span>
                                          <span
                                              className={
                                                  weighting !== DealHelper.input2Float(position.quantity)
                                                      ? "value font-weight-bold"
                                                      : "value"
                                              }
                                          >
                                              {DealHelper.number_format(
                                                  PositionsHelper.getSinglePrice(
                                                      props.price_type_id,
                                                      item,
                                                      position,
                                                      "value",
                                                      group.id,
                                                      true
                                                  ) * weighting
                                              )}
                                          </span>
                                      </div>
                                  );
                              }

                              const element = (
                                  <div
                                      key={"group_full_price_" + group.id + "_" + item.id}
                                      className="deal-group-value"
                                      style={{ fontSize: 10 }}
                                  >
                                      <span className="group">{group.prefix ? group.prefix : group.name}: </span>
                                      <span
                                          className={
                                              weighting !== DealHelper.input2Float(position.quantity)
                                                  ? "value font-weight-bold"
                                                  : "value"
                                          }
                                      >
                                          {DealHelper.number_format(
                                              PositionsHelper.getSinglePrice(
                                                  props.price_type_id,
                                                  item,
                                                  position,
                                                  "value",
                                                  group.id
                                              ) * weighting
                                          )}
                                      </span>
                                  </div>
                              );

                              if (elementChange) {
                                  return (
                                      <React.Fragment
                                          key={"group_single_price_total_group_" + group.id + "_" + item.id}
                                      >
                                          {elementChange}
                                          {element}
                                      </React.Fragment>
                                  );
                              }
                              return element;
                          })
                        : "-"
                    : null}
            </td>
        </tr>
    );
};

const mapStateToProps = state => ({
    price_type_id: state.dealtool.data_price_type ? state.dealtool.data_price_type.id : null,
    data_groups: state.dealtool.data_groups,
    show_nuprices: state.dealtool.show_nuprices,
    show_infotexts: state.dealtool.show_infotexts,
    show_materials: state.dealtool.show_materials,
    show_item_description: state.dealtool.show_item_description,
    show_changed_prices: state.dealtool.show_changed_prices
});
const mapDispatch = dispatch => ({
    open: id => dispatch({ type: "OPEN_POSITION_EDITOR", payload: { id: id } }),
    showDescription: e => {
        e.preventDefault();
        e.stopPropagation();

        return dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "show_item_description", value: parseInt(e.target.getAttribute("data-id")) }
        });
    },
    hideDescription: e => {
        e.preventDefault();
        e.stopPropagation();

        return dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "show_item_description", value: null }
        });
    },
    quickValueChange: (value, field, position, price_property) =>
        dispatch({
            type: "SET_QUICK_VALUE",
            payload: { position: position.id, field: field, value: value, price_property: price_property }
        }),
    quickGroupValueChange: (value, field, position, price_property, group_id) =>
        dispatch({
            type: "SET_QUICK_GROUP_VALUE",
            payload: {
                position: position.id,
                field: field,
                value: value,
                group_id: group_id,
                price_property: price_property
            }
        }),
    remove: e => {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm("Soll die Position wirklich entfernt werden?")) {
            dispatch({
                type: "REMOVE_POSITION",
                payload: parseInt(e.currentTarget.getAttribute("data-id"))
            });
        }
    }
});
export default connect(mapStateToProps, mapDispatch)(DealPositionsElement);
