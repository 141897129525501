import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WYSIWYG } from "./WYSIWYG";

const EditTexts = props => {
    if (!props.show_texts) {
        return null;
    }

    if (props.show_texts === "groups") {
        if (!props.groups) {
            return null;
        }

        let selectedGroup = null;
        let selectedValue = null;
        if (props.show_group_text && props.groups) {
            for (let i in props.groups) {
                if (props.groups[i].id === props.show_group_text) {
                    selectedGroup = props.groups[i];
                    selectedValue =
                        props.group_texts &&
                        props.group_texts[selectedGroup.id] &&
                        props.group_texts[selectedGroup.id].text
                            ? props.group_texts[selectedGroup.id].text
                            : "";
                    break;
                }
            }
        }

        return (
            <div className="deal-content">
                <div className="row">
                    <div className="col-sm-2">
                        <ul className="nav flex-column group-info-text-nav">
                            {props.groups.map(group => {
                                let value =
                                    props.group_texts && props.group_texts[group.id] && props.group_texts[group.id].text
                                        ? props.group_texts[group.id].text
                                        : "";

                                return (
                                    <li className="nav-item" key={"group_text_link_" + group.id}>
                                        <button
                                            className={
                                                "nav-link" + (props.show_group_text === group.id ? " active" : "")
                                            }
                                            data-group={group.id}
                                            onClick={props.changeGroupText}
                                        >
                                            {value && value.trim() !== "" ? (
                                                <FontAwesomeIcon icon="text-width" className="float-right ml-1" />
                                            ) : null}
                                            {group.prefix ? group.prefix + " - " : ""}
                                            {group.name}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col-sm-10">
                        {props.show_group_text && selectedGroup ? (
                            <div className="form-group">
                                <label>Infotext: {selectedGroup.name}</label>
                                <WYSIWYG
                                    onChange={props.onGroupChange}
                                    value={selectedValue}
                                    data={{ group_id: selectedGroup.id }}
                                    insert={
                                        selectedGroup.infotexts && selectedGroup.infotexts.length > 0
                                            ? selectedGroup.infotexts
                                            : null
                                    }
                                />
                            </div>
                        ) : (
                            <div className="p-5 text-center" style={{ fontSize: 18 }}>
                                Ein Gewerk auswählen um Infotext zu bearbeiten
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="deal-content">
            <div className="form-group">
                <label>{props.show_texts === "subcontractors" ? "Nachunternehmer-Infotext" : "Kunden-Infotext"}</label>
                <WYSIWYG
                    onChange={props.onChange}
                    value={
                        props.show_texts === "subcontractors"
                            ? props.subcontractors_info_text
                            : props.customers_info_text
                    }
                    data={{
                        type: props.show_texts === "subcontractors" ? "subcontractors_info_text" : "customers_info_text"
                    }}
                    insert={
                        props.show_texts === "subcontractors"
                            ? props.data_infotexts_subcontractor
                            : props.data_infotexts_customer
                    }
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    show_texts: state.dealtool.show_texts,
    customers_info_text: state.dealtool.customers_info_text,
    subcontractors_info_text: state.dealtool.subcontractors_info_text,
    groups: state.dealtool.data_groups,
    group_texts: state.dealtool.group_texts,
    show_group_text: state.dealtool.show_group_text,
    data_infotexts_customer: state.dealtool.data_infotexts_customer,
    data_infotexts_subcontractor: state.dealtool.data_infotexts_subcontractor
});
const mapDispatch = dispatch => ({
    onChange: (text, data) => dispatch({ type: "SET_DEAL_VALUE", payload: { name: data.type, value: text } }),
    onGroupChange: (text, data) =>
        dispatch({ type: "SET_DEAL_GROUPTEXT", payload: { group_id: data.group_id, text: text } }),
    changeGroupText: e =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: { name: "show_group_text", value: parseInt(e.target.getAttribute("data-group")) }
        })
});
export default connect(mapStateToProps, mapDispatch)(EditTexts);
