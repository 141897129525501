class DBHelper {
    db = null;

    async boot() {
        if (!window.indexedDB) {
            alert("Offline-Datenbank kann nicht aufgebaut werden! (IndexedDB wird nicht unterstützt)");
            return false;
        }

        if (this.database()) {
            return true;
        }

        let request = window.indexedDB.open("SenftReactDB", 1);
        request.onsuccess = (event) => {
            this.db = event.target.result;
        };

        request.onupgradeneeded = (event) => {
            this.db = event.target.result;

            if (!this.db.objectStoreNames.contains("sync")) {
                let syncStore = this.database().createObjectStore("sync", { keyPath: "type", autoIncrement: true });
                syncStore.createIndex("type", "type", { unique: true });
                syncStore.createIndex("data", "data", { unique: false });
            }

            if (!this.db.objectStoreNames.contains("deals")) {
                let dealsStore = this.database().createObjectStore("deals", { keyPath: "id", autoIncrement: true });
                dealsStore.createIndex("id", "id", { unique: true });
                dealsStore.createIndex("data", "data", { unique: false });
            }
        };

        await this.waitForDb();
        return true;
    }

    async waitForDb() {
        return new Promise((resolve, reject) => {
            this.resolveWaitForDb(resolve);
        });
    }

    async resolveWaitForDb(resolve) {
        if (this.database()) {
            resolve();
        }

        let count = 0;
        while (!this.database()) {
            if (this.database()) {
                resolve();
            }

            await this.wait(50);
            count++;
            if (count > 10) {
                break;
            }
        }
        resolve();
    }

    database() {
        return this.db;
    }

    write(table, data) {
        if (!this.database()) {
            return null;
        }

        const transaction = this.database().transaction(table, "readwrite");
        const store = transaction.objectStore(table);
        data.forEach((el) => store.put(el));

        transaction.onerror = (ev) => {
            console.error("Ein Fehler ist aufgetreten!", ev.target.error.message);
        };

        transaction.oncomplete = (ev) => {
            const store = this.database().transaction(table, "readonly").objectStore(table);
            const query = store.openCursor();
            query.onerror = (ev) => {
                console.error("Anfrage fehlgeschlagen!", ev.target.error.message);
            };
            query.onsuccess = (ev) => {
                const cursor = ev.target.result;
                if (cursor) {
                    // Wenn weitere Einträge zum schreiben vorhanden sind, diese abarbeiten
                    cursor.continue();
                }
            };
        };
    }

    clearTable(table) {
        const transaction = this.database().transaction(table, "readwrite");
        let objectStore = transaction.objectStore(table);
        const objectStoreRequest = objectStore.clear();
        return objectStoreRequest;
    }

    async wait(ms) {
        return new Promise((res) => setTimeout(res, ms));
    }

    async getAsync(table, indexKey, indexValue) {
        if (!this.database()) {
            await this.waitForDb();
        }

        const transaction = this.database().transaction(table, "readwrite");
        const objectStore = transaction.objectStore(table);
        const index = objectStore.index(indexKey);

        return new Promise((resolve, reject) => {
            const result = index.get(indexValue);
            result.onsuccess = (event) => {
                resolve(event.target.result);
            };
            result.onerror = (error) => {
                console.error(error);
                reject(error);
            };
        });
    }

    async get(table, indexKey, indexValue) {
        let value = null;
        await this.getAsync(table, indexKey, indexValue).then((data) => {
            value = data;
        });

        return value;
    }

    async getAllAsync(table) {
        if (!this.database()) {
            await this.waitForDb();
        }

        let transaction = this.database().transaction(table, "readonly");
        let objectStore = transaction.objectStore(table);

        return new Promise((resolve, reject) => {
            if ("getAll" in objectStore) {
                // IDBObjectStore.getAll() will return the full set of items in our store.
                objectStore.getAll().onsuccess = function (event) {
                    resolve(event.target.result);
                };
            } else {
                // Fallback to the traditional cursor approach if getAll isn't supported.
                let values = [];
                objectStore.openCursor().onsuccess = function (event) {
                    var cursor = event.target.result;
                    if (cursor) {
                        values.push(cursor.value);
                        cursor.continue();
                    } else {
                        resolve(values);
                    }
                };
            }
        });
    }

    async getAll(table) {
        let value = null;
        await this.getAllAsync(table).then((data) => {
            value = data;
        });

        return value;
    }

    async delete(table, key) {
        if (!this.database()) {
            await this.waitForDb();
        }

        let transaction = this.database().transaction(table, "readwrite");
        let store = transaction.objectStore(table);
        store.delete(key);

        return transaction.complete;
    }
}

export default new DBHelper();
