import React from "react";
import { connect } from "react-redux";

const DealHeader = props => {
    const dealtype = (
        <React.Fragment>
            <input
                type="text"
                className="form-control"
                name="deal_type"
                list="deal_types_list"
                placeholder="Angebot"
                value={props.deal_type}
                onChange={props.onChange}
                disabled={props.loading_maindata}
            />
            <datalist id="deal_types_list">
                <option value="Angebot"></option>
                <option value="Angebot intern"></option>
                <option value="Abrechnung"></option>
            </datalist>
        </React.Fragment>
    );

    return (
        <div className="deal-content deal-header mb-3">
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Bauprojekt</label>
                        {props.projectSelector}
                    </div>
                    {props.offline ? null : (
                        <div className="form-group">
                            <label>Ansprechpartner</label>
                            <select
                                name="customer_contact_id"
                                value={props.customer_contact_id}
                                className="form-control"
                                onChange={props.onChange}
                                disabled={
                                    props.loading_maindata || props.loading_deal_data || props.loading_customer_catalog
                                }
                            >
                                <option value={0}>Kein Ansprechpartner</option>
                                {props.data_customer_contacts && props.data_customer_contacts.length > 0
                                    ? props.data_customer_contacts.map(person => (
                                          <option value={person.id} key={"contact_person_" + person.id}>
                                              {person.name}, {person.firstname}
                                          </option>
                                      ))
                                    : null}
                            </select>
                        </div>
                    )}

                    {props.offline ? null : (
                        <div className="form-group">
                            <label>Angebotstyp</label>
                            {dealtype}
                        </div>
                    )}
                </div>

                <div className="col-sm-6">
                    <table className="table table-borderless">
                        <tbody>
                            {props.offline ? (
                                <tr>
                                    <td>Angebotstyp</td>
                                    <td>{dealtype}</td>
                                </tr>
                            ) : null}
                            <tr>
                                <td>Angebotsnummer</td>
                                <td>
                                    {props.offline ? (
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={props.deal_key}
                                            disabled={true}
                                            placeholder="Wird beim hochladen generiert"
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={props.deal_key}
                                            disabled={true}
                                        />
                                    )}
                                </td>
                            </tr>
                            {props.offline ? null : (
                                <tr>
                                    <td>Bearbeiter</td>
                                    <td>
                                        <select
                                            className="form-control"
                                            name="user_id"
                                            value={props.user_id}
                                            onChange={props.onChange}
                                            disabled={props.loading_maindata}
                                        >
                                            <option value={0}>Kein Bearbeiter</option>
                                            {props.data_users && props.data_users.length > 0
                                                ? props.data_users.map(user => (
                                                      <option value={user.id} key={"deal_user_" + user.id}>
                                                          {user.name}
                                                      </option>
                                                  ))
                                                : null}
                                        </select>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>Angebotsdatum</td>
                                <td>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="date"
                                        value={props.date}
                                        onChange={props.onChange}
                                        disabled={props.loading_maindata}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Begehungsdatum</td>
                                <td>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="date_inspection"
                                        value={props.date_inspection}
                                        onChange={props.onChange}
                                        disabled={props.loading_maindata}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Mehrwertsteuer</td>
                                <td>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" style={{ padding: "0px 8px" }}>
                                                %
                                            </span>
                                        </div>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="tax_percent"
                                            value={props.tax_percent}
                                            onChange={props.onChange}
                                            disabled={props.loading_maindata}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {props.dealHeaderAddition ? props.dealHeaderAddition : null}
        </div>
    );
};

const mapStateToProps = state => ({
    loading_maindata: state.dealtool.loading_maindata,
    loading_customer_catalog: state.dealtool.loading_customer_catalog,
    data_users: state.dealtool.data_users,
    data_customer_contacts: state.dealtool.data_customer_contacts,
    project_id: state.dealtool.project_id,
    customer_contact_id: state.dealtool.customer_contact_id,
    deal_type: state.dealtool.deal_type,
    deal_key: state.dealtool.deal_key,
    user_id: state.dealtool.user_id,
    date: state.dealtool.date,
    date_inspection: state.dealtool.date_inspection,
    tax_percent: state.dealtool.tax_percent
});

const mapDispatch = dispatch => ({
    onChange: (e, value) =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: {
                name: value !== undefined ? e : e.target.name,
                value: value !== undefined ? value : e.target.value
            }
        })
});
export default connect(mapStateToProps, mapDispatch)(DealHeader);
