import React, { Component } from "react";
import ApiHelper from "services/ApiHelper";
import PageLoader from "components/PageLoader";
import { Link } from "react-router-dom";
import DealsHelper from "services/DealsHelper";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import DBHelper from "../services/DBHelper";
import DealsList from "./DealsList";

export default class DashbaordComponent extends Component {
    state = {
        isLoading: false,
        oldDeals: null,
        dealCopy: null,
        dealContextMenu: null,
    };

    componentDidMount() {
        this.loadDeals();
    }

    loadDeals() {
        this.setState({ oldDeals: DealsHelper.getDeals() });

        setTimeout(async () => {
            // console.log(await DBHelper.get("deals"));
        }, 50);
    }

    synchronize() {
        this.setState({ isLoading: true });

        ApiHelper.request("/sync", null, "v2")
            .then((json) => {
                this.saveLocalStorage(json);
                this.setState({ isLoading: false });
            })
            .catch((e) => {
                console.error(e);
                this.setState({ isLoading: false });
                alert("Es ist ein Fehler beim Synchronisieren aufgetreten.");
            });
    }

    saveLocalStorage(json) {
        if (json.data.catalogs !== undefined) {
            // localStorage.setItem("catalogs", JSON.stringify(json.data.catalogs));
            DBHelper.clearTable("sync");
            DBHelper.write("sync", [
                {
                    type: "catalogs",
                    data: json.data.catalogs,
                },
                {
                    type: "catalog_favorites",
                    data: json.data.catalog_favorites,
                },
            ]);
        }

        if (json.data.catalog_groups !== undefined) {
            localStorage.setItem("catalog_groups", JSON.stringify(json.data.catalog_groups));
        }

        if (json.data.customers !== undefined) {
            localStorage.setItem("customers", JSON.stringify(json.data.customers));
        }

        if (json.data.predefined_texts !== undefined) {
            localStorage.setItem("predefined_texts", JSON.stringify(json.data.predefined_texts));
        }

        if (json.data.project_types !== undefined) {
            localStorage.setItem("project_types", JSON.stringify(json.data.project_types));
        }

        localStorage.setItem("last_sync", moment().toISOString());
    }

    uploadDeal(deal) {
        confirmAlert({
            title: "Angebot hochladen",
            message: "Soll das Angebot hochgeladen und anschließend entfernt werden?",
            buttons: [
                {
                    label: "Abbrechen",
                    onClick: () => {},
                },
                {
                    label: "Hochladen",
                    onClick: () => {
                        this.setState({ isLoading: true });

                        DealsHelper.uploadDeal(
                            deal,
                            (data) => {
                                this.setState({ isLoading: false });
                                this.loadDeals();
                            },
                            (data) => {
                                let errorMessages;
                                if (data.errors && data.errors.length > 0) {
                                    let errorsCount = 0;
                                    errorMessages = data.errors.map((error) => (
                                        <div key={"alert_error_" + errorsCount++} style={{ color: "red" }}>
                                            {error}
                                        </div>
                                    ));
                                }

                                confirmAlert({
                                    title: "Fehler beim hochladen",
                                    message: null,
                                    childrenElement: () => <div>{errorMessages}</div>,
                                    confirmLabel: "Okay",
                                    cancelLabel: null,
                                    onConfirm: function () {
                                        // nichts machen
                                    },
                                });
                                this.setState({ isLoading: false });
                            }
                        );
                    },
                },
            ],
        });
    }

    removeDeal(deal) {
        confirmAlert({
            title: "Angebot entfernen",
            message: "Soll das Angebot wirklich entfernt werden?",
            buttons: [
                {
                    label: "Abbrechen",
                    onClick: () => {},
                },
                {
                    label: "Entfernen",
                    onClick: () => {
                        DealsHelper.removeDeal(deal, true);
                        this.setState({ deals: DealsHelper.getDeals() });
                    },
                },
            ],
        });
    }

    copyDeal(deal_id) {
        this.setState({
            dealCopy: deal_id,
        });
    }

    exportDeal(deal_id) {
        DealsHelper.exportDeal(deal_id);
    }

    hideCopyDeal() {
        this.setState({
            dealCopy: null,
        });
    }

    dealCopyAction() {
        this.loadDeals();
        this.hideCopyDeal();
    }

    render() {
        let pageLoader;
        let syncButton = (
            <button onClick={this.synchronize.bind(this)} className="btn btn-primary btn-block">
                Synchronisieren
            </button>
        );
        if (this.state.isLoading) {
            syncButton = (
                <button onClick={this.synchronize.bind(this)} className="btn btn-primary btn-block" disabled>
                    Synchronisieren
                </button>
            );
            pageLoader = <PageLoader />;
        }

        return (
            <div>
                <div className="content">
                    {pageLoader}
                    <h1>Willkommen im Dashboard</h1>

                    <div className="row">
                        <div className="col-sm-6">
                            <Link to={"/deals/new/editor"} className="btn btn-success btn-block">
                                Angebot erstellen
                            </Link>
                        </div>
                        <div className="col-sm-6">{syncButton}</div>
                    </div>
                </div>

                <DealsList />
            </div>
        );
    }
}
