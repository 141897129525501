import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InfotextSelector from "./InfotextSelector";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ font: [] }],
        [{ align: [] }],
        ["clean"]
    ]
};

const WYSIWYG = props => {
    const onChange = e => {
        props.onChange(e, props.data ? props.data : null);
    };

    return (
        <div className="velract-wysiwyg">
            {props.insert ? (
                <InfotextSelector
                    texts={props.insert}
                    onSelect={text =>
                        onChange(props.value && props.value.trim() !== "" ? props.value + "\n" + text : text)
                    }
                />
            ) : null}
            <ReactQuill theme="snow" value={props.value} onChange={onChange} modules={modules} />
        </div>
    );
};

export { WYSIWYG };
