import React from "react";
import { Dealtool } from "../plugins/senft_dealtool/src";
import store from "../redux/store";
import "../plugins/senft_dealtool/src/assets/scss/styles.scss";
import ProjectSelector from "./ProjectSelector";
import CatalogsHelper from "../services/CatalogsHelper";
import DBHelper from "../services/DBHelper";
import { connect } from "react-redux";
import DealHelper from "../plugins/senft_dealtool/src/DealHelper";

const DealEditor = (props) => {
    return (
        <Dealtool
            reduxStore={store}
            offline={true}
            projectSelector={<ProjectSelector catalogsHelper={CatalogsHelper} />}
            load={async (deal_id, callback) => {
                const deal = await DBHelper.get("deals", "id", deal_id);

                if (deal_id === deal.id) {
                    const { data } = deal;

                    props.setValue("project_id", 0);
                    props.setValue("customer_contact_id", 0);
                    props.setValue("deal_type", data.deal_type ? data.deal_type : "");
                    props.setValue("deal_key", "");
                    props.setValue("user_id", 0);
                    props.setValue("date", data.date ? data.date : "");
                    props.setValue("date_inspection", data.date_inspection ? data.date_inspection : "");
                    props.setValue("offline_project", data.offline_project);
                    props.setValue(
                        "customers_info_text",
                        data.text_customer && data.text_customer.trim() ? data.text_customer : ""
                    );
                    props.setValue(
                        "subcontractors_info_text",
                        data.text_subcontractors && data.text_subcontractors.trim() ? data.text_subcontractors : ""
                    );

                    // Kunde laden
                    let customer = null;
                    const customers = JSON.parse(localStorage.getItem("customers"));
                    for (let i in customers) {
                        if (customers[i].id === parseInt(data.offline_project.customer_id)) {
                            customer = customers[i];
                            break;
                        }
                    }
                    props.setValue("data_customer", customer);

                    // Katalog laden
                    let catalog = null;
                    if (customer && customer.catalog_id > 0) {
                        catalog = await CatalogsHelper.getCatalog(customer.catalog_id);
                    }
                    props.setValue("data_catalog", catalog);

                    // Gewerkstexte setzen
                    props.setValue(
                        "group_texts",
                        data.text_groups && Object.keys(data.text_groups).length > 0 ? data.text_groups : {}
                    );

                    // Positionen laden
                    let positions = [];
                    if (data.positions && data.positions.length > 0) {
                        for (let i in data.positions) {
                            data.positions[i].group_data = {};
                            if (data.positions[i].groups) {
                                for (let g in data.positions[i].groups) {
                                    if (data.positions[i].groups[g] && data.positions[i].groups[g].id > 0) {
                                        data.positions[i].groups[g].group_id = parseInt(data.positions[i].groups[g].id);
                                        data.positions[i].groups[g].info_text = data.positions[i].groups[g].infotext;
                                        data.positions[i].groups[g].sub_factor = data.positions[i].groups[g].nufactor;
                                        data.positions[i].group_data[parseInt(g)] = data.positions[i].groups[g];
                                    }
                                }
                            }

                            const pos = DealHelper.createPosition(data.positions[i].id, data.positions[i]);
                            if (pos) {
                                positions.push(pos);
                            }
                        }
                    }
                    props.setValue("positions", positions);

                    // FPEs laden
                    let free_positions = [];
                    if (data.freepositions && data.freepositions.length > 0) {
                        for (let i in data.freepositions) {
                            const fpe = DealHelper.createFpe(
                                data.freepositions[i].identifier,
                                data.freepositions[i].name,
                                data.freepositions[i]
                            );

                            if (fpe) {
                                free_positions.push(fpe);
                            }
                        }
                    }
                    props.setValue("free_positions", free_positions);

                    if (catalog && data.offline_project && data.offline_project.qm) {
                        props.setValue("data_price_type", DealHelper.getPriceType(catalog, data.offline_project.qm));
                    }

                    if (callback) {
                        callback();
                    }
                }
            }}
            save={(postdata, dealtool, callback) => {
                if (postdata.id === "new") {
                    postdata.id = "deal_offline_" + Math.floor(Date.now() / 1000).toString();
                }

                postdata.offline_project = dealtool.offline_project;

                DBHelper.write("deals", [
                    {
                        id: postdata.id,
                        data: postdata,
                    },
                ]);

                callback(postdata.id);
            }}
            loadFavorites={async () => {
                props.setValue("loading_favorites", true);

                let out = [];
                const favorites = await DBHelper.get("sync", "type", "catalog_favorites");
                if (
                    favorites &&
                    favorites.data &&
                    favorites.data.length > 0 &&
                    props.data_catalog &&
                    props.data_catalog.id > 0
                ) {
                    for (let i in favorites.data) {
                        if (favorites.data[i].catalog_id !== props.data_catalog.id) {
                            continue;
                        }

                        out.push({
                            item_id: favorites.data[i].item_id,
                            catalog_id: favorites.data[i].catalog_id,
                        });
                    }
                }

                props.setValue("data_catalog_favorites", out);
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    data_catalog: state.dealtool.data_catalog,
});

const mapDispatch = (dispatch) => ({
    setValue: (e, value) =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: {
                name: value !== undefined ? e : e.target.name,
                value: value !== undefined ? value : e.target.value,
            },
        }),
});
export default connect(mapStateToProps, mapDispatch)(DealEditor);
