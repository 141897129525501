import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditPositionGroups from "./EditPositionGroups";
import DealHelper from "../DealHelper";
import PositionsHelper from "../PositionsHelper";

class EditPosition extends Component {
    state = {
        item: null,
        position: null,
        isNew: false,
        isAutosaving: false,
        alert: null,
        alertMessage: null
    };

    saveHelper = 0;

    componentDidMount() {
        this.loadItem();
    }

    componentDidUpdate() {
        this.loadItem();
    }

    loadItem() {
        if (
            this.props.selector_item &&
            this.props.selector_item > 0 &&
            this.props.data_catalog &&
            this.props.data_catalog.categories
        ) {
            if (!this.state.item || this.state.item.id !== this.props.selector_item) {
                let item = null;
                for (let c in this.props.data_catalog.categories) {
                    if (
                        !this.props.data_catalog.categories[c].items ||
                        this.props.data_catalog.categories[c].items.length < 1
                    ) {
                        continue;
                    }

                    for (let i in this.props.data_catalog.categories[c].items) {
                        if (this.props.data_catalog.categories[c].items[i].id === this.props.selector_item) {
                            item = this.props.data_catalog.categories[c].items[i];
                        }
                    }
                }

                let position = null;
                if (item && this.props.positions && this.props.positions.length > 0) {
                    for (let i in this.props.positions) {
                        if (this.props.positions[i].id === item.id) {
                            position = JSON.parse(JSON.stringify(this.props.positions[i]));

                            if (position.groups) {
                                for (let g in position.groups) {
                                    if (position.groups[g].materials && position.groups[g].materials.length > 0) {
                                        for (let m in position.groups[g].materials) {
                                            if (typeof position.groups[g].materials[m].id === "string") {
                                                position.groups[g].materials[m].id = parseInt(
                                                    position.groups[g].materials[m].id
                                                );
                                            }

                                            if (
                                                position.groups[g].materials[m].version !== null &&
                                                position.groups[g].materials[m].version !== undefined &&
                                                typeof position.groups[g].materials[m].version === "string"
                                            ) {
                                                position.groups[g].materials[m].version = parseInt(
                                                    position.groups[g].materials[m].version
                                                );
                                            }

                                            if (typeof position.groups[g].materials[m].checked === "string") {
                                                position.groups[g].materials[m].checked =
                                                    position.groups[g].materials[m].checked &&
                                                    position.groups[g].materials[m].checked !== "false"
                                                        ? true
                                                        : false;
                                            }
                                        }
                                    }
                                }
                            }

                            break;
                        }
                    }
                }

                let isNew = false;
                if (!position && item) {
                    position = DealHelper.positionDefaults(item);
                    isNew = true;
                }

                this.setState({
                    item: item,
                    position: DealHelper.positionPrepareForEdit({ ...position }),
                    isNew: isNew,
                    isAutosaving: false,
                    alert: null,
                    alertMessage: null
                });
            }
        }
    }

    onChange = e => {
        this.setState({
            isAutosaving: true,
            position: {
                ...this.state.position,
                [e.target.name]: e.target.value
            }
        });

        this.autosave();
    };

    onPricePropertyChange = e => {
        this.setState({
            isAutosaving: true,
            position: {
                ...this.state.position,
                price_property_id: parseInt(e.target.getAttribute("data-group"))
            }
        });

        this.autosave();
    };

    onGroupChange = e => {
        const groupId = parseInt(e.currentTarget.getAttribute("data-group"));
        let position = { ...this.state.position };

        if (!position.groups[groupId]) {
            position.groups[groupId] = DealHelper.createGroupData(groupId);
        }

        this.setState({
            isAutosaving: true,
            position: {
                ...position,
                groups: {
                    ...position.groups,
                    [groupId]: {
                        ...position.groups[groupId],
                        [e.target.name]: e.target.value
                    }
                }
            }
        });

        this.autosave();
    };

    onMaterialToggle = e => {
        const group = parseInt(e.target.getAttribute("data-group"));
        const material = parseInt(e.target.getAttribute("data-material"));
        const defaultVersion = parseInt(e.target.getAttribute("data-defaultversion"));

        let add = true;
        let position = { ...this.state.position };
        if (position.groups && position.groups[group] && position.groups[group].materials) {
            for (let i in position.groups[group].materials) {
                if (parseInt(position.groups[group].materials[i].id) !== material) {
                    continue;
                }

                add = false;
                position.groups[group].materials[i].checked = e.target.checked;
                break;
            }
        }

        if (add) {
            if (!position.groups[group]) {
                position.groups[group] = DealHelper.createGroupData(group);
            }

            if (!position.groups[group].materials) {
                position.groups[group].materials = [];
            }

            if (defaultVersion) {
                position.groups[group].materials.push({
                    id: material,
                    version: defaultVersion,
                    checked: e.target.checked
                });
            } else {
                position.groups[group].materials.push({
                    id: material,
                    checked: e.target.checked
                });
            }
        }

        this.setState({
            isAutosaving: true,
            position: position
        });

        this.autosave();
    };

    onMaterialVersionChange = e => {
        const group = parseInt(e.target.getAttribute("data-group"));
        const material = parseInt(e.target.getAttribute("data-material"));
        const version = parseInt(e.target.value);

        let add = true;
        let position = { ...this.state.position };
        if (position.groups && position.groups[group] && position.groups[group].materials) {
            for (let i in position.groups[group].materials) {
                if (parseInt(position.groups[group].materials[i].id) !== material) {
                    continue;
                }

                add = false;
                position.groups[group].materials[i].checked = true;
                position.groups[group].materials[i].version = version;
                break;
            }
        }

        if (add) {
            if (!position.groups[group].materials) {
                position.groups[group].materials = [];
            }

            position.groups[group].materials.push({
                id: material,
                version: version,
                checked: true
            });
        }

        this.setState({
            isAutosaving: true,
            position: position
        });

        this.autosave();
    };

    onInfoTextAppend = (text, data) => {
        const groupId = parseInt(data.group_id);
        let position = { ...this.state.position };

        if (!position.groups[groupId]) {
            position.groups[groupId] = DealHelper.createGroupData(groupId);
        }

        this.setState({
            isAutosaving: true,
            position: {
                ...position,
                groups: {
                    ...position.groups,
                    [groupId]: {
                        ...position.groups[groupId],
                        infotext: position.groups[groupId].infotext
                            ? position.groups[groupId].infotext + "\n" + text
                            : text
                    }
                }
            }
        });

        this.autosave();
    };

    isFavorite = () => {
        for (let f in this.props.favorites) {
            if (this.props.favorites[f].item_id === this.props.selector_item) {
                return true;
            }
        }
        return false;
    };

    addFavorite = () => {
        this.props.setValue("loading_favorites", true);
        this.props.apiHelper
            .request("/deals/catalog/favorites/add", {
                catalog_id: this.props.data_catalog.id,
                item_id: this.props.selector_item
            })
            .then(response => {
                if (response.status && response.status === "success") {
                    this.props.setValue("loading_favorites", false);
                    this.props.loadFavorites();
                } else {
                    window.alert("Es ist ein Fehler beim Hinzufügen zu den Favoriten aufgetreten.");
                    this.props.setValue("loading_favorites", false);
                }
            })
            .catch(e => {
                console.error(e);
                window.alert("Es ist ein Fehler beim Hinzufügen zu den Favoriten aufgetreten.");
                this.props.setValue("loading_favorites", false);
            });
    };

    removeFavorite = () => {
        this.props.setValue("loading_favorites", true);
        this.props.apiHelper
            .request("/deals/catalog/favorites/remove", {
                catalog_id: this.props.data_catalog.id,
                item_id: this.props.selector_item
            })
            .then(response => {
                if (response.status && response.status === "success") {
                    this.props.setValue("loading_favorites", false);
                    this.props.loadFavorites();
                } else {
                    window.alert("Es ist ein Fehler beim Entfernen zu den Favoriten aufgetreten.");
                    this.props.setValue("loading_favorites", false);
                }
            })
            .catch(e => {
                console.error(e);
                window.alert("Es ist ein Fehler beim Entfernen zu den Favoriten aufgetreten.");
                this.props.setValue("loading_favorites", false);
            });
    };

    autosave = () => {
        this.saveHelper++;
        setTimeout(() => {
            this.saveHelper--;
            if (this.saveHelper === 0) {
                this.save(() => {
                    this.setState({
                        isAutosaving: false
                    });
                });
            }
        }, 500);
    };

    save = callback => {
        if (this.state.item.price_property === "either_or" && !this.state.position.price_property_id) {
            this.setState({
                alert: "danger",
                alertMessage: "Es muss ein Gewerk ausgewählt werden, bevor diese Position gespeichert werden kann."
            });

            return;
        }

        let add = true;
        let positions = [...this.props.positions];
        const positionPrepared = DealHelper.positionPrepareForSave(JSON.parse(JSON.stringify(this.state.position)));

        if (positions && positions.length > 0) {
            for (let i in positions) {
                if (positions[i].id === this.state.position.id) {
                    positions[i] = positionPrepared;
                    add = false;
                    break;
                }
            }
        } else {
            positions = [];
        }

        if (add) {
            positions.push(positionPrepared);
        }

        this.props.setValue("positions", positions);

        if (callback && typeof callback === "function") {
            callback();
        }

        this.setState({
            isNew: false
        });
    };

    remove = () => {
        if (window.confirm("Soll die Position wirklich entfernt werden?")) {
            let positions = [...this.props.positions];
            if (positions && positions.length > 0) {
                for (let i in positions) {
                    if (positions[i].id === this.state.position.id) {
                        positions.splice(i, 1);
                    }
                }
            }

            this.props.setValue("positions", positions);

            this.setState({
                isNew: true
            });
        }
    };

    render() {
        const { item, position } = this.state;
        let positionPrepared;
        if (this.state.position) {
            positionPrepared = DealHelper.positionPrepareForSave(JSON.parse(JSON.stringify(this.state.position)));
        }

        if (!this.props.selector_item || this.props.selector_item < 1) {
            return <div className="dealtool-edit-position-no-pos">Es wurde noch keine Position ausgewählt</div>;
        }

        if (!item || !position) {
            return <div>Es ist ein Fehler beim Laden der Position aus dem Katalog aufgetreten.</div>;
        }

        let favoriteButton;
        if (!this.props.offline) {
            if (this.props.favoritesLoading) {
                favoriteButton = <FontAwesomeIcon icon="spinner" spin className="mr-2" />;
            } else if (this.isFavorite()) {
                favoriteButton = (
                    <FontAwesomeIcon
                        icon={["fas", "star"]}
                        className="mr-2"
                        style={{ cursor: "pointer", color: "#f8ab59" }}
                        onClick={this.removeFavorite}
                    />
                );
            } else {
                favoriteButton = (
                    <FontAwesomeIcon
                        icon={["far", "star"]}
                        className="mr-2"
                        style={{ cursor: "pointer" }}
                        onClick={this.addFavorite}
                    />
                );
            }
        }

        return (
            <div className="dealtool-edit-position">
                <div className="item-header">
                    <div className="float-right">
                        {favoriteButton}

                        <button
                            type="button"
                            className={"btn btn-" + (this.state.isNew ? "success" : "danger")}
                            onClick={this.state.isNew ? this.save : this.remove}
                            disabled={this.state.isAutosaving}
                        >
                            {this.state.isAutosaving ? (
                                <React.Fragment>
                                    <FontAwesomeIcon icon="spinner" className="fa-spin mr-1" />
                                    Speichert ...
                                </React.Fragment>
                            ) : this.state.isNew ? (
                                "Hinzufügen"
                            ) : (
                                "Entfernen"
                            )}
                        </button>
                    </div>
                    <span className="badge badge-primary mr-1">{DealHelper.itemNumber(item)}</span>
                    {item.name}

                    <div className="clearfix small">
                        {item.gaeb_numbers && item.gaeb_numbers.length > 0 ? (
                            <small>
                                {item.gaeb_numbers.map(
                                    (number, i) => number.number + (i + 1 === item.gaeb_numbers.length ? "" : ", ")
                                )}
                            </small>
                        ) : null}
                    </div>
                </div>

                {this.state.alert && this.state.alertMessage ? (
                    <div className={"alert alert-" + this.state.alert} style={{ borderRadius: 0 }}>
                        {this.state.alertMessage}
                    </div>
                ) : null}

                <div className="dealtool-edit-position-container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Menge ({item.unit})</label>
                                <input
                                    type="text"
                                    name="quantity"
                                    className="form-control"
                                    value={position.quantity}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Multiplikator</label>
                                <input
                                    type="text"
                                    name="price_multiplier"
                                    className="form-control"
                                    value={position.price_multiplier}
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Eventualposition</label>
                                <select
                                    name="contingency"
                                    className="form-control"
                                    value={position.contingency}
                                    onChange={this.onChange}
                                >
                                    <option value={true}>Ja</option>
                                    <option value={false}>Nein</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {positionPrepared ? (
                        <div className="card">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th>Preisberechnung</th>
                                        <th className="text-right">EP (EUR)</th>
                                        <th className="text-right">GP (EUR)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ fontWeight: 600 }}>Kundenpreis:</td>
                                        <td className="text-right">
                                            {DealHelper.number_format(
                                                PositionsHelper.getSinglePrice(
                                                    this.props.price_type_id,
                                                    item,
                                                    positionPrepared
                                                )
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {DealHelper.number_format(
                                                PositionsHelper.getSinglePrice(
                                                    this.props.price_type_id,
                                                    item,
                                                    positionPrepared
                                                ) * DealHelper.input2Float(position.quantity)
                                            )}
                                        </td>
                                    </tr>
                                    {item.groups && item.groups.length > 0
                                        ? item.groups.map(group => {
                                              const weighting = PositionsHelper.getWeighting(
                                                  group.id,
                                                  positionPrepared,
                                                  item
                                              );

                                              return (
                                                  <tr key={"pos-editor-grp-price-" + group.id}>
                                                      <td style={{ fontWeight: 600 }}>
                                                          {group.prefix ? group.prefix + " - " : null}
                                                          {group.name}
                                                      </td>
                                                      <td className="text-right">
                                                          {DealHelper.number_format(
                                                              PositionsHelper.getSinglePrice(
                                                                  this.props.price_type_id,
                                                                  item,
                                                                  positionPrepared,
                                                                  "value",
                                                                  group.id
                                                              )
                                                          )}
                                                      </td>
                                                      <td className="text-right">
                                                          {DealHelper.number_format(
                                                              PositionsHelper.getSinglePrice(
                                                                  this.props.price_type_id,
                                                                  item,
                                                                  positionPrepared,
                                                                  "value",
                                                                  group.id
                                                              ) * weighting
                                                          )}
                                                      </td>
                                                  </tr>
                                              );
                                          })
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    <EditPositionGroups
                        item={item}
                        position={position}
                        onChange={this.onGroupChange}
                        onMaterialToggle={this.onMaterialToggle}
                        onMaterialVersionChange={this.onMaterialVersionChange}
                        onPricePropertyChange={this.onPricePropertyChange}
                        onInfoTextAppend={this.onInfoTextAppend}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    price_type_id: state.dealtool.data_price_type ? state.dealtool.data_price_type.id : null,
    selector_item: state.dealtool.selector_item,
    data_catalog: state.dealtool.data_catalog,
    positions: state.dealtool.positions,
    favorites: state.dealtool.data_catalog_favorites,
    favoritesLoading: state.dealtool.loading_favorites
});

const mapDispatch = dispatch => ({
    setValue: (e, value) =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: {
                name: value !== undefined ? e : e.target.name,
                value: value !== undefined ? value : e.target.value
            }
        })
});
export default connect(mapStateToProps, mapDispatch)(EditPosition);
