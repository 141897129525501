import ApiHelper from "./ApiHelper";
import moment from "moment";
import DBHelper from "./DBHelper";

class DealsHelper {
    deals_data = null;

    loadData() {
        let data = localStorage.getItem("deals");

        if (data !== undefined && data !== null) {
            this.deals_data = JSON.parse(data);
        } else {
            this.deals_data = null;
        }
    }

    getDeals() {
        if (this.deals_data === null) {
            this.loadData();
        }

        return this.deals_data;
    }

    getDeal(id) {
        if (this.deals_data === null) {
            this.loadData();
        }

        if (this.deals_data === undefined || !this.deals_data || this.deals_data.length < 1) {
            return null;
        }

        this.loadData();
        for (let k in this.deals_data) {
            if (this.deals_data[k] && this.deals_data[k].id !== undefined && this.deals_data[k].id === id) {
                return this.deals_data[k];
            }
        }
        return null;
    }

    saveDeal(deal_data) {
        if (this.deals_data === null) {
            this.loadData();
        }

        if (this.deals_data === null || !this.deals_data || this.deals_data.length < 1) {
            this.deals_data = [];
        }

        let status = true;
        if (this.deals_data.length > 0) {
            for (let k in this.deals_data) {
                if (this.deals_data[k].id !== undefined && this.deals_data[k].id === deal_data.id) {
                    this.deals_data[k] = deal_data;
                    status = false;
                }
            }
        }
        if (status) {
            this.deals_data.push(deal_data);
        }
        localStorage.setItem("deals", JSON.stringify(this.deals_data));
    }

    removeDeal(deal_id, old) {
        if (old) {
            // Alte Angebots-Lösch-Funktion
            if (this.deals_data === null) {
                this.loadData();
            }

            let remove = null;
            if (this.deals_data && this.deals_data.length > 0) {
                for (let i in this.deals_data) {
                    if (this.deals_data[i].id !== undefined && this.deals_data[i].id === deal_id) {
                        remove = i;
                    }
                }
            }

            if (remove) {
                this.deals_data.splice(remove, 1);
            }
            localStorage.setItem("deals", JSON.stringify(this.deals_data));
        } else {
            DBHelper.delete("deals", deal_id);
        }
    }

    uploadDeal(deal_id, callback, error) {
        let self = this;
        let deal_temp = this.getDeal(deal_id);

        if (deal_temp) {
            ApiHelper.request("/upload", deal_temp)
                .then(function(data) {
                    if (data.status === "success") {
                        deal_temp.uploaded = true;
                        deal_temp.uploaded_at = moment().format();
                        deal_temp.deal_number = data.deal_number ? data.deal_number : null;
                        self.saveDeal(deal_temp);

                        if (callback) {
                            callback(data);
                        }
                    } else {
                        error(data);
                    }
                })
                .catch(function(e) {
                    console.error(e);

                    if (error) {
                        error(e);
                    }
                });
            return true;
        }
        return false;
    }

    exportDeal(deal_id) {
        let deal = this.getDeal(deal_id);
        if (!deal) {
            return false;
        }
        deal.export_moment = moment().toISOString();

        let element = document.createElement("a");
        let file = new Blob([JSON.stringify(deal)], { type: "text/json" });
        element.href = URL.createObjectURL(file);
        element.download = deal.id + ".senftdeal";
        element.click();
    }
}

export default new DealsHelper();
