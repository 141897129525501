import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import DealHelper from "../plugins/senft_dealtool/src/DealHelper";
import Icon from "../components/Icon";
import ApiHelper from "services/ApiHelper";

class ProjectSelector extends Component {
    state = {
        customers: null,
        projectSearch: {
            number: "",
            street: "",
            housenumber: "",
            zip: "",
            city: "",
        },
        projectSearchIsOpen: false,
        projectSearchSearching: false,
        projectSearchSearched: false,
        projectSearchResults: null,
    };

    componentDidMount() {
        this.setState({
            customers: JSON.parse(localStorage.getItem("customers")),
        });
    }

    toggleProjectSearch = () => {
        this.setState({
            projectSearchIsOpen: !this.state.projectSearchIsOpen,
        });
    };

    projectSearchChange = (e) => {
        this.setState({
            projectSearch: {
                ...this.state.projectSearch,
                [e.target.name]: e.target.value,
            },
        });
    };

    projectsSearch = () => {
        this.setState({
            projectSearchSearching: true,
            projectSearchSearched: true,
            projectSearchResults: null,
        });

        ApiHelper.request("/search-project", this.state.projectSearch, "v2")
            .then((json) => {
                this.setState({
                    projectSearchSearching: false,
                    projectSearchResults:
                        json && json.status === "success" && json.data && json.data.length > 0 ? json.data : null,
                });
            })
            .catch((e) => {
                console.error(e);
                this.setState({ projectSearchSearching: false, projectSearchResults: null });
                alert("Es ist ein Fehler beim Laden der Bauprojekte aufgetreten.");
            });
    };

    applyProject = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        const sel = this.state.projectSearchResults[index];
        if (!sel) {
            alert("Es ist ein Fehler beim Übernehmen des Bauprojekts aufgetreten.");
            return;
        }

        this.props.changeOfflineProject({
            target: { name: "project_number", value: sel.project_number ? sel.project_number : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "customer_id", value: sel.customer_id ? sel.customer_id : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "street", value: sel.address_street ? sel.address_street : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "housenumber", value: sel.address_housenumber ? sel.address_housenumber : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "zip", value: sel.address_zip ? sel.address_zip : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "city", value: sel.address_city ? sel.address_city : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "location", value: sel.location ? sel.location : "" },
        });
        this.props.changeOfflineProject({
            target: { name: "qm", value: sel.qm ? sel.qm : "" },
        });

        this.setState({
            projectSearchIsOpen: false,
            projectSearchSearching: false,
            projectSearchSearched: false,
            projectSearchResults: null,
        });
    };

    relevantProjectInfoChange = (e) => {
        this.props.changeOfflineProject(e);

        this.props.setValue("loading_customer_catalog", true);

        setTimeout(async () => {
            let customer = null;
            for (let i in this.state.customers) {
                if (this.state.customers[i].id === parseInt(this.props.offlineProject.customer_id)) {
                    customer = this.state.customers[i];
                    break;
                }
            }

            let catalog = null;
            if (customer && customer.catalog_id > 0) {
                catalog = await this.props.catalogsHelper.getCatalog(customer.catalog_id);
            }

            this.props.setValue("data_customer", customer);
            this.props.setValue("data_customer_contacts", null); // Später setzen (wird in offline-app noch nicht verwendet)
            this.props.setValue("data_catalog", catalog);
            this.props.setValue("data_price_type", DealHelper.getPriceType(catalog, this.props.offlineProject.qm));

            setTimeout(() => {
                this.props.setValue("loading_customer_catalog", false);
            }, 300);
        }, 200);
    };

    render() {
        const { offlineProject } = this.props;
        return (
            <div className="card">
                {this.state.projectSearchIsOpen ? (
                    <Modal
                        isOpen={true}
                        onRequestClose={this.toggleProjectSearch}
                        ariaHideApp={false}
                        style={{
                            overlay: {
                                zIndex: 50000,
                            },
                            content: {
                                padding: 0,
                            },
                        }}
                    >
                        <div className="content">
                            <button
                                type="button"
                                className="btn btn-secondary btn-sm float-right"
                                onClick={this.toggleProjectSearch}
                            >
                                Schließen
                            </button>
                            <h1 className="mb-3">Bauprojekt auswählen</h1>
                            <div className="row">
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>Projektnummer</label>
                                                <input
                                                    type="text"
                                                    value={this.state.projectSearch.number}
                                                    name="number"
                                                    onChange={this.projectSearchChange}
                                                    disabled={this.state.projectSearchSearching}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label>Straße</label>
                                                <input
                                                    type="text"
                                                    value={this.state.projectSearch.street}
                                                    name="street"
                                                    onChange={this.projectSearchChange}
                                                    disabled={this.state.projectSearchSearching}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-1">
                                            <div className="form-group">
                                                <label>Hausnummer</label>
                                                <input
                                                    type="text"
                                                    value={this.state.projectSearch.housenumber}
                                                    name="housenumber"
                                                    onChange={this.projectSearchChange}
                                                    disabled={this.state.projectSearchSearching}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>PLZ</label>
                                                <input
                                                    type="text"
                                                    value={this.state.projectSearch.zip}
                                                    name="zip"
                                                    onChange={this.projectSearchChange}
                                                    disabled={this.state.projectSearchSearching}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>Stadt</label>
                                                <input
                                                    type="text"
                                                    value={this.state.projectSearch.city}
                                                    name="city"
                                                    onChange={this.projectSearchChange}
                                                    disabled={this.state.projectSearchSearching}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button
                                            type="button"
                                            className="btn btn-success btn-block"
                                            disabled={this.state.projectSearchSearching}
                                            onClick={this.projectsSearch}
                                        >
                                            Suchen
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {this.state.projectSearchSearching ? (
                                <div className="text-center">
                                    <Icon icon="spinner" className="fa-spin" />
                                    <br />
                                    Bauprojekte werden geladen ...
                                </div>
                            ) : this.state.projectSearchSearched ? (
                                this.state.projectSearchResults && this.state.projectSearchResults.length > 0 ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Bauprojektnummer</th>
                                                <th>Adresse</th>
                                                <th>Lage</th>
                                                <th className="text-right">Größe (qm)</th>
                                                <th>Kunde</th>
                                                <th className="text-right">Optionen</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.projectSearchResults.map((el, i) => (
                                                <tr key={i}>
                                                    <td>{el.project_number ? el.project_number : "-"}</td>
                                                    <td>
                                                        {el.address_street} {el.address_housenumber}, {el.address_zip}{" "}
                                                        {el.address_city}
                                                    </td>
                                                    <td>{el.location ? el.location : "-"}</td>
                                                    <td className="text-right">{el.qm}</td>
                                                    <td>{el.customer_name ? el.customer_name : "-"}</td>
                                                    <td
                                                        className="text-right"
                                                        style={{ paddingTop: 3, paddingBottom: 0 }}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={this.applyProject}
                                                            data-index={i}
                                                        >
                                                            Übernehmen
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="alert alert-light">Es wurden keine Bauprojekte gefunden</div>
                                )
                            ) : null}
                        </div>
                    </Modal>
                ) : null}

                <div className="card-body">
                    <div className="form-group">
                        <button
                            type="button"
                            className="btn btn-secondary float-right btn-xs"
                            onClick={this.toggleProjectSearch}
                        >
                            Bauprojekt auswählen
                        </button>
                        <label>Kunde</label>
                        <select
                            name="customer_id"
                            className="form-control"
                            value={offlineProject.customer_id}
                            onChange={this.relevantProjectInfoChange}
                        >
                            <option value="">Kunden auswählen</option>

                            {this.state.customers && this.state.customers.length > 0
                                ? this.state.customers.map((customer) => (
                                      <option key={"project_customer_" + customer.id} value={customer.id}>
                                          {customer.name}
                                      </option>
                                  ))
                                : null}
                        </select>
                    </div>

                    <div className="row">
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label>Straße</label>
                                <input
                                    type="text"
                                    name="street"
                                    className="form-control"
                                    value={offlineProject.street}
                                    onChange={this.props.changeOfflineProject}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>Hausnummer</label>
                                <input
                                    type="text"
                                    name="housenumber"
                                    className="form-control"
                                    value={offlineProject.housenumber}
                                    onChange={this.props.changeOfflineProject}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>PLZ</label>
                                <input
                                    type="text"
                                    name="zip"
                                    className="form-control"
                                    value={offlineProject.zip}
                                    onChange={this.props.changeOfflineProject}
                                />
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label>Stadt</label>
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    value={offlineProject.city}
                                    onChange={this.props.changeOfflineProject}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Lage / Etage</label>
                                <input
                                    type="text"
                                    name="location"
                                    className="form-control"
                                    value={offlineProject.location}
                                    onChange={this.props.changeOfflineProject}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>Größe (qm)</label>
                                <input
                                    type="number"
                                    name="qm"
                                    className="form-control"
                                    value={offlineProject.qm}
                                    onChange={this.relevantProjectInfoChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>Projektnummer</label>
                                <input
                                    type="text"
                                    name="project_number"
                                    className="form-control"
                                    value={offlineProject.project_number ? offlineProject.project_number : ""}
                                    onChange={this.relevantProjectInfoChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    offlineProject: state.dealtool.offline_project,
});

const mapDispatch = (dispatch) => ({
    changeOfflineProject: (e) =>
        dispatch({ type: "SET_OFFLINE_PROJECT_VALUE", payload: { name: e.target.name, value: e.target.value } }),
    setValue: (e, value) =>
        dispatch({
            type: "SET_DEAL_VALUE",
            payload: {
                name: value !== undefined ? e : e.target.name,
                value: value !== undefined ? value : e.target.value,
            },
        }),
});
export default connect(mapStateToProps, mapDispatch)(ProjectSelector);
