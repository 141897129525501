import React, { Component } from 'react';
import CustomersHelper from '../../services/CustomersHelper';

export default class CustomerComponent extends Component {

    state = {
        isLoading: true,
        notFound: false,
        customer_id: null,
        customer: null
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({customer_id: id, customer: CustomersHelper.getCustomer(id), isLoading: false});
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div className="content">
                    <h1>Kunde</h1>

                    Daten werden geladen ...
                </div>
            );
        }


        if(this.state.customer_id < 1 || this.state.customer === null) {
            return (
                <div className="content">
                    <h1>Kunde</h1>

                    Der aufgerufene Kunde wurde nicht gefunden.
                </div>
            );
        }

        let { customer } = this.state;
        let contactPersons;
        if(customer.contact_persons !== undefined && customer.contact_persons.length > 0) {
            let personsList = customer.contact_persons.map((element) =>
                <div key={'ansprechpartner' + element.id} className="col-md-4 col-sm-6">
                    <div className="panel panel-default panel-sm">
                        <div className="panel-heading">{element.first_name} {element.name}</div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>Telefon</td>
                                    <td>{element.phone}</td>
                                </tr>
                                <tr>
                                    <td>Telefon (mobil)</td>
                                    <td>{element.phone_mobile}</td>
                                </tr>
                                <tr>
                                    <td>E-Mail</td>
                                    <td>{element.email}</td>
                                </tr>
                                <tr>
                                    <td>Fax</td>
                                    <td>{element.fax}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );

            contactPersons = (
                <div className="row">
                    <div className="col-md-12"><h3>Ansprechpartner</h3></div>
                    {personsList}
                </div>
            );
        }

        return (
            <div>
                <div className="content">
                    <h1>Kunde <span>> {customer.name}</span></h1>    

                    <div className="row">
                        <div className="col-md-6">
                            <div className="panel panel-default">
                                <div className="panel-heading">Daten</div>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Straße</td>
                                            <td>{customer.street}</td>
                                        </tr>
                                        <tr>
                                            <td>PLZ</td>
                                            <td>{customer.zip}</td>
                                        </tr>
                                        <tr>
                                            <td>Stadt</td>
                                            <td>{customer.city}</td>
                                        </tr>
                                        <tr>
                                            <td>E-Mail</td>
                                            <td>{customer.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Telefon</td>
                                            <td>{customer.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Telefon (mobil)</td>
                                            <td>{customer.phone_mobile}</td>
                                        </tr>
                                        <tr>
                                            <td>Fax</td>
                                            <td>{customer.fax}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {contactPersons}
                </div>
            </div>
        );
    }

}