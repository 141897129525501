import React, { Component } from "react";
import CatalogsHelper from "services/CatalogsHelper";
import BigTextareas from "services/BigTextareas";
import PredefinedTextSelector from "components/PredefinedTextSelector";
import $ from "jquery";

export default class EditPosition extends Component {
    state = {
        item: null,
        groups: null,
        last_item: null,
        data_quantity: 1,
        data_price_multiplier: 1,
        data_groups: {},
        data_contingency: false,
        data_price_property: null,
        saveWeightingError: false
    };

    componentDidMount() {
        this.loadPositionData();
        this.setScrollEvent();
    }

    componentDidUpdate() {
        if (this.state.last_item !== this.props.item) {
            this.loadPositionData();
            this.setScrollEvent();
        }
    }

    setScrollEvent() {
        $("#selector-current-item")
            .unbind("scroll")
            .bind("scroll", function() {
                if ($(window).width() > 767) {
                    if ($(this).scrollTop() > 60) {
                        if (!$("#positionEditSaveBtns").hasClass("fixed-top")) {
                            $("#currentPositionTitle").css(
                                "height",
                                $("#currentPositionTitle").height() +
                                    $("#positionEditSaveBtns").outerHeight(true) +
                                    "px"
                            );
                        }

                        $("#positionEditSaveBtns")
                            .addClass("fixed-top")
                            .css("top", $("#dealPositionSelectorHead").height() + "px")
                            .css("left", $("#editPositionContainer").offset().left + "px")
                            .css("width", $("#editPositionContainer").outerWidth() + "px");
                    } else {
                        if ($("#positionEditSaveBtns").hasClass("fixed-top")) {
                            $("#positionEditSaveBtns")
                                .removeClass("fixed-top")
                                .attr("style", "")
                                .css("width", "100%")
                                .css("margin-bottom", "15px");

                            $("#currentPositionTitle").css("height", "auto");
                        }
                    }
                }
            });
        $("#selector-current-item").scroll();
    }

    loadPositionData() {
        if (
            this.props.catalog !== undefined &&
            this.props.catalog.items !== undefined &&
            this.props.catalog.items.length > 0
        ) {
            for (let k in this.props.catalog.items) {
                if (
                    this.props.catalog.items[k].id !== undefined &&
                    parseInt(this.props.catalog.items[k].id, 10) === parseInt(this.props.item, 10)
                ) {
                    let groups = null;
                    let data = {
                        item: this.props.catalog.items[k],
                        last_item: this.props.item,
                        groups: null,
                        data_quantity: 1,
                        data_price_multiplier: 1,
                        data_groups: {},
                        data_contingency: false,
                        data_price_property: null,
                        saveWeightingError: false
                    };

                    // CatalogGroup-Daten laden
                    if (
                        this.props.catalog.items[k].groups !== undefined &&
                        this.props.catalog.items[k].groups.length > 0
                    ) {
                        groups = [];
                        for (let g in this.props.catalog.items[k].groups) {
                            let group = CatalogsHelper.getGroup(this.props.catalog.items[k].groups[g].id);

                            if (
                                group &&
                                group.id !== undefined &&
                                parseInt(group.id, 10) === parseInt(this.props.catalog.items[k].groups[g].id, 10)
                            ) {
                                let infotext = "";
                                if (
                                    this.props.catalog.items[k].groups[g].default_info_text &&
                                    this.props.catalog.items[k].groups[g].default_info_text.trim() !== ""
                                ) {
                                    infotext = this.props.catalog.items[k].groups[g].default_info_text.trim();
                                }

                                let groupMaterials = [];
                                if (
                                    this.props.catalog.items[k].materials &&
                                    this.props.catalog.items[k].materials.length > 0
                                ) {
                                    for (let c in this.props.catalog.items[k].materials) {
                                        if (
                                            !this.props.catalog.items[k].materials[c].materials ||
                                            this.props.catalog.items[k].materials[c].materials.length < 1
                                        ) {
                                            continue;
                                        }

                                        for (let m in this.props.catalog.items[k].materials[c].materials) {
                                            const material = this.props.catalog.items[k].materials[c].materials[m];
                                            if (
                                                parseInt(material.group_id) !==
                                                parseInt(this.props.catalog.items[k].groups[g].id)
                                            ) {
                                                continue;
                                            }

                                            if (material.default) {
                                                let version = null;
                                                let addMaterial = true;
                                                if (material.versions.length > 0) {
                                                    if (material.default_version) {
                                                        version = material.default_version;
                                                    } else {
                                                        addMaterial = false;
                                                    }
                                                }

                                                if (addMaterial) {
                                                    groupMaterials.push({
                                                        id: material.material_id,
                                                        version: version,
                                                        checked: true
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }

                                groups.push(group);
                                data.data_groups["group_" + group.id] = {
                                    group_id: group.id,
                                    info_text: infotext,
                                    weighting: null,
                                    nu_factors: 1,
                                    materials: groupMaterials
                                };
                            }
                        }

                        data.groups = groups;
                    }

                    // Angebots-Werte der Position laden
                    if (this.props.positions && this.props.positions.length > 0) {
                        for (let p in this.props.positions) {
                            if (
                                this.props.positions[p].item_id !== undefined &&
                                parseInt(this.props.positions[p].item_id, 10) === parseInt(this.props.item, 10)
                            ) {
                                // Positionsdaten setzen
                                data.data_quantity = this.props.positions[p].quantity;
                                data.data_price_multiplier = this.props.positions[p].multiplier;

                                if (
                                    this.props.positions[p].contingency !== undefined &&
                                    this.props.positions[p].contingency &&
                                    this.props.positions[p].contingency.toString() === "true"
                                ) {
                                    data.data_contingency = true;
                                } else {
                                    data.data_contingency = false;
                                }

                                if (
                                    this.props.positions[p].price_property !== undefined &&
                                    this.props.positions[p].price_property > 0
                                ) {
                                    data.data_price_property = this.props.positions[p].price_property;
                                } else {
                                    data.data_price_property = null;
                                }

                                // Gewerks-Infos setzen
                                if (data.groups && data.groups.length > 0) {
                                    for (let grp in data.groups) {
                                        let info_text = "";
                                        let weighting = null;
                                        let nu_factors = 1;
                                        let materials = [];

                                        if (
                                            this.props.positions[p].groups["group_" + data.groups[grp].id] !== undefined
                                        ) {
                                            if (
                                                this.props.positions[p].groups["group_" + data.groups[grp].id]
                                                    .info_text !== undefined
                                            ) {
                                                info_text = this.props.positions[p].groups[
                                                    "group_" + data.groups[grp].id
                                                ].info_text;
                                            }
                                            if (
                                                this.props.positions[p].groups["group_" + data.groups[grp].id]
                                                    .weighting !== undefined
                                            ) {
                                                weighting = this.props.positions[p].groups[
                                                    "group_" + data.groups[grp].id
                                                ].weighting;
                                            }
                                            if (
                                                this.props.positions[p].groups["group_" + data.groups[grp].id]
                                                    .nu_factors !== undefined
                                            ) {
                                                nu_factors = this.props.positions[p].groups[
                                                    "group_" + data.groups[grp].id
                                                ].nu_factors;
                                            }
                                            if (
                                                this.props.positions[p].groups["group_" + data.groups[grp].id]
                                                    .materials !== undefined &&
                                                this.props.positions[p].groups["group_" + data.groups[grp].id]
                                                    .materials &&
                                                this.props.positions[p].groups["group_" + data.groups[grp].id].materials
                                                    .length > 0
                                            ) {
                                                materials = this.props.positions[p].groups[
                                                    "group_" + data.groups[grp].id
                                                ].materials;
                                            }
                                        }

                                        data.data_groups["group_" + data.groups[grp].id].info_text = info_text;
                                        data.data_groups["group_" + data.groups[grp].id].weighting = weighting;
                                        data.data_groups["group_" + data.groups[grp].id].nu_factors = nu_factors;
                                        data.data_groups["group_" + data.groups[grp].id].materials = materials;
                                    }
                                }
                                break;
                            }
                        }
                    }

                    this.setState(data);
                    break;
                }
            }
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value = target.type === "checkbox" ? target.checked : target.value;

        if (target.getAttribute("type") === "number") {
            value = parseFloat(value.toString().replace(",", "."));
        }

        this.setState({
            [name]: value
        });
    }

    changeContingency(event) {
        const target = event.target;

        if (!target.value || target.value === "false") {
            this.setState({
                data_contingency: false
            });
        } else {
            this.setState({
                data_contingency: true
            });
        }
    }

    calcValue(event) {
        const element = event.target;
        const type = element.getAttribute("data-type");
        const target = element.getAttribute("data-target");

        let groups = null;
        let group = null;
        let value = 0;
        if (target === "group_weighting") {
            groups = this.state.data_groups;
            group = element.getAttribute("data-group");

            if (groups["group_" + group] !== undefined && groups["group_" + group].weighting !== undefined) {
                if (groups["group_" + group].weighting !== null) {
                    value = parseFloat(groups["group_" + group].weighting);
                }

                if (type === "minus") {
                    groups["group_" + group].weighting = value - 1;
                } else if (type === "plus") {
                    groups["group_" + group].weighting = value + 1;
                }

                this.setState({
                    data_groups: groups
                });
            }
        } else if (target === "group_nu_factors") {
            groups = this.state.data_groups;
            group = element.getAttribute("data-group");

            if (groups["group_" + group] !== undefined && groups["group_" + group].nu_factors !== undefined) {
                if (groups["group_" + group].nu_factors !== null) {
                    value = parseFloat(groups["group_" + group].nu_factors);
                }

                if (type === "minus") {
                    groups["group_" + group].nu_factors = value - 1;
                } else if (type === "plus") {
                    groups["group_" + group].nu_factors = value + 1;
                }

                this.setState({
                    data_groups: groups
                });
            }
        } else {
            if (this.state[target] !== undefined) {
                if (type === "minus") {
                    value = parseFloat(this.state[target]) - 1;
                } else if (type === "plus") {
                    value = parseFloat(this.state[target]) + 1;
                }
            }

            this.setState({
                [target]: value
            });
        }

        this.handlePricePropertyGroupWeighting();
    }

    handleGroupValueChange(event) {
        const target = event.target;
        const group = target.getAttribute("data-group");
        let value = target.value;

        let groups = this.state.data_groups;
        if (target.type === "number") {
            // Gewichtung aktualisieren
            if (target.getAttribute("data-target") === "group_weighting") {
                if (value && value.trim() !== "") {
                    groups["group_" + group].weighting = parseFloat(value.toString().replace(",", "."));
                } else if (value.trim() === "") {
                    groups["group_" + group].weighting = "";
                } else {
                    groups["group_" + group].weighting = null;
                }
            }

            if (target.getAttribute("data-target") === "group_nu_factors") {
                if (value && value.trim() !== "") {
                    groups["group_" + group].nu_factors = parseFloat(value.toString().replace(",", "."));
                } else if (value.trim() === "") {
                    groups["group_" + group].nu_factors = "";
                } else {
                    groups["group_" + group].nu_factors = 1;
                }
            }
        } else {
            // Info-Text aktualisieren
            groups["group_" + group].info_text = value;
        }

        this.setState({
            data_groups: groups
        });

        this.handlePricePropertyGroupWeighting();
    }

    resetGroupWeighting(event) {
        const target = event.target;
        const group = target.getAttribute("data-group");

        let groups = this.state.data_groups;
        groups["group_" + group].weighting = null;

        this.setState({
            data_groups: groups
        });
    }

    positionIsInDeal() {
        if (this.props.positions !== undefined && this.props.positions.length > 0 && this.state.item.id > 0) {
            for (let i in this.props.positions) {
                if (
                    this.props.positions[i].item_id !== undefined &&
                    this.props.positions[i].item_id === this.state.item.id
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    infoTextUpdate(target, text, data) {
        let groups = this.state.data_groups;
        let text_pre = groups["group_" + data.group_id].info_text ? groups["group_" + data.group_id].info_text : "";
        groups["group_" + data.group_id].info_text = text_pre + text;

        this.setState({
            data_groups: groups
        });
    }

    checkGroupPriceProperty(item, group) {
        if (item.price_property === undefined || !item.price_property || item.price_property !== "either_or") {
            return true;
        }

        if (group.id === this.state.data_price_property) {
            return true;
        }
        return false;
    }

    changePriceProperty(e) {
        let group = parseInt(e.target.getAttribute("data-group"), 10);

        if (!e.target.checked || group < 1) {
            group = null;
        }

        this.setState({
            data_price_property: group
        });

        this.handlePricePropertyGroupWeighting(group);
    }

    handlePricePropertyGroupWeighting(group_id) {
        // Bei Either-Or Positionen alle Gewerks-Gewichtungen gleich 0 setzen, die nicht dem ausgewählten Gewerk entsprechen
        if (this.state.item.price_property !== undefined && this.state.item.price_property === "either_or") {
            let groups = this.state.data_groups;
            for (let i in groups) {
                if (group_id === undefined) {
                    if (groups[i].group_id !== this.state.data_price_property) {
                        groups[i].weighting = 0;
                    }
                } else {
                    if (groups[i].group_id !== this.state.data_price_property && groups[i].group_id !== group_id) {
                        groups[i].weighting = 0;
                    } else {
                        groups[i].weighting = null;
                    }
                }
            }

            this.setState({
                data_groups: groups
            });
        }
    }

    setMaterialVersion(group_id, material_id, version_id) {
        let groups = this.state.data_groups;
        let materials = null;
        for (let g in groups) {
            if (parseInt(groups[g].group_id, 10) === parseInt(group_id, 10)) {
                materials = groups[g].materials;
                break;
            }
        }

        if (!materials) {
            materials = [];
        }

        let status = true;
        version_id = parseInt(version_id, 10);
        for (let i in materials) {
            if (parseInt(materials[i].id, 10) === parseInt(material_id, 10)) {
                status = false;
                materials[i].version = version_id && version_id > 0 ? version_id : null;
            }
        }

        if (status) {
            materials.push({
                id: material_id,
                version: version_id && version_id > 0 ? version_id : null,
                checked: true
            });
        }

        this.setState({
            data_groups: groups
        });
    }

    toggleMaterial(group_id, material_id, checked, defaultVersion) {
        let groups = this.state.data_groups;

        let materials = null;
        for (let g in groups) {
            if (parseInt(groups[g].group_id, 10) === parseInt(group_id, 10)) {
                materials = groups[g].materials;
                break;
            }
        }

        if (!materials) {
            materials = [];
        }

        let status = true;
        for (let i in materials) {
            if (parseInt(materials[i].id, 10) === parseInt(material_id, 10)) {
                status = false;
                materials[i].checked = checked;
            }
        }

        if (status) {
            materials.push({
                id: material_id,
                version: defaultVersion,
                checked: checked
            });
        }

        for (let g in groups) {
            if (parseInt(groups[g].group_id, 10) === parseInt(group_id, 10)) {
                groups[g].materials = materials;
            }
        }

        this.setState({
            data_groups: groups
        });
    }

    removePosition() {
        this.props.remove(this.state.item.id);
    }

    save() {
        // Bei Either-Or Positionen gucken ob ein Gewerk gewichtet wurde
        if (this.state.item.price_property !== undefined && this.state.item.price_property === "either_or") {
            if (!this.state.data_price_property || this.state.data_price_property < 1) {
                this.setState({
                    saveWeightingError: true
                });
                return;
            }
        }

        this.setState({
            saveWeightingError: false
        });

        let data = {
            item_id: this.state.item.id,
            quantity: this.state.data_quantity,
            multiplier: this.state.data_price_multiplier,
            groups: this.state.data_groups,
            contingency: this.state.data_contingency,
            price_property: this.state.data_price_property
        };
        this.props.save(data);
    }

    render() {
        let { item, groups } = this.state;
        let gewerke = (
            <div className="col-md-12">
                <div className="alert alert-warning">Diese Position hat keine Gewerke</div>
            </div>
        );

        if (item === null) {
            return (
                <div className="selector-current-item-content">
                    <div className="alert alert-danger">Die Position wurde nicht gefunden</div>
                </div>
            );
        }

        let saveAlertMessage;
        if (this.state.saveWeightingError) {
            saveAlertMessage = (
                <div className="alert alert-danger">
                    Es muss ein Gewerk gewichtet werden, bevor die Position gespeichert werden kann.
                </div>
            );
        }

        if (groups && groups.length > 0) {
            gewerke = groups.map(element => {
                let matsCountGroup = 0;
                return (
                    <div key={"groupPanel_" + element.id}>
                        <div className="col-md-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <input
                                        type="checkbox"
                                        className={
                                            item.price_property !== undefined && item.price_property === "either_or"
                                                ? ""
                                                : "hidden"
                                        }
                                        id={"pricePropCheck_" + element.id}
                                        style={{ marginRight: "5px" }}
                                        onChange={this.changePriceProperty.bind(this)}
                                        data-group={element.id}
                                        checked={this.state.data_price_property === element.id ? true : false}
                                    />
                                    <label htmlFor={"pricePropCheck_" + element.id}>{element.name}</label>
                                </div>
                                <div
                                    className={
                                        !this.checkGroupPriceProperty(item, element)
                                            ? "hidden panel-body"
                                            : "panel-body"
                                    }
                                >
                                    <div className="form-group">
                                        <div style={{ float: "right" }}>
                                            <PredefinedTextSelector
                                                type="position-info-text"
                                                catalogItem={this.state.item ? this.state.item.id : null}
                                                target={"#inputGroupText_" + element.id}
                                                updateEvent={this.infoTextUpdate.bind(this)}
                                                updateData={{
                                                    group_id: element.id
                                                }}
                                            />
                                        </div>

                                        <label htmlFor={"inputGroupText_" + element.id}>Info-Text</label>
                                        <textarea
                                            className="form-control big-textarea"
                                            rows="5"
                                            id={"inputGroupText_" + element.id}
                                            value={this.state.data_groups["group_" + element.id].info_text}
                                            onChange={this.handleGroupValueChange.bind(this)}
                                            data-group={element.id}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button
                                                    className="btn btn-default btn-xs pull-right"
                                                    style={{
                                                        margin: 0,
                                                        position: "absolute",
                                                        right: "15px",
                                                        padding: "2px",
                                                        marginTop: "-5px"
                                                    }}
                                                    data-group={element.id}
                                                    onClick={this.resetGroupWeighting.bind(this)}
                                                >
                                                    = Menge
                                                </button>
                                                <label htmlFor={"inputGroupWeighting_" + element.id}>Gewichtung</label>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <input
                                                            type="number"
                                                            data-target="group_weighting"
                                                            className="form-control"
                                                            id={"inputGroupWeighting_" + element.id}
                                                            onChange={this.handleGroupValueChange.bind(this)}
                                                            value={
                                                                this.state.data_groups["group_" + element.id]
                                                                    .weighting === null
                                                                    ? this.state.data_quantity
                                                                    : this.state.data_groups["group_" + element.id]
                                                                          .weighting
                                                            }
                                                            data-group={element.id}
                                                        />
                                                    </div>
                                                    <div className="col-xs-4">
                                                        <button
                                                            className="btn btn-default btn-block"
                                                            data-group={element.id}
                                                            data-target="group_weighting"
                                                            data-type="plus"
                                                            onClick={this.calcValue.bind(this)}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                    <div className="col-xs-4">
                                                        <button
                                                            className="btn btn-default btn-block"
                                                            data-group={element.id}
                                                            data-target="group_weighting"
                                                            data-type="minus"
                                                            onClick={this.calcValue.bind(this)}
                                                        >
                                                            -
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor={"inputGroupNuPrice_" + element.id}>NU-Faktor</label>
                                                <div className="row">
                                                    <div className="col-xs-4">
                                                        <input
                                                            type="number"
                                                            data-target="group_nu_factors"
                                                            className="form-control"
                                                            id={"inputGroupNuPrice_" + element.id}
                                                            onChange={this.handleGroupValueChange.bind(this)}
                                                            value={
                                                                this.state.data_groups["group_" + element.id]
                                                                    .nu_factors === null
                                                                    ? ""
                                                                    : this.state.data_groups["group_" + element.id]
                                                                          .nu_factors
                                                            }
                                                            data-group={element.id}
                                                        />
                                                    </div>
                                                    <div className="col-xs-4">
                                                        <button
                                                            className="btn btn-default btn-block"
                                                            data-group={element.id}
                                                            data-target="group_nu_factors"
                                                            data-type="plus"
                                                            onClick={this.calcValue.bind(this)}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                    <div className="col-xs-4">
                                                        <button
                                                            className="btn btn-default btn-block"
                                                            data-group={element.id}
                                                            data-target="group_nu_factors"
                                                            data-type="minus"
                                                            onClick={this.calcValue.bind(this)}
                                                        >
                                                            -
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {item.materials && item.materials.length > 0 ? (
                                            <div className="col-sm-12">
                                                <b>Materialien:</b>

                                                <table className="table" style={{ border: "1px solid #ddd" }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 50 }}></th>
                                                            <th>Hersteller</th>
                                                            <th>Serie</th>
                                                            <th>Artikelnummer</th>
                                                            <th>Ausführung</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.materials.map(component => {
                                                            if (
                                                                !component.materials ||
                                                                component.materials.length < 1
                                                            ) {
                                                                return null;
                                                            }

                                                            let matsCount = 0;
                                                            let mats = component.materials.map(material => {
                                                                if (material.group_id !== element.id) {
                                                                    return null;
                                                                }

                                                                let defaultVersion = null;
                                                                if (material.versions && material.versions.length > 0) {
                                                                    defaultVersion = material.versions[0].id;
                                                                }

                                                                let checked = false;
                                                                const matsTemp = this.state.data_groups[
                                                                    "group_" + element.id
                                                                ].materials;
                                                                let checkedVersion = "";
                                                                if (matsTemp && matsTemp.length > 0) {
                                                                    for (let m in matsTemp) {
                                                                        if (
                                                                            matsTemp[m].id === material.material.id &&
                                                                            matsTemp[m].checked
                                                                        ) {
                                                                            checked = true;
                                                                            checkedVersion =
                                                                                matsTemp[m].version &&
                                                                                parseInt(matsTemp[m].version, 10) > 0
                                                                                    ? matsTemp[m].version
                                                                                    : "";
                                                                        }
                                                                    }
                                                                }

                                                                matsCount++;
                                                                matsCountGroup++;
                                                                return (
                                                                    <tr key={"material_" + material.material.id}>
                                                                        <td
                                                                            style={{
                                                                                textAlign: "center",
                                                                                width: 30
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={e => {
                                                                                    this.toggleMaterial(
                                                                                        element.id,
                                                                                        material.material.id,
                                                                                        e.target.checked,
                                                                                        defaultVersion
                                                                                    );
                                                                                }}
                                                                                checked={checked}
                                                                            />
                                                                            {material.id}
                                                                        </td>
                                                                        <td>
                                                                            {material.manufacturer
                                                                                ? material.manufacturer.name
                                                                                : "-"}
                                                                        </td>
                                                                        <td>
                                                                            {material.series &&
                                                                            material.series.trim() !== ""
                                                                                ? material.series
                                                                                : "-"}
                                                                        </td>
                                                                        <td>
                                                                            {material.article_number &&
                                                                            material.article_number
                                                                                .toString()
                                                                                .trim() !== ""
                                                                                ? material.article_number
                                                                                : "-"}
                                                                        </td>
                                                                        <td>
                                                                            {material.versions &&
                                                                            material.versions.length > 0 ? (
                                                                                <select
                                                                                    className="form-control"
                                                                                    style={{ margin: 0 }}
                                                                                    value={checkedVersion}
                                                                                    onChange={e => {
                                                                                        this.setMaterialVersion(
                                                                                            element.id,
                                                                                            material.material.id,
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {material.versions.map(version => (
                                                                                        <option
                                                                                            value={version.id}
                                                                                            key={
                                                                                                "version_" +
                                                                                                material.id +
                                                                                                "_" +
                                                                                                version.id
                                                                                            }
                                                                                        >
                                                                                            {version.title}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            ) : null}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            });

                                                            if (matsCount < 1) {
                                                                return null;
                                                            }

                                                            return (
                                                                <React.Fragment key={"comp_" + component.component_id}>
                                                                    <tr>
                                                                        <td colSpan={5}>
                                                                            <b>{component.component_name}</b>
                                                                        </td>
                                                                    </tr>

                                                                    {mats}
                                                                </React.Fragment>
                                                            );
                                                        })}

                                                        {matsCountGroup < 1 ? (
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    Es stehen keine Materialien für dieser Gewerk zur
                                                                    Auswahl bereit.
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        let deleteButton = (
            <button className="btn btn-danger" style={{ width: "40%" }} disabled>
                Position entfernen
            </button>
        );
        if (this.positionIsInDeal()) {
            deleteButton = (
                <button className="btn btn-danger" style={{ width: "40%" }} onClick={this.removePosition.bind(this)}>
                    Position entfernen
                </button>
            );
        }

        let tempGaebCount = 0;
        return (
            <div className="selector-current-item-content editPositionContainer" id="editPositionContainer">
                <BigTextareas />
                <h3 style={{ marginTop: "0px", marginBottom: "15px" }} id="currentPositionTitle">
                    <button
                        className={
                            "pull-right btn btn-sm btn-default " + (this.props.isLightbox ? "hidden" : "visible-xs")
                        }
                        onClick={this.props.closeEvent}
                    >
                        Schließeen
                    </button>
                    <span className="label label-default" style={{ marginRight: "10px", padding: "1px 8px" }}>
                        {item.gaeb_numbers && item.gaeb_numbers[0] ? item.gaeb_numbers[0].number : item.key}
                    </span>
                    {item.name}

                    {item.gaeb_numbers && item.gaeb_numbers.length > 1 ? (
                        <div style={{ fontSize: "12px", marginTop: "5px" }}>
                            {item.gaeb_numbers.map(
                                number => number.number + (++tempGaebCount < item.gaeb_numbers.length ? ", " : "")
                            )}
                        </div>
                    ) : null}
                </h3>

                <div
                    className="btn-group positionEditSaveBtns"
                    style={{ marginBottom: "15px", width: "100%" }}
                    id="positionEditSaveBtns"
                >
                    <button className="btn btn-success" style={{ width: "60%" }} onClick={this.save.bind(this)}>
                        Position speichern
                    </button>
                    {deleteButton}
                </div>

                {saveAlertMessage}

                <div className="panel panel-default">
                    <div className="panel-heading">Allgemeine Informationen</div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: "middle" }}>Menge ({item.unit})</td>
                                <td>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={this.state.data_quantity}
                                                onChange={this.handleInputChange.bind(this)}
                                                name="data_quantity"
                                            />
                                        </div>
                                        <div className="col-xs-3">
                                            <button
                                                className="btn btn-default btn-block"
                                                data-target="data_quantity"
                                                data-type="plus"
                                                onClick={this.calcValue.bind(this)}
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div className="col-xs-3">
                                            <button
                                                className="btn btn-default btn-block"
                                                data-target="data_quantity"
                                                data-type="minus"
                                                onClick={this.calcValue.bind(this)}
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "middle" }}>Multiplikator</td>
                                <td>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={this.state.data_price_multiplier}
                                                onChange={this.handleInputChange.bind(this)}
                                                name="data_price_multiplier"
                                            />
                                        </div>
                                        <div className="col-xs-3">
                                            <button
                                                className="btn btn-default btn-block"
                                                data-target="data_price_multiplier"
                                                data-type="plus"
                                                onClick={this.calcValue.bind(this)}
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div className="col-xs-3">
                                            <button
                                                className="btn btn-default btn-block"
                                                data-target="data_price_multiplier"
                                                data-type="minus"
                                                onClick={this.calcValue.bind(this)}
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>Eventualposition</td>
                                <td>
                                    <div className="form-group" style={{ marginBottom: "0px" }}>
                                        <select
                                            style={{ marginBottom: "0px" }}
                                            name="data_contingency"
                                            className="form-control"
                                            value={this.state.data_contingency}
                                            onChange={this.changeContingency.bind(this)}
                                        >
                                            <option value={true}>Ja</option>
                                            <option value={false}>Nein</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h3>Gewerke</h3>
                <div className="row">{gewerke}</div>
            </div>
        );
    }
}
