import React, { Component } from "react";
import CustomersHelper from "../../services/CustomersHelper";
import { Link } from "react-router-dom";

export default class CustomersListComponent extends Component {
    render() {
        let customers = CustomersHelper.getCustomers();

        let list = <div className="content">Es wurden noch keine Kunden geladen.</div>;
        if (customers) {
            list = customers.map(element => (
                <Link
                    to={"/customers/" + element.id + "/detail"}
                    key={element.id}
                    className="list-item"
                    id={element.id}
                >
                    {element.name}
                </Link>
            ));
        }

        return (
            <div>
                <div className="content">
                    <h1>Kunden</h1>
                </div>

                <div className="list-view">{list}</div>
            </div>
        );
    }
}
