import React, { Component } from "react";
import Config from "Config";
import ApiHelper from "../services/ApiHelper";
import DBHelper from "../services/DBHelper";
import Icon from "../components/Icon";
import ReactModal from "react-modal";
import moment from "moment";
import $ from "jquery";

export default class AppInfoComponent extends Component {
    state = {
        checkForUpdate: false,
        currentVersion: null,
        database: "waiting"
    };

    componentDidMount() {
        this.checkDB();
    }

    async checkDB() {
        await DBHelper.waitForDb();

        setTimeout(() => {
            if (DBHelper.database()) {
                this.setState({
                    database: "true"
                });
            } else {
                this.setState({
                    database: "false"
                });
            }
        }, 2000);
    }

    checkForUpdate() {
        this.setState({
            checkForUpdate: true,
            currentVersion: null
        });
        ApiHelper.request("/version").then(response => {
            if (response && response.status === "success" && response.data && response.data.version) {
                this.setState({
                    currentVersion: response.data.version
                });
            } else {
                alert("Es konnte Aufgrund der API-Endpunkt Konfiguration kein Versionabgleich durchgeführt werden.");
            }

            this.setState({
                checkForUpdate: false
            });
        });
    }

    render() {
        let lastSync = localStorage.getItem("last_sync");
        let lastSyncMoment;
        if (lastSync && lastSync.trim() !== "") {
            lastSyncMoment = moment(lastSync);
        }

        return (
            <div className="content">
                <h1>App-Info</h1>

                <ReactModal
                    isOpen={!this.state.checkForUpdate && this.state.currentVersion !== null ? true : false}
                    ariaHideApp={false}
                    style={{
                        content: {
                            textAlign: "center",
                            width: "250px",
                            left: "50%",
                            marginLeft: "-125px"
                        }
                    }}
                >
                    {this.state.currentVersion && this.state.currentVersion.trim() === Config.version.trim() ? (
                        <div>
                            <div style={{ fontSize: "18px", color: "green", marginBottom: "10px" }}>Alles aktuell!</div>
                            Es ist die aktuellste Version der App installiert!
                        </div>
                    ) : (
                        <div>
                            <div style={{ fontSize: "18px", color: "red", marginBottom: "10px" }}>
                                Neue Version verfügbar!
                            </div>
                            Es ist eine neue Version der App verfügbar!
                            <br />
                            <br />
                            <div style={{ textAlign: "left", fontSize: "10px" }}>
                                Die neueste Version kann mit einem Klick auf die gelbe Schaltfläche unterhalb des
                                Senft-Logos installiert werden. Sollte diese Leiste nicht automatisch erscheinen, ist
                                das Endgerät eventuell nicht mit dem Internet verbunden oder die App muss einmal neu
                                gestartet werden.
                            </div>
                        </div>
                    )}
                    <div style={{ marginTop: "15px" }}>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => this.setState({ currentVersion: null })}
                        >
                            Schließen
                        </button>
                    </div>
                </ReactModal>

                <div className="row">
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>HTML-Version</td>
                                        <td>{$("#root").attr("data-version")}</td>
                                    </tr>
                                    <tr>
                                        <td>JavaScript SRC-Version</td>
                                        <td>{Config.version}</td>
                                    </tr>
                                    <tr>
                                        <td>Api-Endpoint</td>
                                        <td>
                                            <input
                                                type="text"
                                                onChange={() => {}}
                                                className="form-control"
                                                value={Config.api.endpoint}
                                                onClick={e => e.target.select()}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Datenbank</td>
                                        <td>
                                            {this.state.database === "waiting" ? (
                                                <Icon icon="spinner" spin />
                                            ) : this.state.database === "true" ? (
                                                <Icon icon="check" className="text-success" />
                                            ) : (
                                                <Icon icon="times" className="text-danger" />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Letzte Synchronisierung</td>
                                        <td>
                                            {lastSyncMoment ? (
                                                lastSyncMoment.calendar()
                                            ) : (
                                                <span style={{ color: "red" }}>Nocht nicht synchronisiert</span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Benötigt Sync?</td>
                                        <td>
                                            {ApiHelper.needsSync() ? "Ja" : "Nein"}
                                            <div className="small">
                                                <small>Minimal-Sync: {Config.api.min_sync}</small>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td />
                                        <td>
                                            <button
                                                onClick={this.checkForUpdate.bind(this)}
                                                className="btn btn-primary btn-sm"
                                                disabled={this.state.checkForUpdate}
                                            >
                                                {this.state.checkForUpdate
                                                    ? "Version wird überprüft"
                                                    : "Version überprüfen"}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
